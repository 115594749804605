import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine, Cell, LabelList
} from 'recharts';
import Loader from "../reusables/Loader";
import ErrorResponse from "../error-response/error-response";
import ChartInfoIcon from "../chart-info";


const LINE_CHART_HEIGHT = 360;

const CustomTooltip = (props) => {
  const { active, payload, label,formatter } = props;
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="tooltip-heading">{`${label}`}</p>
        <div style={{color: payload[0].stroke}}>
          <span className="tooltip-label">Amount : </span>
          <span className="tooltip-value">{formatter(payload[0].value)}</span>
        </div>
      </div>
    );
  }

  return null;
};
const renderCustomizedLabel = (props) => {
  const { x, y, value } = props;
  return (
    <g>
      {
        parseFloat(value) <=0 &&
        <text x={x+15} y={y +10} fontWeight={500} textAnchor="middle" dominantBaseline="middle">
          {parseFloat(value).toFixed(1)}%
        </text>
      }
      {
        parseFloat(value) > 0 &&
        <text x={x + 15} y={y - 5} fontWeight={500} textAnchor="middle" dominantBaseline="middle">
          {parseFloat(value).toFixed(1)}%
        </text>
      }
    </g>
  );
};
const CustomizedAxisTick =(props)=> {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text fontSize={'1em'} x={0} y={0} dy={16} textAnchor="end" fill="#305A80" transform="rotate(-35)">
        {payload.value.substr(0,22)}{payload.value.length>22?'...':''}
      </text>
    </g>
  );
}
const PnlSummaryBarChart=(props)=> {
  const {data,loading,error,formatter,dataTestId,chartInfoCopy} = props;
  if(loading) {
    return <div style={{margin:'1em 0 2em 0'}}><Loader dataTestId={`${dataTestId}-loading`} type={'pulse'} size={10}/></div>
  }
  if(error){
    return(<ErrorResponse dataTestId={`${dataTestId}-error`} error={error}/> )
  }
  if(data.length > 0 && !loading) {
    return (
      <div className={`simple-charts-wrapper`} data-testid={dataTestId} style={{fontSize: '12px', width: '100%', maxWidth: '700px'}}>
        <ChartInfoIcon text={chartInfoCopy}/>
        <ResponsiveContainer height={LINE_CHART_HEIGHT}>
          <BarChart
            height={LINE_CHART_HEIGHT}
            data={data}
            margin={{top: 5, right: 30, left: 20, bottom: 5}}
            barSize={25}
          >
            <XAxis
              interval={0}
              fontSize={9}
              height={80}
              dataKey="cat"
              type={'category'}
              tickSize={10}
              tick={<CustomizedAxisTick/>}
              padding={{left: 5, right: 5}}
            />
            {/*<XAxis dataKey='cat'/>*/}
            <YAxis tickFormatter={(value)=> formatter(value)}/>
            <Tooltip content={<CustomTooltip/>} formatter={(value)=> formatter(value)} />
            <ReferenceLine y={0} stroke="#000" strokeDasharray="3 3" />
            <Bar dataKey="value" fill="#3571CA"  >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.value>0 ? '#3366cc':'#ff8042'} />
              ))}
              <LabelList dataKey='percentageOfSales' content={renderCustomizedLabel}/>
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
  return (<div className="chart-error error-icon"><span>Data not available</span></div>)
}
export default PnlSummaryBarChart;
