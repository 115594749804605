import React, {Fragment, useEffect, useState} from "react";
import CustomSelect from "../custom-select";
import CustomMonthYearSelect from "../custom-month-year-select";
import {useSelector} from "react-redux";
import {formatCurrency} from "../analysis-helper";
import {
  getMonthOptionsBasedOnYearSelected,
  currentYear,
  currentMonth,
  monthsInAnYear,
  formatPercentAndCheckSign,
  formatCurrencyAndCheckSign,
  sortSections
} from "./common-functions";
import useGet from "../hooks/use-get";
import Loader from "../reusables/Loader";
import withErrorBoundary from "../error/ErrorBoundary";
import notify from "../notify/notify";
import ErrorResponse from "../error-response/error-response";

const PnlFinancials =({view})=> {
  const [sortOrder, setSortOrder] = useState(['period1-value', 'descending']);

  const [selectedDateStart, setSelectedDateStart] = useState({month: currentMonth, year: currentYear});
  const [selectedDateEnd, setSelectedDateEnd] = useState({month: currentMonth, year: currentYear - 1});

  const company = useSelector(state => state.selectedCompany);
  const [duration, setDuration] = useState('Months');

  const [monthOptionsStart, setMonthOptionsStart] = useState(getMonthOptionsBasedOnYearSelected(selectedDateStart));
  const [monthOptionsEnd, setMonthOptionsEnd] = useState(getMonthOptionsBasedOnYearSelected(selectedDateEnd.year));
  const [pnlData, setPnlData] = useState([]);

  const [leftDurationMsg, setLeftDurationMsg] = useState(null);
  const [rightDurationMsg, setRightDurationMsg] = useState(null);

  const getSelectedDateStart = () => {
    const month = monthsInAnYear.indexOf(selectedDateStart.month) + 1;
    return `${month.toString().length === 1 ? `0${month}` : `${month}`}-${selectedDateStart.year.toString().substr(2, 2)}`;
  }

  const getSelectedDateEnd = () => {
    const month = monthsInAnYear.indexOf(selectedDateEnd.month) + 1;
    return `${month.toString().length === 1 ? `0${month}` : `${month}`}-${selectedDateEnd.year.toString().substr(2, 2)}`;
  }

  const [pnlResponse, pnlLoading, pnlError,] = useGet({
    url: `${process.env.REACT_APP_DJANGO_BACKEND_BASE_URL}/financials/PNL/${company.companyId}/${getSelectedDateStart()}/${getSelectedDateEnd()}/${duration}`,
  });

  const sumOtherValues=(items)=>{
    return items.reduce((acc, curr,index) => {
      if (index === 0) {
        acc.values = curr.values;
      } else {
        acc.values.period1.value = curr.values.period1.value + acc.values.period1.value;
        acc.values.period1.percentage = curr.values.period1.percentage + acc.values.period1.percentage;
        acc.values.period2.value = curr.values.period2.value + acc.values.period2.value;
        acc.values.period2.percentage = curr.values.period2.percentage + acc.values.period2.percentage;
        acc.values.difference.value = curr.values.difference.value + acc.values.difference.value;
        acc.values.difference.percentage = curr.values.difference.percentage + acc.values.difference.percentage;
      }
      return acc;
    }, {name: `Others`, values: {}});
  }

  const splitIntoTopTenAndOthers =(section)=> {
    const top10 =section.slice(0, 10);
    const othersItems = section.slice(10,section.length);
    if (othersItems.length > 0) {
      return [...top10,sumOtherValues(othersItems)];
    }
    else{
      return [...top10];
    }
  }
  useEffect(() => {
    if (!pnlLoading && pnlResponse && !pnlError) {
      setLeftDurationMsg(null);
      setRightDurationMsg(null);
      const cloneData = JSON.parse(JSON.stringify(pnlResponse));
      const res = cloneData.reduce((converted, section) => {
        if (Array.isArray(section)) {
          if (section[0].NoDataSide) {
            if (section[0].NoDataSide === 'Left') {
              setLeftDurationMsg({msg: section[0].NoDataMessage, error: true});
            } else {
              setRightDurationMsg({msg: section[0].NoDataMessage, error: true});
            }
          } else if (section[0].LimitedDataSide) {
            if (section[0].LimitedDataSide === 'Left') {
              setLeftDurationMsg({msg: section[0].LimitedDataMessage, error: true});
            } else {
              setRightDurationMsg({msg: section[0].LimitedDataMessage, error: true});
            }
          } else if (section[0].IdealCaseSide) {
            if (section[0].IdealCaseSide === 'Left') {
              setLeftDurationMsg({msg: section[0].IdealCaseMessage, error: false});
            } else {
              setRightDurationMsg({msg: section[0].IdealCaseMessage, error: false});
            }
          } else {
            converted.push({name: section[0].name,subcategories:[], values: section[0].values[0]});
          }
        } else {
          converted[converted.length - 1]['subcategories'] = section.subcategories? section.subcategories.reduce((acc, item) => {
            acc.push({name: item.name, values: item.values[0]});
            return acc;
          }, []):[];
        }
        return converted;
      }, []);
      const sortingOrder = sortOrder[0].split('-');
      if (view === 'Intermediate View') {
        const result = res.map((section) => {

          const sorted = section.subcategories ?
            section.subcategories.sort((a, b) => {
              if (sortOrder[1] === 'descending') {
                return b.values[sortingOrder[0]][sortingOrder[1]] - a.values[sortingOrder[0]][sortingOrder[1]]
              } else {
                return a.values[sortingOrder[0]][sortingOrder[1]] - b.values[sortingOrder[0]][sortingOrder[1]]
              }
            })
            : [];
          if(sorted.length <= 10){
            return ({name: section.name, subcategories: sorted, values: section.values});
          }
          else{
            return ({name: section.name, subcategories: splitIntoTopTenAndOthers(sorted), values: section.values});
          }
        });
        setPnlData(result);
      } else if (view === 'Full Detail View') {
        const result = sortSections(res, sortingOrder[0], sortingOrder[1], sortOrder[1]);
        setPnlData(result);
      } else {
        setPnlData(res);
      }
    }
    if (pnlError) {
      notify({type: 'error', message: 'Something went wrong while fetching P&L Financials'});
    }
    return ()=>{
      setPnlData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pnlResponse, duration, pnlLoading, pnlError, view, sortOrder, selectedDateStart, selectedDateEnd,
  ]);

  const durationMap = {
    'Month': 'Months',
    'Last Twelve Months': 'Last12Months',
    'Year to Date': 'YearToDate',
    'Quarter': 'Quarter'
  }

  const handleDurationChange = (selectedDuration) => {
    setDuration(durationMap[selectedDuration]);
  }
  const handleYearChangeStart = (selectedYearString) => {
    setSelectedDateStart(date => ({month: date.month, year: selectedYearString}))
    setMonthOptionsStart(getMonthOptionsBasedOnYearSelected(selectedYearString));
  }
  const handleYearChangeEnd = (selectedYearString) => {
    setSelectedDateEnd(date => ({month: date.month, year: selectedYearString}))
    setMonthOptionsEnd(getMonthOptionsBasedOnYearSelected(selectedYearString));
  }
  const handleMonthChangeStart = (month) => {
    setSelectedDateStart(date => ({month: month, year: date.year}))
  }
  const handleMonthChangeEnd = (month) => {
    setSelectedDateEnd(date => ({month: month, year: date.year}))
  }

  const handleSorting = (order) => {
    if (order === sortOrder[0]) {
      if (sortOrder[1] === 'descending') {
        setSortOrder([order, 'ascending']);
      } else {
        setSortOrder([order, 'descending']);
      }
    } else {
      setSortOrder([order, 'descending']);
    }
  }

  return (
    <div style={{margin: '0 1em', padding: '1em', border: '1px solid #305A80'}}>
      <table className={`financials-table pnl ${view.replace(/ /g, '')}`}>
        <thead>
        <tr>
          <th>
            <CustomSelect
              dataTestId={''}
              selectedValue={Object.keys(durationMap).find(key => durationMap[key] === duration)}
              options={[{label: 'Month', selectable: true, disconnected: false}, {
                label: 'Quarter',
                selectable: true,
                disconnected: false
              }, {label: 'Year to Date', selectable: true, disconnected: false}, {
                label: 'Last Twelve Months',
                selectable: true,
                disconnected: false
              }]}
              className={`companySwitcher`}
              callback={handleDurationChange}/>
          </th>
          <th>
            <div>
              {duration !== "Months" && <span>Ending in</span>}
              <CustomMonthYearSelect monthOptions={monthOptionsStart}
                                     selectedMonth={selectedDateStart.month}
                                     selectedYear={selectedDateStart.year}
                                     callbackMonthChange={handleMonthChangeStart}
                                     callBackYearChange={handleYearChangeStart}
              />
            </div>
            <div>
              {!leftDurationMsg && rightDurationMsg && <div>&nbsp;</div>}
              {leftDurationMsg && <div style={{
                color: `${leftDurationMsg.error ? '#E02020' : '#090909'}`,
                fontWeight: '500',
                fontSize: '0.9em'
              }}>{leftDurationMsg.msg}</div>}
            </div>
          </th>
          <th>
            <div>
              {duration !== "Months" && <span>Ending in</span>}
              <CustomMonthYearSelect monthOptions={monthOptionsEnd}
                                     selectedMonth={selectedDateEnd.month}
                                     selectedYear={selectedDateEnd.year}
                                     callbackMonthChange={handleMonthChangeEnd}
                                     callBackYearChange={handleYearChangeEnd}
              />
            </div>
            <div>
              {leftDurationMsg && !rightDurationMsg && <div>&nbsp;</div>}
              {rightDurationMsg && <div style={{
                color: `${rightDurationMsg.error ? '#E02020' : '#090909'}`,
                fontWeight: '500',
                fontSize: '0.9em'
              }}>{rightDurationMsg.msg}</div>}
            </div>
          </th>
          <th>
            {}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr className={'table-heading intermediate-view'}>
          <td><span className="financial-table-heading">Profit and Loss</span></td>
          <td>
            <div><span onClick={() => handleSorting('period1-value')}
                       className={`sort-elem ${sortOrder[0] === 'period1-value' ? `active ${sortOrder[1]}` : ''}`}>{formatCurrency(0, company.currency).substring(0, 1)}</span><span
              onClick={() => handleSorting('period1-percentage')}
              className={`sort-elem ${sortOrder[0] === 'period1-percentage' ? `active ${sortOrder[1]}` : ''}`}>% of Sales</span>
            </div>
          </td>
          <td>
            <div><span onClick={() => handleSorting('period2-value')}
                       className={`sort-elem ${sortOrder[0] === 'period2-value' ? `active ${sortOrder[1]}` : ''}`}>{formatCurrency(0, company.currency).substring(0, 1)}</span><span
              onClick={() => handleSorting('period2-percentage')}
              className={`sort-elem ${sortOrder[0] === 'period2-percentage' ? `active ${sortOrder[1]}` : ''}`}>% of Sales</span>
            </div>
          </td>
          <td>
            <div><span onClick={() => handleSorting('difference-value')}
                       className={`sort-elem ${sortOrder[0] === 'difference-value' ? `active ${sortOrder[1]}` : ''}`}>Diff ({formatCurrency(0, company.currency).substring(0, 1)})</span><span
              onClick={() => handleSorting('difference-percentage')}
              className={`sort-elem ${sortOrder[0] === 'difference-percentage' ? `active ${sortOrder[1]}` : ''}`}>Diff (%)</span>
            </div>
          </td>
        </tr>
        <tr className={`table-heading compact-view`}>
          <td><span className="financial-table-heading">Profit and Loss</span></td>
          <td>
            <div><span className={`sort-elem`}>{formatCurrency(0, company.currency).substring(0, 1)}</span><span
              className={`sort-elem`}>% of Sales</span></div>
          </td>
          <td>
            <div><span className={`sort-elem`}>{formatCurrency(0, company.currency).substring(0, 1)}</span><span
              className={`sort-elem`}>% of Sales</span></div>
          </td>
          <td>
            <div><span className={`sort-elem`}>Diff ({formatCurrency(0, company.currency).substring(0, 1)})</span><span
              className={`sort-elem`}>Diff (%)</span></div>
          </td>
        </tr>
        {pnlLoading &&
         <div style={{margin: '0 auto', width: '200px'}}><Loader dataTestId={`pnl-financial-loading`} type={'pulse'}
                                                                 size={10}/></div>}
        {!pnlLoading && !pnlError && pnlData.map((row, index) => {
          return (
            <Fragment key={index}>
              <tr className={`cat-row`}>
                <td>{row.name}</td>
                <td>
                  <div>
                    <span>{formatCurrencyAndCheckSign(row.values.period1.value, company)}</span><span>{formatPercentAndCheckSign(row.values.period1.percentage)}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{formatCurrencyAndCheckSign(row.values.period2.value, company)}</span><span>{formatPercentAndCheckSign(row.values.period2.percentage)}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{formatCurrencyAndCheckSign(row.values.difference.value, company)}</span><span>{formatPercentAndCheckSign(row.values.difference.percentage)}</span>
                  </div>
                </td>
              </tr>
              {/*{(['Detailed View', 'Complete Details View'].includes(view)) && [null, undefined].includes(row.subcategories) &&*/}
              {/* <tr className={'empty-row'}>*/}
              {/*   <td>{}</td>*/}
              {/*   <td>{}</td>*/}
              {/*   <td>{}</td>*/}
              {/*   <td>{}</td>*/}
              {/* </tr>*/}
              {/*}*/}
              {(['Intermediate View', 'Full Detail View'].includes(view)) && row.subcategories && row.subcategories.map((subcatRow) => {
                return (
                  <tr className={'subcat-row'}>
                    <td>{subcatRow.name}</td>
                    <td>
                      <div>
                        <span>{formatCurrencyAndCheckSign(subcatRow.values.period1.value, company)}</span><span>{formatPercentAndCheckSign(subcatRow.values.period1.percentage)}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{formatCurrencyAndCheckSign(subcatRow.values.period2.value, company)}</span><span>{formatPercentAndCheckSign(subcatRow.values.period2.percentage)}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{formatCurrencyAndCheckSign(subcatRow.values.difference.value, company)}</span><span>{formatPercentAndCheckSign(subcatRow.values.difference.percentage)}</span>
                      </div>
                    </td>
                  </tr>)
              })}
            </Fragment>
          )
        })}
        {pnlError && <ErrorResponse dataTestId={`pnl-financial-error`} error={pnlError}/>}
        </tbody>
      </table>
    </div>
  )
}
export default withErrorBoundary(PnlFinancials);
