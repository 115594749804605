import {formatCurrency} from "../analysis-helper";
import React, {useState} from "react";
import CustomSelect from "../custom-select";

const tableDropdownOptions = [
  {
    label: '0%',
    selectable: true,
    disconnected: false
  },
  {
    label: '0.5%',
    selectable: true,
    disconnected: false
  }, {
    label: '1.0%',
    selectable: true,
    disconnected: false
  }, {
    label: '1.5%',
    selectable: true,
    disconnected: false
  }, {
    label: '2.0%',
    selectable: true,
    disconnected: false
  }, {
    label: '2.5%',
    selectable: true,
    disconnected: false
  }, {
    label: '3.0%',
    selectable: true,
    disconnected: false
  }, {
    label: '3.5%',
    selectable: true,
    disconnected: false
  }, {
    label: '4.0%',
    selectable: true,
    disconnected: false
  }, {
    label: '4.5%',
    selectable: true,
    disconnected: false
  }, {
    label: '5.0%',
    selectable: true,
    disconnected: false
  },]
const value = 137603;

const formatCurrencyAndCheckSign = (currency, company) => {
  const formattedCurrency = formatCurrency(currency, company.currency);
  if (formattedCurrency.includes('-')) {
    return (
      <span style={{color: 'red'}}>({formattedCurrency.split('-')[1].substr(1, formattedCurrency.length - 1)})</span>
    )
  }
  return formattedCurrency.substr(1, formattedCurrency.length - 1);
}

const ProfitImprovementTable =({company,tableHeading,leftText,topText})=>{
  const length = 5.5;
  const [startValueLeft, setStartValueLeft] = useState(0);
  const [startValueRight, setStartValueRight] = useState(0);
  const showColumnsLeftTable = () => {
    const items = [<tr>
      <td>&nbsp;</td>
    </tr>];
    for (let i = startValueLeft; i < (startValueLeft + length); i += 0.5) {
      if (i === startValueLeft) {
        items.push(<tr>
          <td>{
            <CustomSelect
              dataTestId={'reports-type-select'}
              selectedValue={parseFloat(startValueLeft).toFixed(1)}
              options={tableDropdownOptions}
              className={`companySwitcher profit-improvement-table-dropdown`}
              callback={(text) => setStartValueLeft(parseFloat(text))}/>
          }</td>
        </tr>)
      } else {
        items.push(<tr>
          <td>{parseFloat(i).toFixed(1)}%</td>
        </tr>)
      }
    }
    return items;
  }
  const showRightTableHeader = () => {
    const items = [];
    for (let i = startValueRight; i < (startValueRight + length); i += 0.5) {
      if (i === startValueRight) {
        items.push(
          <th>
            <CustomSelect
              dataTestId={'reports-type-select'}
              selectedValue={parseFloat(startValueRight).toFixed(1)}
              options={tableDropdownOptions}
              className={`companySwitcher profit-improvement-table-dropdown`}
              callback={(text) => setStartValueRight(parseFloat(text))}/>
          </th>
        )
      } else {
        items.push(<th>{parseFloat(i).toFixed(1)}%</th>)
      }
    }
    return items;
  }
  const generateColumns = () => {
    const rowList = [];
    const columnList = [];
    for (let j = 0; j < length; j += 0.5) {
      columnList.push(<td>{formatCurrencyAndCheckSign(value * (j + 1) * 10 * Math.random(), company)}</td>)
    }
    for (let i = 0; i < length; i += 0.5) {
      rowList.push(<tr>
        {columnList}
      </tr>)
    }
    return rowList;
  }
  return(
    <div className='table-wrapper'>
      <div style={{padding: '1em 0 1em 2em',fontWeight:'bold',color:'#305A80'}}>{tableHeading}
      </div>

      <div style={{transform: 'translate(0px, 0px)', height: '30px',display:'inline-block'}}>{topText}
      </div>
      <div style={{display: 'flex', justifyContent:'flex-start'}}>
        <div style={{
          transform: 'rotate(270deg)',
          transformOrigin: '170px 100px',
          height: '30px',
          maxWidth: '250px',
          whiteSpace:'nowrap',
          display: 'inline-block'
        }}>{leftText}
        </div>
        <table className='report-financial-table-left'>
          <thead>
          <tr>
            <th>{}</th>
          </tr>
          </thead>
          <tbody>
          {showColumnsLeftTable()}
          </tbody>
        </table>
        <table className='report-financial-table-right'>
          <thead>
          <tr>
            {showRightTableHeader()}
          </tr>
          </thead>
          <tbody>
          {generateColumns()}
          </tbody>
        </table>
      </div>
    </div>
  )
}
export default ProfitImprovementTable;