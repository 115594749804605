import React, {useState} from "react";
import CustomSelect from "../custom-select";
import ProfitImprovementTable from "./profit-improvement-table";
import ReportSection from "./report-section";

const durationOptions = [
  {
    label: 'Month',
    selectable: true,
    disconnected: false
  }, {
    label: 'Quarter',
    selectable: true,
    disconnected: false
  }, {
    label: 'Year to Date',
    selectable: true,
    disconnected: false
  }, {
    label: 'Last Twelve Months',
    selectable: true,
    disconnected: false
  }, {
    label: 'Last Financial Year',
    selectable: true,
    disconnected: false
  },
];

const ProfitImprovementScenario = ({modifySectionToggle,addRemoveSectionList,addRemoveSection,commentModeToggle,company}) => {
  const [duration, setDuration] = useState('Month');
  return (
    <div className='report-financial-table-wrapper'>
      <h3 className="table-heading lineChartHeading">
        <span className='lineChartHeadingLabel'>Profit Improvement Scenarios</span>
      </h3>
      <div style={{marginBottom:'1em'}}>
        <CustomSelect
          dataTestId={'duration-select'}
          selectedValue={duration}
          options={durationOptions}
          className={`companySwitcher`}
          callback={(text) => setDuration(text)}/>
      </div>
      <ReportSection
        showControls={modifySectionToggle}
        name={'Profit Improvement Scenarios Writeup'}
        addRemoveSectionList={addRemoveSectionList}
        toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
        commentMode={commentModeToggle}
      >
      <div className={'profit-improvement-scenario-writeup'}>
        {/*<h3 className="table-heading lineChartHeading">*/}
        {/*  <span className='lineChartHeadingLabel'>{duration} Profit Improvement Scenarios</span>*/}
        {/*</h3>*/}
        <ul>
          <li>1 - If your Prices had been 1.0% higher, your Previous Year EBITDA would have been £3,912,360 (49.0%) higher (i.e. £11,895,732)</li>
          <li>2 - If your Cost of Sales had been 1.0% lower, your Previous Year EBITDA would have been £3,259,994 (40.8%) higher (i.e. £11,243,366)</li>
          <li>3 - If your SG&A had been 1.0% lower, your Previous Year EBITDA would have been £572,532 (7.2%) higher (i.e. £8,555,904)</li>
          <li>4 - Example from the table below - If your Prices had been 1.0% higher and your CoS had been 1.0% lower, your Previous Year EBITDA would have been £7,172,355 (89.8%) higher (i.e. £15,155,727)</li>
          <li>5 - Example from the table below - If your CoS had been 1.0% lower and your SG&A had been 3.0% lower, your Previous Year EBITDA would have been £4,977,591 (62.3%) higher (i.e. £12,960,963)</li>
        </ul>
      </div>
      </ReportSection>
      <ReportSection
        showControls={modifySectionToggle}
        name={`Increase in ${duration} EBITDA Given different increase in Price and Reduction in COS`}
        addRemoveSectionList={addRemoveSectionList}
        titleWidth={600}
        toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
        commentMode={commentModeToggle}
      >
      <ProfitImprovementTable leftText={'Reduction in COS'} topText={'Increase in Price'} tableHeading={`Profit Improvement Scenarios - Increase in ${duration} EBITDA Given different increase in Price and Reduction in COS`} company={company}/>
      </ReportSection>
      <ReportSection
        showControls={modifySectionToggle}
        name={`Increase in ${duration} EBITDA Given different Reductions in COS and SG&A`}
        addRemoveSectionList={addRemoveSectionList}
        titleWidth={600}
        toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
        commentMode={commentModeToggle}
      >
      <ProfitImprovementTable leftText={'Reduction in SG&A'} topText={'Reduction in COS'} tableHeading={`Profit Improvement Scenarios - Increase in ${duration} EBITDA Given different Reductions in COS and SG&A`} company={company}/>
      </ReportSection>
      </div>
  )
}
export default ProfitImprovementScenario;