import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import React from "react";
import notify from "../notify/notify";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {

  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);
  //const typeArrForOthersCatMatch =droppableDestination.droppableId.split('_');
  //removed.type= typeArrForOthersCatMatch[3] === `Other ${typeArrForOthersCatMatch[4]}`? null: droppableDestination.droppableId.split('_')[3];
  destClone.splice(droppableDestination.index, 0, removed);

  const result = {removed};
  result[droppableSource.droppableId.split('_')[3]] = sourceClone;
  result[droppableDestination.droppableId.split('_')[3]] = destClone;
  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 1.5,
  margin: `0 0 ${grid}px 0`,
  borderRadius:'3px',
  boxShadow:'0 1px 2px rgba(0,0,0,0.5)',

  // change background colour if dragging
  background: isDragging ? "#3B7298" : "#ffffff",
  color: isDragging ? "#fff" : "#090909",
  // styles we need to apply on draggables
  ...draggableStyle
});
const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#6DB0CC" : "#D8DFE7",
  padding: grid,
  color:isDraggingOver ? "#ffffff" : "#090909",
});
const dragdropContent= { display: "flex"};
const rightCategories= { display: "flex",flexDirection:'column',flex:'0 0 286px'};

const BsAccounts=({state,track,stateChanged})=>{

  function onDragEnd(result) {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    const sourceName = source.droppableId.split('_');
    const destinationName = destination.droppableId.split('_');
    if (sourceName[4] === destinationName[4] && sourceName[3] === destinationName[3]){

      const items = reorder(state[sourceName[0]][0].items[sourceName[3]], source.index, destination.index);
      const newState=[...state];
      newState[sourceName[0]][0].items[sourceName[3]] = items;
      stateChanged(newState);
      track();
    } else {
      const result = move(state[sourceName[0]][0].items[sourceName[3]],state[destinationName[0]][0].items[destinationName[3]], source, destination);
      const newState=[...state];
      newState[sourceName[0]][0].items[sourceName[3]] = result[sourceName[3]];
      newState[destinationName[0]][0].items[destinationName[3]] = result[destinationName[3]];
      stateChanged(newState);
      notify({type:'SUCCESS',message:`${result.removed.name} moved from '${sourceName[3]} (${sourceName[4]})' to '${destinationName[3]} (${destinationName[4]})'`});
      track();
    }
  }

  function showRightNavItems(items,ind,name) {
    const list=[];
    let i=0;
    for (const [key, value] of Object.entries(items)) {
      list.push(
        <Droppable key={`${ind}_${i}`} name={key} droppableId={`${ind}_2_${++i}_${key}_${name}`} type={'inner'}>
          {(provided, snapshot) => {
            return (
              <div
                ref={provided.innerRef}
                className="draggable-item"
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                <p className="sectionHeadingRightNavLevel2">{key} <span style={{position:'relative',top:'-3px',color:'rgb(48, 90, 128)'}}>{value.length}</span></p>
                {provided.placeholder}
              </div>
            )}}
        </Droppable>
      );
    }
    return list;
  }

  function showItems(items,ind,name) {
    const list=[];
    let i=0;
    for (const [key, value] of Object.entries(items)) {
      list.push(
        <Droppable key={`${key}_1`} name={key} droppableId={`${ind}_1_${++i}_${key}_${name}`} type={'inner'}>
          {(provided, snapshot) => {
            return (
              <div
                ref={provided.innerRef}
                className="draggable-item"
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                <p className="sectionHeadingLevel2">{key}</p>
                { value.map((item,index)=> (
                <Draggable
                  key={item.id}
                  name={item.name}
                  draggableId={item.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around"
                        }}
                      >
                        {item.name}
                      </div>
                    </div>
                  )}
                </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}}
        </Droppable>
      );
    }
    return list;
  }

  return (
    <div style={{marginLeft:'1em'}} className={'bs-drag-drop'}>
      <DragDropContext onDragEnd={onDragEnd}>
        <div style={{display:'flex',flexFlow:'row nowrap',maxWidth: 'calc(100vw - 1em)',height: 'calc(100vh - 287px)', overflow: 'hidden'}}>
          <div style={{flex:'0 0 calc(100vw - 286px)',overflow: 'auto',border: '1px solid rgb(48, 90, 128)'}}>
          <div style={dragdropContent}>
            {state.map((el, ind) => {
                    return (
                      <div
                        className="draggable-item-bs"
                        key={ind}
                      >
                        <p className="sectionHeading">{el[0].name}</p>
                        {showItems(el[0].items,`${ind}`,el[0].name)}
                      </div>
                    )}
            )}
          </div>
        </div>
        <div style={{overflowX:'hidden',overflowY:'auto'}}>
          <div className="rightCategories" style={rightCategories}>
            <p className={'rightCategoriesHeading'}>BS Categories</p>
            {state.map((el, ind) => {
              return (
                <div
                  className="draggable-item-bs"
                  key={ind}
                >
                  <p className="sectionHeadingRightNav">{el[0].name}</p>
                  {showRightNavItems(el[0].items,`${ind}`,el[0].name)}
                </div>
              )}
            )}
          </div>
        </div>
        </div>
      </DragDropContext>
    </div>
  )
}
export default BsAccounts;