import { toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { stringHash } from '../util/StringHash';
import './notify.css';
import {Mixpanel} from "../mixpanel";

function notify({type = '', message = 'Something went wrong'}) {
    const options = {
        type: toast.TYPE[type.toUpperCase()],
        position: toast.POSITION.TOP_RIGHT,
        transition: Slide,
        autoClose: 5000,
        pauseOnHover: true,
        toastId: stringHash(message)
    };
    Mixpanel.track('Got Notification',{'message':message});
    toast(message, options);
}

export default notify;