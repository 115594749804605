import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend, ReferenceLine,
} from 'recharts';
import Loader from "../reusables/Loader";
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';
import ErrorResponse from "../error-response/error-response";
import ChartInfoIcon from "../chart-info";

const colors = scaleOrdinal(schemeCategory10).range();

const LINE_CHART_HEIGHT = 360;

const CustomTooltip = (props) => {
  const { active, payload, label,formatter } = props;
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="tooltip-heading">{`${label}`}</p>
        { payload.map((item,index)=>{
          return (
            <div key={index} style={{color: item.fill}}>
              <span className="tooltip-label">{item.dataKey}: </span>
              <span className="tooltip-value">{formatter(item.value)}</span>
            </div>
          )})}
      </div>
    );
  }

  return null;
};
const BsRepresentationBarChart=(props)=> {
  const {data,loading,error,formatter,dataTestId,chartInfoCopy} = props;
  if(loading) {
    return <div style={{margin:'1em 0 2em 0',flex:'0 0 50%'}}><Loader dataTestId={`${dataTestId}-loading`} type={'pulse'} size={10}/></div>
  }
  if(error){
    return (<ErrorResponse dataTestId={`${dataTestId}-error`} error={error}/>)
  }
  const res=[];
  if(data && !loading) {
    for (const [key, value] of Object.entries(data)) {
      res.push({name:key,...value});
    }
    const BAR_GAP = 0;
    const BAR_SIZE = 50;
    return (
      <div className={`simple-charts-wrapper container-for-chart-info-icon`} data-testid={dataTestId} style={{fontSize: '0.8em', width: '100%', maxWidth: '700px'}}>
          <ChartInfoIcon text={chartInfoCopy}/>
          <ResponsiveContainer height={LINE_CHART_HEIGHT}>
          <BarChart
            height={LINE_CHART_HEIGHT}
            data={res}
            margin={{top: 5, right: 30, left: 20, bottom: 5}}
          >
            <XAxis dataKey="name" />
            <YAxis tickFormatter={(value)=> formatter(value)}/>
            <ReferenceLine y={0} stroke="#000" />
            <Tooltip content={<CustomTooltip/>} formatter={(value)=> formatter(value)} />
            {
              res.map((obj,index) =>{
                const stackId= obj.name;
                const barsList =[];
                let i =0;
                const barListCreate =(sign,key,pos)=>{
                  barsList.push(<Bar
                    barSize={BAR_SIZE}
                    transform={`translate(${pos},0)`}
                    //transform={`translate(${sign === 'positive' ? (BAR_GAP + BAR_SIZE)/2:-(BAR_GAP + BAR_SIZE)/2}, 0)`}
                    dataKey={key}
                    stackId={ `${sign === 'positive'? `positive-${stackId}`: `negative-${stackId}`}`}
                    fill={colors[i++]}/>);
                }
                for (const [key,value] of Object.entries(obj)) {
                  if(key !== 'name'){
                    if(stackId === 'Assets'){
                      if(parseInt(value)>= 0) {
                        barListCreate('positive',key, 50+ (BAR_SIZE+BAR_GAP)/2)
                      }
                      else{
                        barListCreate('negative',key,50 - (BAR_SIZE+BAR_GAP)/2)
                      }
                    }
                    if(stackId === 'Liabilities'){
                      if(parseInt(value)>= 0) {
                        barListCreate('positive',key, (BAR_SIZE + BAR_GAP) / 2)
                      }
                      else{
                        barListCreate('negative',key, (BAR_SIZE + BAR_GAP) / 2)
                      }
                    }
                    if(stackId === 'Equity'){
                      if(parseInt(value)>= 0) {
                          barListCreate('positive',key,(BAR_SIZE+BAR_GAP)/2 - 50)
                      }
                      else{
                        barListCreate('negative',key,-(BAR_SIZE+BAR_GAP)/2 - 45)
                      }
                    }
                  }
                }
                return [...barsList];
              })
            }
            <Legend />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
  return (<div>{}</div>)
}
export default BsRepresentationBarChart;
