/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import Token from '../util/TokenUtil';
import qokoon_title from '../img/logo.svg';
import withErrorBoundary from '../error/ErrorBoundary';
import { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { ImUser } from 'react-icons/im';
import { HeaderCSS } from './HeaderCSS';
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import notify from "../notify/notify";
import {Mixpanel} from "../mixpanel";

function Header() {
    const history = useHistory();
    const company = useSelector(state => state.selectedCompany);
    const dispatch=useDispatch();
    const logout =async () => {
        const headers = {
            'Content-Type': 'text/plain;charset=UTF-8',
        };

        const res = await axios(`${process.env.REACT_APP_BACKEND_BASE_URL}/logout`,{
            method: "delete",
            withCredentials: true,
            headers:headers
        });
        if(res.status === 200){
            const name = localStorage.getItem("name");
            localStorage.removeItem('authOrg');
            localStorage.removeItem('name');
            localStorage.removeItem('isDev');
            dispatch({type:'RESET'});
            Mixpanel.track('Logout',{name});
            history.replace('/login');
        }
        else{
            notify({type: 'error', message: 'Something went wrong while logging out!'});
            dispatch({type:'RESET'});
        }
    }
    const legalDocsURLs = ['/privacypolicy', '/termsandconditions', '/cookiepolicy'];
    const location = useLocation();
    const userName = Token.givenName();
    const [displayUserMenu, setDisplayUserMenu] = useState(false);
    const hideUserMenu = event => setDisplayUserMenu(event.target.className === 'user-menu');
    const toggleUserMenu = event => { 
        event.stopPropagation(); 
        setDisplayUserMenu(!displayUserMenu);
    }
    useEffect(() => {
        document.addEventListener('click', hideUserMenu);

        return(() => document.removeEventListener('click', hideUserMenu));

    });
    
    return (
        <header className="header" css={HeaderCSS}>
            <Link to="/" onClick={()=>{
                Mixpanel.track('Url Change',{company:company.companyName,url:'home'});
            }}><img src={qokoon_title} id="qokoon_title" alt="qb_title" /></Link>

            <Link className='glossary-link' target="_blank" to='/glossary' onClick={()=>{
                Mixpanel.track('Url Change',{company:company.companyName,url:'/glossary'}) }}>Glossary</Link>

            <Link className='how-to-link' target="_blank" to='/how-to' onClick={()=>{
                Mixpanel.track('Url Change',{company:company.companyName,url:'/how-to'}) }}>How to?</Link>
            {
                !legalDocsURLs.includes(location.pathname) && <div className="header-right">
                    <div data-testid="user-menu-toggle" onClick={toggleUserMenu}><ImUser /></div>
                    {
                        displayUserMenu && <ul className="user-menu">
                            <li>{userName}</li>
                            <li onClick={logout}>Sign out</li>
                        </ul>
                    }
                </div>
            }
        </header>
    );
}

export default withErrorBoundary(Header);
