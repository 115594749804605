import React from 'react';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';
import Loader from "../reusables/Loader";
import ErrorResponse from "../error-response/error-response";
import ChartInfoIcon from "../chart-info";

const data = [
  {
    subject: 'Total Sales',
    A: 120,
    B: 110,
    fullMark: 150,
  },
  {
    subject: 'Cost of Sales',
    A: 98,
    B: 130,
    fullMark: 150,
  },
  {
    subject: 'Gross Profit',
    A: 86,
    B: 130,
    fullMark: 150,
  },
  {
    subject: 'Total SG&A',
    A: 99,
    B: 100,
    fullMark: 150,
  },
  {
    subject: 'EBITDA',
    A: 85,
    B: 90,
    fullMark: 150,
  },
  {
    subject: 'EBITDA Margin',
    A: 65,
    B: 85,
    fullMark: 150,
  },
];
const RADAR_CHART_HEIGHT = 360;
const RadarChartComp=(props)=> {
  const {loading,error,dataTestId,chartInfoCopy, fullWidth=false} = props;
  if(loading) {
    return <div style={{margin:'1em 0 2em 0'}}><Loader dataTestId={`${dataTestId}-loading`} type={'pulse'} size={10}/></div>
  }
  if(data.length > 0 && !loading) {
    return (
      <div className={`simple-charts-wrapper`} style={{fontSize: '0.8em', width: '100%', maxWidth: fullWidth?'100%':'700px'}}>
        <ChartInfoIcon text={chartInfoCopy}/>
        <ResponsiveContainer height={RADAR_CHART_HEIGHT}>
          <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
            <PolarGrid />
            <PolarAngleAxis dataKey="subject" />
            <PolarRadiusAxis />
            <Radar dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
          </RadarChart>
        </ResponsiveContainer>
      </div>
    );
  }
  if(error){
    return (<ErrorResponse dataTestId={`${dataTestId}-error`} error={error}/>)
  }
  return (<div className="chart-error error-icon"><span>Data not available</span></div>)
}
export default RadarChartComp;
