import React, {useEffect, useRef, useState} from "react";
import expandIcon from '../img/expand-icon.svg';
import collapseIcon from '../img/collapse-icon.svg';
import CustomSelect from "../custom-select";

const ReportSection =({children,showControls,name,addRemoveSectionList, toggleCallback, commentMode,titleWidth=300})=>{
  const sectionRef=useRef(null);
  const [isExpand,setIsExpand]=useState(true);
  const [sectionHeight,setSectionHeight] =useState(100);
  const [commentPosition,setCommentPosition] =useState(null);
  const [commentText,setCommentText] =useState('');
  const toggleSectionClick =()=>{
    if(isExpand){
      setIsExpand(false);
      toggleCallback(name,'add');
    }
    else{
      setIsExpand(true);
      toggleCallback(name,'remove');
    }
  }
  useEffect(()=>{
    const ref = sectionRef.current;
    if(ref !== null){
      setSectionHeight(ref.getBoundingClientRect().height);
    }
  },[children,showControls,name,addRemoveSectionList, toggleCallback, commentMode,commentPosition])

  function textAreaTextChange(e){
    setCommentText(e.target.value);
  }
  if(!showControls && !commentMode) {
    if(addRemoveSectionList.includes(name)){
      return <div>{}</div>
    }
    else {
      return (
        <div className='without-controls'>
          {!commentMode && commentText !== '' && commentPosition ==='Top' && <label className={'comment-label'}>{commentText}</label>}
          {children}
          {!commentMode && commentText !== '' && commentPosition ==='Bottom' && <label className={'comment-label'}>{commentText}</label>}
        </div>
      )
    }
  }
  else if(commentMode && !showControls){
    return(
      <div ref={sectionRef}  id={name.replace(/ +/g, "")} className={`section-toggler ${isExpand?'expand':'collapse'} ${commentMode? 'commentMode':''} ${commentPosition  && commentPosition === 'Top'?'comment-top':'comment-bottom'}`} >
        { commentMode && commentPosition ==='Top' && showTextBox()}
        {!commentMode && commentText !== '' && commentPosition ==='top' && <label className={'comment-label'}>{commentText}</label>}
        {commentMode && <div style={{height:`${sectionHeight - `${commentPosition !== null ? 60: 0}`}px`, display:'none'}} className={'commentModeControlsWrapper'}>
          <div className={'comment-mode-select-wrapper'}>
            <label className={'comment-mode-select-label'}>Please select comment position</label>
            <CustomSelect
              dataTestId={'comment-location'}
              selectedValue={commentPosition}
              options={[{label: 'Top', selectable: true, disconnected:false},{label: 'Bottom', selectable: true, disconnected:false}]}
              className={`companySwitcher`}
              callback={(text)=> setCommentPosition(text)}/>
          </div>
        </div>}
        <div className='toggle-component'>{children}</div>
        { commentMode && commentPosition ==='Bottom' && showTextBox()}
        {!commentMode && commentText !== '' && commentPosition ==='Bottom' && <label className={'comment-label'}>{commentText}</label>}
      </div>
    )
  }

  function showTextBox() {
    return (
      <textarea  value={commentText} onChange={textAreaTextChange} style={{width:'calc(100% - 3em)',resize:'none'}} rows="3"> </textarea>
    )
  }

  return(
    <div ref={sectionRef} id={name.replace(/ +/g, "")} className={`section-toggler ${isExpand?'expand':'collapse'} ${commentMode? 'commentMode':''} ${commentPosition  && commentPosition === 'Top'?'comment-top':'comment-bottom'}`} >
      { commentMode && commentPosition ==='Top' && showTextBox()}
      {!commentMode && commentText !== '' && commentPosition ==='Top' && <label className={'comment-label'}>{commentText}</label>}
      {commentMode && <div style={{height:`${sectionHeight - `${commentPosition !== null ? 60: 0}`}px`, display:'none'}} className={'commentModeControlsWrapper'}>
        <div className={'comment-mode-select-wrapper'}>
          <label className={'comment-mode-select-label'}>Please select comment position</label>
          <CustomSelect
            dataTestId={'comment-location'}
            selectedValue={commentPosition}
            options={[{label: 'Top', selectable: true, disconnected:false},{label: 'Bottom', selectable: true, disconnected:false}]}
            className={`companySwitcher`}
            callback={(text)=> setCommentPosition(text)}/>
        </div>
      </div>}
      <span onClick={toggleSectionClick} style={{width:`${titleWidth}px`,left:`calc(50% - ${titleWidth/2}px)` }} className={`toggler-section-name`}>{name}</span>
      <div onClick={toggleSectionClick} className={'toggle-icon-wrapper'}><img alt="expand-collapse-icon" src={isExpand? collapseIcon:expandIcon}/></div>
      <div className='toggle-component'>{children}</div>
      { commentMode && commentPosition ==='Bottom' && showTextBox()}
      {!commentMode && commentText !== '' && commentPosition ==='Bottom' && <label className={'comment-label'}>{commentText}</label>}
    </div>
  )
}
export default ReportSection;