import React, {Fragment, useEffect, useState} from "react";
import CustomMonthYearSelect from "../custom-month-year-select";
import {useSelector} from "react-redux";
import {formatCurrency} from "../analysis-helper";
import {
  currentMonth,
  currentYear,
  getMonthOptionsBasedOnYearSelected,
  monthsInAnYear,
  formatPercentAndCheckSign,
  formatCurrencyAndCheckSign,
} from "./common-functions";
import useGet from "../hooks/use-get";
import Loader from "../reusables/Loader";
import withErrorBoundary from "../error/ErrorBoundary";
import notify from "../notify/notify";
import ErrorResponse from "../error-response/error-response";


const BsFinancials =({view})=> {

  const [sortOrder,setSortOrder] =useState(['period1-value','descending']);

  const [selectedDateStart,setSelectedDateStart] = useState({month:currentMonth,year:currentYear});
  const [selectedDateEnd,setSelectedDateEnd] = useState({month:currentMonth,year:currentYear-1});

  const company = useSelector(state => state.selectedCompany);

  const [monthOptionsStart, setMonthOptionsStart] = useState(getMonthOptionsBasedOnYearSelected(selectedDateStart));

  const [monthOptionsEnd, setMonthOptionsEnd] = useState(getMonthOptionsBasedOnYearSelected(selectedDateEnd.year));
  const [bsData,setBsData]= useState([]);

  const [leftDurationMsg, setLeftDurationMsg] = useState(null);
  const [rightDurationMsg, setRightDurationMsg] = useState(null);

  const getSelectedDateStart =()=>{
    const month = monthsInAnYear.indexOf(selectedDateStart.month)+1;
    return `${month.toString().length===1? `0${month}`:`${month}`}-${selectedDateStart.year.toString().substr(2,2)}`;
  }

  const getSelectedDateEnd =()=>{
    const month = monthsInAnYear.indexOf(selectedDateEnd.month)+1;
    return `${month.toString().length===1? `0${month}`:`${month}`}-${selectedDateEnd.year.toString().substr(2,2)}`;
  }

  const [bsResponse,bsLoading, bsError,] = useGet({
    url: `${process.env.REACT_APP_DJANGO_BACKEND_BASE_URL}/financials/BS/${company.companyId}/${getSelectedDateStart()}/${getSelectedDateEnd()}`});

  const mapData=(obj)=>{
    const sortingOrder = sortOrder[0].split('-');
    return obj.section_data ? obj.section_data.reduce((converted, section) => {
      if (Array.isArray(section)) {
        converted.push({name: section[0].name, values: section[0].values[0]});
      } else {
        for (const [key, value] of Object.entries(section.subcategories)) {
          const name = key;
          const values = value.values[0];
          const subsubcategories = value.subsubcategories.sort((a,b)=>{
            if (sortOrder[1] === 'descending') {
              return b.values[0][sortingOrder[0]][sortingOrder[1]] - a.values[0][sortingOrder[0]][sortingOrder[1]]
            } else {
              return a.values[0][sortingOrder[0]][sortingOrder[1]] - b.values[0][sortingOrder[0]][sortingOrder[1]]
            }
          })
          if(!converted[converted.length - 1]['subcategories']){
            converted[converted.length -1]['subcategories']=[]
          }
          converted[converted.length - 1]['subcategories'].push({
            name: name,
            values: values,
            subsubcategories: subsubcategories
          })
        }
      }
      return converted;
    }, []): null;
  }
  const extractData =(data)=>{
    const assets = {name: data['section1'].name}
    if (data['section1'].values) {
      assets['values'] = data['section1'].values[0];
    }
    const liabilities = {name: data['section2'].name};
    if (data['section2'].values) {
      liabilities['values'] = data['section2'].values[0];
    }
    const equity = {name: data['section3'].name};
    if (data['section3'].values) {
      equity['values'] = data['section3'].values[0];
    }
    assets['section_data'] = mapData(data['section1']);
    liabilities['section_data'] = mapData(data['section2']);
    debugger;
    equity['section_data'] = mapData(data['section3']);

    const result = [];
    result.push(assets);
    result.push(liabilities);
    result.push(equity);
    return result;
  }
  const extractMessages=(section,side)=>{
    if(side==='Left') {
      section.forEach((obj)=>{
        if (obj.NoDataSide) {
          setLeftDurationMsg({msg: obj.NoDataMessage, error: true});
        } else if (obj.LimitedDataSide) {
          setLeftDurationMsg({msg: obj.LimitedDataMessage, error: true});
        } else if (obj.IdealCaseSide) {
          setLeftDurationMsg({msg: obj.IdealCaseMessage, error: false});
        }
      })
    }
    else if(side === 'Right'){
      section.forEach((obj)=> {
        if (obj.NoDataSide) {
          setRightDurationMsg({msg: obj.NoDataMessage, error: true});
        } else if (obj.LimitedDataSide) {
          setRightDurationMsg({msg: obj.LimitedDataMessage, error: true});
        } else if (obj.IdealCaseSide) {
          setRightDurationMsg({msg: obj.IdealCaseMessage, error: false});
        }
      })
    }
  }
  useEffect(()=>{
    if (!bsLoading && bsResponse && !bsError) {
      if(bsResponse.length === 3){
        extractMessages(bsResponse[0],'Left');
        extractMessages(bsResponse[1],'Right');
        const data = bsResponse[2][0];
        setBsData(extractData(data));
      }
      else{
        const data = bsResponse[0][0];
        setBsData(extractData(data));
      }
    }
    if(bsError){
      notify({ type: 'error', message: 'Something went wrong while fetching BS Financials' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[bsResponse, bsLoading, bsError, view, sortOrder, selectedDateStart, selectedDateEnd,]);


  const handleYearChangeStart = (selectedYearString) => {
    setSelectedDateStart(date=>({month: date.month,year:selectedYearString}))
    setMonthOptionsStart(getMonthOptionsBasedOnYearSelected(selectedYearString));
  }
  const handleYearChangeEnd = (selectedYearString) => {
    setSelectedDateEnd(date=>({month: date.month,year:selectedYearString}))
    setMonthOptionsEnd(getMonthOptionsBasedOnYearSelected(selectedYearString));
  }
  const handleMonthChangeStart = (month) => {
    setSelectedDateStart(date=> ({month:month,year:date.year}))
  }
  const handleMonthChangeEnd = (month) => {
    setSelectedDateEnd(date=> ({month:month,year:date.year}))
  }

  const handleSorting=(order)=>{
    if(order === sortOrder[0]){
      if(sortOrder[1]==='descending'){
        setSortOrder([order,'ascending']);
      }
      else{
        setSortOrder([order,'descending']);
      }
    }
    else{
      setSortOrder([order,'descending']);
    }
  }
  return(
    <div style={{margin: '0 1em',padding: '1em',border: '1px solid #305A80'}}>
      <table className={`financials-table ${view.replace(/ /g,'')}`}>
        <thead>
        <tr>
          <th>
            {}
          </th>
          <th>
            <div>
              { <span>Ending in</span>}
              <CustomMonthYearSelect monthOptions={monthOptionsStart}
                                     selectedMonth={selectedDateStart.month}
                                     selectedYear={selectedDateStart.year}
                                     callbackMonthChange={handleMonthChangeStart}
                                     callBackYearChange={handleYearChangeStart}
              />
            </div>
            <div>
              {!leftDurationMsg && rightDurationMsg && <div>&nbsp;</div>}
              {leftDurationMsg && <div style={{color:`${leftDurationMsg.error? '#E02020':'#090909'}`,fontWeight:'500',fontSize:'0.9em'}}>{leftDurationMsg.msg}</div>}
            </div>
          </th>
          <th>
            <div>
              {<span>Ending in</span>}
              <CustomMonthYearSelect monthOptions={monthOptionsEnd}
                                     selectedMonth={selectedDateEnd.month}
                                     selectedYear={selectedDateEnd.year}
                                     callbackMonthChange={handleMonthChangeEnd}
                                     callBackYearChange={handleYearChangeEnd}
              />
            </div>
            <div>
              {leftDurationMsg && !rightDurationMsg && <div>&nbsp;</div>}
              {rightDurationMsg && <div style={{color:`${rightDurationMsg.error? '#E02020':'#090909'}`,fontWeight:'500',fontSize:'0.9em'}}>{rightDurationMsg.msg}</div>}
            </div>
          </th>
          <th>
            {}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr className={'table-heading intermediate-view'}>
          <td><span className="financial-table-heading">Balance Sheet</span></td>
          <td><div><span onClick={()=>handleSorting('period1-value')} className={`sort-elem ${sortOrder[0]=== 'period1-value'? `active ${sortOrder[1]}`:''}`}>{formatCurrency(0,company.currency).substring(0,1)}</span><span onClick={()=>handleSorting('period1-percentage')} className={`sort-elem ${sortOrder[0]=== 'period1-percentage'? `active ${sortOrder[1]}`:''}`}>% of Assets</span></div></td>
          <td><div><span onClick={()=>handleSorting('period2-value')} className={`sort-elem ${sortOrder[0]=== 'period2-value'? `active ${sortOrder[1]}`:''}`}>{formatCurrency(0,company.currency).substring(0,1)}</span><span onClick={()=>handleSorting('period2-percentage')} className={`sort-elem ${sortOrder[0]=== 'period2-percentage'? `active ${sortOrder[1]}`:''}`}>% of Assets</span></div></td>
          <td><div><span onClick={()=>handleSorting('difference-value')} className={`sort-elem ${sortOrder[0]=== 'difference-value'? `active ${sortOrder[1]}`:''}`}>Diff ({formatCurrency(0,company.currency).substring(0,1)})</span><span onClick={()=>handleSorting('difference-percentage')} className={`sort-elem ${sortOrder[0]=== 'difference-percentage'? `active ${sortOrder[1]}`:''}`}>Diff (%)</span></div></td>
        </tr>
        <tr className={`table-heading compact-view`}>
          <td><span className="financial-table-heading">Balance Sheet</span></td>
          <td><div><span className={`sort-elem`}>{formatCurrency(0,company.currency).substring(0,1)}</span><span className={`sort-elem`}>% of Assets</span></div></td>
          <td><div><span className={`sort-elem`}>{formatCurrency(0,company.currency).substring(0,1)}</span><span className={`sort-elem`}>% of Assets</span></div></td>
          <td><div><span className={`sort-elem`}>Diff ({formatCurrency(0,company.currency).substring(0,1)})</span><span className={`sort-elem`}>Diff (%)</span></div></td>
        </tr>
        {bsLoading && <div style={{margin:'0 auto',width:'200px'}}><Loader dataTestId={`bs-financial-loading`} type={'pulse'} size={10}/></div>}
        {!bsLoading && !bsError && bsData.map((row,index)=>{
          return(
            <Fragment key={index}>
              <tr className={`cat-row detailed`}>
                <td>{row.name}</td>
                <td><div><span>{row.values && formatCurrencyAndCheckSign(row.values.period1.value,company)}</span><span>{row.values && formatPercentAndCheckSign(row.values.period1.percentage)}</span></div></td>
                <td><div><span>{row.values && formatCurrencyAndCheckSign(row.values.period2.value,company)}</span><span>{row.values && formatPercentAndCheckSign(row.values.period2.percentage)}</span></div></td>
                <td><div><span>{row.values && formatCurrencyAndCheckSign(row.values.difference.value,company)}</span><span>{row.values && formatPercentAndCheckSign(row.values.difference.percentage)}</span></div></td>
              </tr>
              {row.section_data && row.section_data.map((section)=>{
                return (
                  <Fragment key={index}>
                    {section.name !== 'To Be Removed' && <tr className={`subRow-section`}>
                      <td>{section.name}</td>
                      <td><div><span>{section.values && formatCurrencyAndCheckSign(section.values.period1.value,company)}</span><span>{section.values && formatPercentAndCheckSign(section.values.period1.percentage)}</span></div></td>
                      <td><div><span>{section.values && formatCurrencyAndCheckSign(section.values.period2.value,company)}</span><span>{section.values && formatPercentAndCheckSign(section.values.period2.percentage)}</span></div></td>
                      <td><div><span>{section.values && formatCurrencyAndCheckSign(section.values.difference.value,company)}</span><span>{section.values && formatPercentAndCheckSign(section.values.difference.percentage)}</span></div></td>
                    </tr>}
                    {
                      section.subcategories && section.subcategories.map((subCat)=>{
                        return(
                          <Fragment >
                            <tr className={`subRow-subSection`}>
                              <td>{subCat.name}</td>
                              <td><div><span>{formatCurrencyAndCheckSign(subCat.values.period1.value,company)}</span><span>{formatPercentAndCheckSign(subCat.values.period1.percentage)}</span></div></td>
                              <td><div><span>{formatCurrencyAndCheckSign(subCat.values.period2.value,company)}</span><span>{formatPercentAndCheckSign(subCat.values.period2.percentage)}</span></div></td>
                              <td><div><span>{formatCurrencyAndCheckSign(subCat.values.difference.value,company)}</span><span>{formatPercentAndCheckSign(subCat.values.difference.percentage)}</span></div></td>
                            </tr>
                            {
                              subCat.subsubcategories && subCat.subsubcategories.map((subSubCat) => {
                                return (
                                  <tr className={'subSubSection-subcategories'}>
                                    <td>
                                      {subSubCat.name}
                                    </td>
                                    <td>
                                      <div>
                                        <span>{formatCurrencyAndCheckSign(subSubCat.values[0].period1.value,company)}</span><span>{formatPercentAndCheckSign(subSubCat.values[0].period1.percentage)}</span>
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        <span>{formatCurrencyAndCheckSign(subSubCat.values[0].period2.value,company)}</span><span>{formatPercentAndCheckSign(subSubCat.values[0].period2.percentage)}</span>
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        <span>{formatCurrencyAndCheckSign(subSubCat.values[0].difference.value,company)}</span><span>{formatPercentAndCheckSign(subSubCat.values[0].difference.percentage)}</span>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })
                            }
                          </Fragment>
                        )
                      })
                    }
                  </Fragment>
                )})}
            </Fragment>
          )
        })}
        {bsError && <ErrorResponse dataTestId={`bs-financial-error`} error={bsError}/>}
        </tbody>
      </table>
    </div>
  )
}
export default withErrorBoundary(BsFinancials);
