import React, {Fragment} from "react";
import ReportSection from "./report-section";
import SimpleLineChart from "../simple-line-chart";
import {formatCurrency} from "../analysis-helper";
import infoCopies from "../chart-info-copies";
import LineChartDoubleYAxis from "../line-chart-double-y-axis";
import AreaChartMultipleSeries from "../area-chart-multiple-series";

const TrendsReportSection =({
                              modifySectionToggle,
                              addRemoveSectionList,
                              addRemoveSection,
                              commentModeToggle,
                              company,
                              lTMPrevChartLoading,
                              lTMPrevChartError,
                              lTMPrevChart,
                              monthlyEvolutionChartLoading,
                              monthlyEvolutionChartError,
                              monthlyEvolutionChart,
                              ltmEvolutionChartLoading,
                              ltmEvolutionChartError,
                              ltmEvolutionChart,
                              overallEbitdaEvolutionMonthLoading,
                              overallEbitdaEvolutionMonthError,
                              overallEbitdaEvolutionMonth,
                              overallEbitdaEvolutionLTMLoading,
                              overallEbitdaEvolutionLTMError,
                              overallEbitdaEvolutionLTM,
                              netIncomeEvolutionMonthLoading,
                              netIncomeEvolutionMonthError,
                              netIncomeEvolutionMonth,
                              historicTrendComponentsChartLoading,
                              historicTrendComponentsChartError,
                              historicTrendComponentsChart,
                              historicTrendLiabilitiesComponentsChartLoading,
                              historicTrendLiabilitiesComponentsChartError,
                              historicTrendLiabilitiesComponentsChart,
                              historicTrendEquityComponentsChartLoading,
                              historicTrendEquityComponentsChartError,
                              historicTrendEquityComponentsChart
                            })=>{
  return(
    <Fragment>
      <div style={{display: 'flex'}}>
        <div style={{flex: '0 0 50%', padding: '0px 0px 1em 1.5em'}}>
          <ReportSection
            showControls={modifySectionToggle}
            name={'Last Twelve Months Trend VS Previous Year'}
            addRemoveSectionList={addRemoveSectionList}
            toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
            commentMode={commentModeToggle}
          >
            <div className='lineChartHeading'>
              <span className='lineChartHeadingLabel'>LAST TWELVE MONTHS TREND VS PREVIOUS YEAR</span>
            </div>
            <SimpleLineChart
              dataTestId={'line-ltm-prev'}
              formatter={(value) => formatCurrency(value, company.currency)}
              loading={lTMPrevChartLoading}
              error={lTMPrevChartError}
              showActiveDot={false}
              data={lTMPrevChart}
              chartInfoCopy={infoCopies.lastTwelveMonthTrendVsYear}
            />
          </ReportSection>
        </div>
        <div style={{flex: '0 0 50%', padding: '0px 0px 1em 1.5em'}}>
          <ReportSection
            showControls={modifySectionToggle}
            name={'Sales Categories'}
            addRemoveSectionList={addRemoveSectionList}
            toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
            commentMode={commentModeToggle}
          >
            <div className='lineChartHeading'>
              <span className='lineChartHeadingLabel'>Sales Categories</span>
            </div>
            <SimpleLineChart
              dataTestId={'line-ltm-prev'}
              formatter={(value) => formatCurrency(value, company.currency)}
              loading={lTMPrevChartLoading}
              error={lTMPrevChartError}
              data={lTMPrevChart}
              showActiveDot={false}
              chartInfoCopy={infoCopies.lastTwelveMonthTrendVsYear}
            />
          </ReportSection>
        </div>
      </div>

      <div style={{display: 'flex'}}>
        <div style={{flex: '0 0 50%', padding: '0px 0px 1em 1.5em'}}>
          <ReportSection
            showControls={modifySectionToggle}
            name={'Cost of Sales as % of Sales Evolution (Monthly)'}
            addRemoveSectionList={addRemoveSectionList}
            toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
            commentMode={commentModeToggle}
          >
            <div className='lineChartHeading'>
              <span className='lineChartHeadingLabel'>cost of Sales as % of sales Evolution (Monthly)</span>
            </div>
            <LineChartDoubleYAxis
              dataTestId={'line-area-monthly-evolution'}
              dataValue={'Cost of Sales'}
              percentageOf={'% of Sales'}
              showGrid={false}
              showDot={false}
              formatter={(value) => formatCurrency(value, company.currency)}
              loading={monthlyEvolutionChartLoading} error={monthlyEvolutionChartError}
              data={monthlyEvolutionChart}
              chartInfoCopy={(infoCopies.allMonthlyCharts('Cost of Sales'))}
            />
          </ReportSection>
        </div>
        <div style={{flex: '0 0 50%', padding: '0px 0px 1em 1.5em'}}>
          <ReportSection
            showControls={modifySectionToggle}
            name={'Cost of Sales as % of Sales Evolution (LTM)'}
            addRemoveSectionList={addRemoveSectionList}
            toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
            commentMode={commentModeToggle}
          >
            <div className='lineChartHeading'>
              <span className='lineChartHeadingLabel'>Cost of Sales as % of sales Evolution (LTM)</span>
            </div>
            <LineChartDoubleYAxis
              dataTestId={'line-area-ltm-evolution'}
              dataValue={'Cost of Sales'}
              percentageOf={'% of Sales'}
              showGrid={false}
              showDot={false}
              withoutArea={true}
              formatter={(value) => formatCurrency(value, company.currency)}
              loading={ltmEvolutionChartLoading} error={ltmEvolutionChartError}
              data={ltmEvolutionChart}
              chartInfoCopy={(infoCopies.allMonthlyCharts('Cost of Sales'))}
            />
          </ReportSection>
        </div>
      </div>

      <div style={{display: 'flex'}}>
        <div style={{flex: '0 0 50%', padding: '0px 0px 1em 1.5em'}}>
          <ReportSection
            showControls={modifySectionToggle}
            name={'EBITDA Evolution - Monthly'}
            addRemoveSectionList={addRemoveSectionList}
            toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
            commentMode={commentModeToggle}
          >
            <div className='lineChartHeading'>
              <span className='lineChartHeadingLabel'>EBITDA Evolution - Monthly</span>
            </div>
            <LineChartDoubleYAxis
              dataTestId={`overall-ebitda-evolution-month`}
              percentageOf={'EBITDA as % of sales'}
              dataValue={'EBITDA'}
              showGrid={false}
              formatter={(value) => formatCurrency(value, company.currency)}
              loading={overallEbitdaEvolutionMonthLoading}
              error={overallEbitdaEvolutionMonthError}
              replaceAreaWithBar={true}
              showDot={false}
              data={overallEbitdaEvolutionMonth}
              chartInfoCopy={infoCopies.allMonthlyCharts('EBITDA')}
            />
          </ReportSection>
        </div>
        <div style={{flex: '0 0 50%', padding: '0px 0px 1em 1.5em'}}>
          <ReportSection
            showControls={modifySectionToggle}
            name={'EBITDA Evolution - LTM'}
            addRemoveSectionList={addRemoveSectionList}
            toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
            commentMode={commentModeToggle}
          >
            <div className='lineChartHeading'>
              <span className='lineChartHeadingLabel'>EBITDA Evolution - LTM</span>
            </div>
            <LineChartDoubleYAxis
              dataTestId={`overall-ebitda-evolution-ltm`}
              percentageOf={'EBITDA as % of sales'}
              dataValue={'EBITDA'}
              showGrid={false}
              showDot={false}
              formatter={(value) => formatCurrency(value, company.currency)}
              loading={overallEbitdaEvolutionLTMLoading}
              error={overallEbitdaEvolutionLTMError}
              replaceAreaWithBar={true}
              data={overallEbitdaEvolutionLTM}
              chartInfoCopy={infoCopies.allLtmCharts('EBITDA')}
            />
          </ReportSection>
        </div>
      </div>

      <div style={{display: 'flex'}}>
        <div style={{flex: '0 0 50%', padding: '0px 0px 1em 1.5em'}}>
          <ReportSection
            showControls={modifySectionToggle}
            name={'CASH EVOLUTION - MONTHLY BALANCE'}
            addRemoveSectionList={addRemoveSectionList}
            toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
            commentMode={commentModeToggle}
          >
            <div className='lineChartHeading'>
              <span className='lineChartHeadingLabel'>CASH EVOLUTION - MONTHLY BALANCE</span>
            </div>
            <LineChartDoubleYAxis
              dataTestId={`net-income-evolution-month`}
              percentageOf={'Net Income as % of sales'}
              dataValue={'Net Income'}
              showGrid={false}
              showDot={false}
              formatter={(value) => formatCurrency(value, company.currency)}
              loading={netIncomeEvolutionMonthLoading}
              error={netIncomeEvolutionMonthError}
              data={netIncomeEvolutionMonth}
              chartInfoCopy={infoCopies.allMonthlyCharts('Net Income')}
            />
          </ReportSection>
        </div>
        <div style={{flex: '0 0 50%', padding: '0px 0px 1em 1.5em'}}>
          <ReportSection
            showControls={modifySectionToggle}
            name={'DEBT EVOLUTION - MONTHLY BALANCE'}
            addRemoveSectionList={addRemoveSectionList}
            toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
            commentMode={commentModeToggle}
          >
            <div className='lineChartHeading'>
              <span className='lineChartHeadingLabel'>DEBT EVOLUTION - MONTHLY BALANCE</span>
            </div>
            <LineChartDoubleYAxis
              dataTestId={`overall-ebitda-evolution-ltm`}
              percentageOf={'EBITDA as % of sales'}
              dataValue={'EBITDA'}
              showGrid={false}
              showDot={false}
              formatter={(value) => formatCurrency(value, company.currency)}
              loading={overallEbitdaEvolutionLTMLoading}
              error={overallEbitdaEvolutionLTMError}
              data={overallEbitdaEvolutionLTM}
              chartInfoCopy={infoCopies.allLtmCharts('EBITDA')}
            />
          </ReportSection>
        </div>
      </div>

      <div style={{display: 'flex'}}>
        <div style={{flex: '0 0 50%', padding: '0px 0px 1em 1.5em'}}>
          <ReportSection
            showControls={modifySectionToggle}
            name={'Net Debt - Monthly Balance'}
            addRemoveSectionList={addRemoveSectionList}
            toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
            commentMode={commentModeToggle}
          >
            <div className='lineChartHeading'>
              <span className='lineChartHeadingLabel'>Net Debt - MONTHLY BALANCE</span>
            </div>
            <LineChartDoubleYAxis
              dataTestId={`net-income-evolution-month`}
              percentageOf={'Net Income as % of sales'}
              dataValue={'Net Income'}
              showGrid={false}
              showDot={false}
              formatter={(value) => formatCurrency(value, company.currency)}
              loading={netIncomeEvolutionMonthLoading}
              error={netIncomeEvolutionMonthError}
              data={netIncomeEvolutionMonth}
              chartInfoCopy={infoCopies.allMonthlyCharts('Net Income')}
            />
          </ReportSection>
        </div>
      </div>

      <div style={{display: 'flex'}}>
        <div style={{flex: '0 0 50%', padding: '0px 0px 1em 1.5em'}}>
          <ReportSection
            showControls={modifySectionToggle}
            name={'Current Assets Evolution'}
            addRemoveSectionList={addRemoveSectionList}
            toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
            commentMode={commentModeToggle}
          >
            <div className='lineChartHeading'>
              <span className='lineChartHeadingLabel'>Current Assets - Evolution</span>
            </div>
            <AreaChartMultipleSeries
              dataTestId={`historic-trend-components-chart`}
              formatter={(value) => formatCurrency(value, company.currency)}
              loading={historicTrendComponentsChartLoading}
              error={historicTrendComponentsChartError}
              series={historicTrendComponentsChart}
              showGrid={false}
              showDot={false}
              replaceLineWithArea={true}
              chartInfoCopy={infoCopies.bsSummaryOtherCategories.largestComponent('Current Assets')}
            />
          </ReportSection>
        </div>
        <div style={{flex: '0 0 50%', padding: '0px 0px 1em 1.5em'}}>
          <ReportSection
            showControls={modifySectionToggle}
            name={'Current Liabilities Evolution'}
            addRemoveSectionList={addRemoveSectionList}
            toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
            commentMode={commentModeToggle}
          >
            <div className='lineChartHeading'>
              <span className='lineChartHeadingLabel'>Current Liabilities - Evolution</span>
            </div>
            <AreaChartMultipleSeries
              dataTestId={`historic-trend-components-chart`}
              formatter={(value) => formatCurrency(value, company.currency)}
              loading={historicTrendLiabilitiesComponentsChartLoading}
              error={historicTrendLiabilitiesComponentsChartError}
              series={historicTrendLiabilitiesComponentsChart}
              showGrid={false}
              showDot={false}
              chartInfoCopy={infoCopies.bsSummaryOtherCategories.largestComponent('Current Liabilities')}
            />
          </ReportSection>
        </div>
      </div>

      <div style={{display: 'flex'}}>
        <div style={{flex: '0 0 50%', padding: '0px 0px 1em 1.5em'}}>
          <ReportSection
            showControls={modifySectionToggle}
            name={'Equity Evolution'}
            addRemoveSectionList={addRemoveSectionList}
            toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
            commentMode={commentModeToggle}
          >
            <div className='lineChartHeading'>
              <span className='lineChartHeadingLabel'>Equity - Evolution</span>
            </div>
            <AreaChartMultipleSeries
              dataTestId={`historic-trend-components-chart`}
              formatter={(value) => formatCurrency(value, company.currency)}
              loading={historicTrendEquityComponentsChartLoading}
              error={historicTrendEquityComponentsChartError}
              series={historicTrendEquityComponentsChart}
              showGrid={false}
              showDot={false}
              replaceLineWithArea={true}
              chartInfoCopy={infoCopies.bsSummaryOtherCategories.largestComponent('Equity')}
            />
          </ReportSection>
        </div>
      </div>
    </Fragment>
  )
}
export default TrendsReportSection;
