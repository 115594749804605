import React, {useCallback, useEffect, useState} from "react";
import SubHeader from "../subHeader";
import './chart-of-accounts.css';
import PnlAccounts from "./pnl-accounts";
import BsAccounts from "./bs-accounts";
import useGet from "../hooks/use-get";
import {useSelector} from "react-redux";
import Modal from "react-modal";
import notify from "../notify/notify";
import axios from "axios";
import Loader from "../reusables/Loader";
import BackLink from "../back-link";
import ErrorResponse from "../error-response/error-response";
import withErrorBoundary from "../error/ErrorBoundary";

const changeCount={
  background:'#B70223',
  borderRadius:'50%',
  color:'#fff',
  boxShadow:'0px 0px 1px 1px #fff',
  height:'22px',
  width:'22px',
  lineHeight:'22px',
  position:'relative',
  bottom:'5px',
  display:'inline-block',
}

function ChartOfAccounts() {
  const [showBs,setShowBs] =useState(false);
  const [showPnl,setShowPnl] =useState(true);
  const [showModal,setShowModal] =useState(false);
  const company = useSelector(state => state.selectedCompany);
  const [chartOfAccountsPnl,setChartOfAccountsPnl]=useState({});
  const [chartOfAccountsBs,setChartOfAccountsBs]=useState({});

  const [changes,trackChanges]= useState(0);
  const [resetConfirmed,setResetConfirmed]=useState(false);

  const [chartOfAccountsResponse, chartOfAccountsLoading, chartOfAccountsError,refetch] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/v2/chart-of-accounts?companyId=${company.companyId}`,
  });

  const modifyBs=(state)=> {
    const sortedBs = state.reduce((acc,curr)=> {
      for(const [,value] of Object.entries(curr[1])){
           value.sort(function(a, b) {
            return a.name.localeCompare(b.name);
        });
      }
      acc.push(curr);
      return acc;
    },[]);
    const convertedBs= sortedBs.map((el, ind) => {
      const name= el[0];
      const elem= el[1];
      const list =[];
      list.push({name:name,items:elem})
      return list;
    });
    return convertedBs;
  };

  function convertBs(state){
    const res= state.reduce((acc,curr)=>{
      const item =[];
      item.push(curr[0].name);
      item.push(curr[0].items);

      acc.push(item);
      return acc;
    },[]);
    return res;
  }

  const bsMap=(bsResponse)=> {
    const orderedBsObj = {};
    orderedBsObj['Current Assets']= {};
    orderedBsObj['Current Assets']['Cash'] = bsResponse['Current Assets']['Cash'] === undefined? []: bsResponse['Current Assets']['Cash'];
    orderedBsObj['Current Assets']['Accounts Receivable'] = bsResponse['Current Assets']['Accounts Receivable'] === undefined? []: bsResponse['Current Assets']['Accounts Receivable'];
    orderedBsObj['Current Assets']['Inventory'] = bsResponse['Current Assets']['Inventory'] === undefined? []: bsResponse['Current Assets']['Inventory'];
    orderedBsObj['Current Assets']['Other Current Assets'] = bsResponse['Current Assets']['Other Current Assets'] === undefined? []: bsResponse['Current Assets']['Other Current Assets'];

    orderedBsObj['Non-Current Assets']= {};
    orderedBsObj['Non-Current Assets']['Tangibles Assets']=bsResponse['Non-Current Assets']['Tangibles Assets'] === undefined? []: bsResponse['Non-Current Assets']['Tangibles Assets'];
    orderedBsObj['Non-Current Assets']['Intangibles Assets']=bsResponse['Non-Current Assets']['Intangibles Assets'] === undefined? []: bsResponse['Non-Current Assets']['Intangibles Assets'];
    orderedBsObj['Non-Current Assets']['Accumulated Depreciation']=bsResponse['Non-Current Assets']['Accumulated Depreciation'] === undefined? []: bsResponse['Non-Current Assets']['Accumulated Depreciation'];
    orderedBsObj['Non-Current Assets']['Investments']=bsResponse['Non-Current Assets']['Investments'] === undefined? []: bsResponse['Non-Current Assets']['Investments'];
    orderedBsObj['Non-Current Assets']['Other Non-Current Assets']=bsResponse['Non-Current Assets']['Other Non-Current Assets'] === undefined? []: bsResponse['Non-Current Assets']['Other Non-Current Assets'];

    orderedBsObj['Current Liabilities']={};
    orderedBsObj['Current Liabilities']['Accounts Payable']=bsResponse['Current Liabilities']['Accounts Payable'] === undefined? []: bsResponse['Current Liabilities']['Accounts Payable'];
    orderedBsObj['Current Liabilities']['Tax Liability']=bsResponse['Current Liabilities']['Tax Liability'] === undefined? []: bsResponse['Current Liabilities']['Tax Liability'];
    orderedBsObj['Current Liabilities']['VAT']=bsResponse['Current Liabilities']['VAT'] === undefined? []: bsResponse['Current Liabilities']['VAT'];
    orderedBsObj['Current Liabilities']['Other Current Liabilities']=bsResponse['Current Liabilities']['Other Current Liabilities'] === undefined? []: bsResponse['Current Liabilities']['Other Current Liabilities'];
    orderedBsObj['Current Liabilities']['Short Term Debt']=bsResponse['Current Liabilities']['Short Term Debt'] === undefined? []: bsResponse['Current Liabilities']['Short Term Debt'];

    orderedBsObj['Long Term Liabilities']= {};
    orderedBsObj['Long Term Liabilities']['Deferred Taxes'] =bsResponse['Long Term Liabilities']['Deferred Taxes'] === undefined? []: bsResponse['Long Term Liabilities']['Deferred Taxes'];
    orderedBsObj['Long Term Liabilities']['Other Long Term Liabilities'] =bsResponse['Long Term Liabilities']['Other Long Term Liabilities'] === undefined? []: bsResponse['Long Term Liabilities']['Other Long Term Liabilities'];
    orderedBsObj['Long Term Liabilities']['Long Term Debt'] =bsResponse['Long Term Liabilities']['Long Term Debt'] === undefined? []: bsResponse['Long Term Liabilities']['Long Term Debt'];

    orderedBsObj['Equity']= {};
    orderedBsObj['Equity']['Retained Earnings'] =bsResponse['Equity']['Retained Earnings'] === undefined? []: bsResponse['Equity']['Retained Earnings'];
    orderedBsObj['Equity']['Current Year Earnings'] =bsResponse['Equity']['Current Year Earnings'] === undefined? []: bsResponse['Equity']['Current Year Earnings'];
    orderedBsObj['Equity']['Other Equity'] =bsResponse['Equity']['Other Equity'] === undefined? []: bsResponse['Equity']['Other Equity'];
    return orderedBsObj;
  }

  const pnlMap=(pnlResponse)=> {
    const orderedPnlObj = {};

    [
      'Sales',
      'Cost of Sales',
      'SG&A',
      'Depreciation & Amortization',
      'Interest Income',
      'Interest Expense',
      'Other Income',
      'Other Expense',
      'Income Taxes',
      'Dividends',
    ].forEach(category => {
      orderedPnlObj[category] = pnlResponse[category] === undefined ? [] : pnlResponse[category];
    });

    return orderedPnlObj;
  }
  const sortPnl = (array)=>{
    const sorted= array.reduce((acc,curr)=>{
      curr[1].sort(function(a, b) {
        return a.name.localeCompare(b.name);
      });
      acc.push(curr);
      return acc;
    },[]);
    return sorted;
  }
  useEffect(()=>{
    document.querySelector('body').style.overflow='hidden';
    return(()=>{
      document.querySelector('body').style.overflow='auto';
    })
  })
  useEffect(()=>{
      if (!chartOfAccountsLoading && (chartOfAccountsResponse)) {
        setChartOfAccountsPnl({profitAndLoss: sortPnl(Object.entries(pnlMap(chartOfAccountsResponse.profitAndLoss)))});
        setChartOfAccountsBs({balanceSheet: modifyBs(Object.entries(bsMap(chartOfAccountsResponse.balanceSheet)))});
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[chartOfAccountsResponse, chartOfAccountsLoading, chartOfAccountsError, refetch]);

  const profitTabClick =()=>{
    setShowPnl(true);
    setShowBs(false);
  }
  const bsTabClick =()=>{
    setShowPnl(false);
    setShowBs(true);
  }
  const track = useCallback((value)=>{
    trackChanges((changes)=> changes+=1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[changes]);

  const resetChartOfAccount= async(companyId)=>{
    const headers = {
      'Content-Type': 'text/plain;charset=UTF-8',
    };

    let parameters = {
      companyId
    };
    try {
      const response = await axios(`${process.env.REACT_APP_BACKEND_BASE_URL}/chart-of-accounts?companyId=${companyId}`, {
        method: "delete",
        data: parameters,
        withCredentials: true,
        headers: headers
      });
      return response;
    }
    catch(error){
     return error;
    }
  }

  const applyChanges =async ()=>{
    const headers = {
      'Content-Type': 'text/plain;charset=UTF-8',
    };
  try {
    const entriesPnl = new Map(chartOfAccountsPnl.profitAndLoss);
    const objPnl = Object.fromEntries(entriesPnl);
    const entriesBs = new Map(convertBs(chartOfAccountsBs.balanceSheet));
    const objBs = Object.fromEntries(entriesBs);
    const data = {profitAndLoss:objPnl,balanceSheet:objBs};
    console.log(data.balanceSheet);
    const response = await axios(`${process.env.REACT_APP_BACKEND_BASE_URL}/v2/chart-of-accounts?companyId=${company.companyId}`, {
      method: "PUT",
      data: data,
      withCredentials: true,
      headers: headers
    });
    if (response.status === 200) {
      notify({type: 'success', message: `Chart of Accounts updated successfully!`});
      trackChanges(0);
      refetch();
    } else {
      notify({type: 'error', message: `Something went wrong. Please try again later`});
      trackChanges(0);
      refetch();
    }
  }
  catch(e){
    notify({type: 'error', message: `Something went wrong. Please try again later`});
  }
  }

  useEffect( ()=>{
    if(resetConfirmed) {
      resetChartOfAccount(company.companyId).then(
        (response)=>{
          if(response.status === 200){
            notify({type:'success',message:`Chart of Account for ${company.companyName} got reset successfully!`});
            refetch();
            trackChanges(0);
          }
          else{
            notify({type:'error',message:`Something went wrong. Please try again later`});
            refetch();
          }
          setShowModal(false);
        }
      );
    }
    setResetConfirmed(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[resetConfirmed,refetch,showModal]);

  const pnlDataChanged =(newState)=>{
    setChartOfAccountsPnl({profitAndLoss:newState});
  }
  const bsDataChanged =(newState)=>{
    setChartOfAccountsBs({balanceSheet:newState});
  }

  const reset=()=>{
    setShowModal(true);
  }
  const cancelChanges=()=>{
    refetch();
    trackChanges(0);
  }

  return (
    <div style={{display:'flex',flexDirection:'column',alignSelf:'auto',flexWrap:'nowrap',width:'100vw'}}>
      <div className={'chart-of-accounts-top-container'}>
      <SubHeader text={'Chart of Accounts'} version2/>
      <BackLink url={'/'}/>
      <p style={{textAlign:'center',fontSize:'1.1em',paddingTop:'0.2em',width:'75%',margin:'0 auto',lineHeight:'1.5'}}>Visualize your Chart of Accounts and reclassify accounts by dragging them amongst columns or to the menu on the right.</p>
      {/*<p style={{textAlign:'center',fontSize:'1.2em',fontWeight:'500'}}>You can reclassify costs and sales by dragging them amongst columns or to the menu on the right and click apply changes to update.</p>*/}
      <div className="action-buttons">
        <button className={`primary-btn ${changes === 0 ? 'disabled':''}`} disabled={changes === 0 } onClick={applyChanges}>Apply Changes {changes >0 && <span style={changeCount}>{changes}</span>}</button>
        {changes > 0 && <button style={{margin:'0 1em'}} className="reset-button" onClick={cancelChanges}>Cancel Changes</button>}
        <button style={{margin:'0 1em'}} className="reset-button" onClick={reset}>Reset</button>
      </div>
        <div className="tabs" style={{marginLeft:'1em',display:'flex',marginTop:'0.5em'}}>
          <div data-testid={'ProfitAndLossTab'} className={`tab-button ${showPnl? 'active':''}`} onClick={profitTabClick}>Profit and Loss</div>
          <div data-testid={'BalanceSheetTab'} className={`tab-button ${showBs? 'active':''}`} onClick={bsTabClick}>Balance Sheet</div>
        </div>
      </div>
        <div className="chartsOfAccountsTabs">
        {chartOfAccountsLoading && <Loader dataTestId={'chart-of-accounts-loading'} type={'pulse'} size={20}/>}
        {!chartOfAccountsLoading && chartOfAccountsError && <ErrorResponse dataTestId={`chart-of-accounts-error`} error={chartOfAccountsError}/>}
        {showPnl &&  !chartOfAccountsLoading && chartOfAccountsPnl.profitAndLoss &&
         <PnlAccounts
           track={track}
           stateChanged={pnlDataChanged}
           state={chartOfAccountsPnl.profitAndLoss}
         />}
        {showBs &&  !chartOfAccountsLoading && chartOfAccountsBs.balanceSheet &&
         <BsAccounts
           track={track}
           stateChanged={bsDataChanged}
           state={chartOfAccountsBs.balanceSheet}/>}
      </div>
      <Modal
        style={{
          content: {
          }
        }}
        className="simpleModal"
        overlayClassName="modalOverlay"
        isOpen={showModal}
        contentLabel="Add a company"
        data-testid="delete-company-modal"
      >
        <div className="modalHeader"><h2>Reset Chart of Accounts</h2>
          <button
            className="modalCloseButton"
            onClick={()=>{
              setShowModal(false);
            }}>
          </button>
        </div>
        <div className='modalBody'>
          <p style={{fontSize:'1.1em',fontWeight:'bold'}}>Please confirm that you want to reset Chart of Accounts to QoKoon default settings?</p>
          <p style={{fontSize: '1.1em',width: '90%',textAlign: 'center',margin: '0 auto',display: 'block',padding: '1em',lineHeight:'1.5'}}>You will loose any changes you have made to Chart of Accounts.</p>
          <div style={{paddingTop:'2em'}}>
            <button className='primary-btn' onClick={()=>{setResetConfirmed(true)}}>Reset</button>
            <button className='secondary-btn' style={{marginLeft:'2em'}} onClick={()=>{setShowModal(false)}}>Cancel</button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
export default withErrorBoundary(ChartOfAccounts);