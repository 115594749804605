import React, {Fragment, useEffect, useRef, useState} from "react";
import SubHeader from "../subHeader";
import CustomSelect from "../custom-select";
import './reports-new.css';
import ToggleOn from '../img/toggle-on.svg';
import ToggleOff from '../img/toggle-off.svg';
import ReportSection from "./report-section";
import {useSelector} from "react-redux";
import useGet from "../hooks/use-get";
import Loader from "../reusables/Loader";
import {
  formatCurrency,
  mapCategoryForDifferencesBarChart, mapEvolution, mapLargestComponentsTrends,
  mapLTMPrevChartData, mapPnlSummaryEvolution,
  waterfallChartMap
} from "../analysis-helper";
import infoCopies from "../chart-info-copies";

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ReactToPrint from "react-to-print";
import BsRepresentationBarChart from "../bs-representation-bar-chart";
import ProfitImprovementScenario from "./profit-improvement-scenario";
import MonthlyReportSection from "./monthly-report-section";
import YTDReportSection from "./ytd-report-section";
import TrendsReportSection from "./trends-report-section";

const ReportsNew = () => {
  const [reportType, setReportType] = useState('Individual Section View');
  const [modifySectionToggle, setModifySectionToggle] = useState(false);
  const [commentModeToggle, setCommentModeToggle] = useState(false);
  const [activeTab, setActiveTab] = useState('Monthly');
  const [overallEbitdaContriWriteup, setOverallEbitdaContriWriteup] = useState([]);
  const [changeEbitdaMonth, setChangeEbitdaMonth] = useState([]);
  const [changeEbitdaMarginMonth, setChangeEbitdaMarginMonth] = useState([]);
  const company = useSelector(state => state.selectedCompany);
  const selectedDate = useSelector(state => state.endDate);

  const [keyInsights, setKeyInsights] = useState([]);
  const getDate = (date) => `${date.month} ${date.year.slice(-2)}`;
  const [addRemoveSectionList, setAddRemoveSectionList] = useState([]);
  const reportRef = useRef(null);
  const [changeEbitdaYTD, setChangeEbitdaYTD] = useState([]);
  const [changeEbitdaMarginYTD, setChangeEbitdaMarginYTD] = useState([]);
  const [categoryDifferenceMonthlyChart, setCategoryDifferenceMonthlyChart] = useState({});
  const [categoryDifferenceYTDChart, setCategoryDifferenceYTDChart] = useState({});
  const [bsOverallWriteup, setBsOverallWriteup] = useState({});
  const [categoryCfGenerationMonthlyChart, setCategoryCfGenerationMonthlyChart] = useState({});
  const [cashflowGenerationMonthWriteup, setCashflowGenerationMonthWriteup] = useState({});
  const [categoryCfGenerationYTDChart, setCategoryCfGenerationYTDChart] = useState({});
  const [cashflowGenerationYTDWriteup, setCashflowGenerationYTDWriteup] = useState({});
  const [lTMPrevChart, setLTMPrevChart] = useState([]);
  const [monthlyEvolutionChart, setMonthlyEvolutionChart] = useState([]);
  const [ltmEvolutionChart, setLtmEvolutionChart] = useState([]);
  const [overallEbitdaEvolutionMonth, setOverallEbitdaEvolutionMonth] = useState([]);
  const [overallEbitdaEvolutionLTM, setOverallEbitdaEvolutionLTM] = useState([]);
  const [netIncomeEvolutionMonth, setNetIncomeEvolutionMonth] = useState({});
  const [historicTrendComponentsChart, setHistoricTrendComponentsChart] = useState([]);
  const [historicTrendLiabilitiesComponentsChart, setHistoricTrendLiabilitiesComponentsChart] = useState([]);
  const [historicTrendEquityComponentsChart, setHistoricTrendEquityComponentsChart] = useState([]);


  const _exportPdf = (name) => {
    html2canvas(document.querySelector("#new-reports-container")).then(canvas => {

      const imgData = canvas.toDataURL("image/png");
      // const pdf = new jsPDF("p", "mm", "a4");
      // const imgProps= pdf.getImageProperties(imgData);
      // const pdfWidth = pdf.internal.pageSize.getWidth();
      // const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      // // const pdfWidth = pdf.internal.pageSize.getWidth();
      // // const pdfHeight = pdf.internal.pageSize.getHeight();
      // pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      // pdf.save("mypdf.pdf");

      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;

      const pdf = new jsPDF('p', 'mm');
      let position = 0;

      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save( `${name}.pdf`);
    });
  }

  const [keyInsightsResponse, keyInsightsLoading, keyInsightsError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/insights/snapshots?companyId=${company.companyId}` +
         `&date=${getDate(selectedDate)}`
  });

  useEffect(() => {
    if (!keyInsightsLoading && (keyInsightsResponse && Object.keys(keyInsightsResponse).length > 0)) {
      setKeyInsights(keyInsightsResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyInsightsResponse, keyInsightsLoading, keyInsightsError]);

  const [overallEbitdaContriWriteupResponse, overallEbitdaContriWriteupLoading, overallEbitdaContriWriteupError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/writeup/overall/pnl?companyId=${company.companyId}` +
         `&date=${getDate(selectedDate)}`
  });

  const [changeEbitdaYTDResponse, changeEbitdaYTDLoading, changeEbitdaYTDError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/change/ebitda?companyId=${company.companyId}` +
         `&category=SG%26A&date=${getDate(selectedDate)}&breakdown=YTD`
  });

  useEffect(() => {
    if (!changeEbitdaYTDLoading && changeEbitdaYTDResponse) {
      setChangeEbitdaYTD(waterfallChartMap(changeEbitdaYTDResponse));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeEbitdaYTDResponse, changeEbitdaYTDLoading, changeEbitdaYTDError])

  useEffect(() => {
    if (!overallEbitdaContriWriteupLoading && overallEbitdaContriWriteupResponse) {
      setOverallEbitdaContriWriteup(overallEbitdaContriWriteupResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overallEbitdaContriWriteupResponse,
    overallEbitdaContriWriteupLoading,
    overallEbitdaContriWriteupError,
    company
  ]);

  const [changeEbitdaMonthResponse, changeEbitdaMonthLoading, changeEbitdaMonthError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/change/ebitda?companyId=${company.companyId}` +
         `&category=SG%26A&date=${getDate(selectedDate)}&breakdown=MONTH`
  });

  useEffect(() => {
    if (!changeEbitdaMonthLoading && changeEbitdaMonthResponse) {
      setChangeEbitdaMonth(waterfallChartMap(changeEbitdaMonthResponse));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeEbitdaMonthResponse, changeEbitdaMonthLoading, changeEbitdaMonthError]);

  const [changeEbitdaMarginMonthResponse, changeEbitdaMarginMonthLoading, changeEbitdaMarginMonthError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/change/ebitda-margin?companyId=${company.companyId}` +
         `&category=SG%26A&date=${getDate(selectedDate)}&breakdown=MONTH`
  });

  useEffect(() => {
    if (!changeEbitdaMarginMonthLoading && (changeEbitdaMarginMonthResponse && Object.keys(changeEbitdaMarginMonthResponse).length > 0)) {
      setChangeEbitdaMarginMonth(waterfallChartMap(changeEbitdaMarginMonthResponse));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    changeEbitdaMarginMonthResponse,
    changeEbitdaMarginMonthLoading,
    changeEbitdaMarginMonthError,
  ]);

  const [changeEbitdaMarginYTDResponse, changeEbitdaMarginYTDLoading, changeEbitdaMarginYTDError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/change/ebitda-margin?companyId=${company.companyId}` +
         `&category=SG%26A&date=${getDate(selectedDate)}&breakdown=YTD`
  });

  useEffect(() => {
    if (!changeEbitdaMarginYTDLoading && (changeEbitdaMarginYTDResponse && Object.keys(changeEbitdaMarginYTDResponse).length > 0)) {
      setChangeEbitdaMarginYTD(waterfallChartMap(changeEbitdaMarginYTDResponse));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeEbitdaMarginYTDResponse, changeEbitdaMarginYTDLoading, changeEbitdaMarginYTDError])

  const [categoryDifferenceMonthlyResponse, categoryDifferenceMonthlyLoading, categoryDifferenceMonthlyError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/differences/pnl?companyId=${company.companyId}` +
         `&category=${'SG&A'.replace(/&/ig, '%26')}&date=${getDate(selectedDate)}&breakdown=MONTH`
  });

  useEffect(() => {
    if (!categoryDifferenceMonthlyLoading && (categoryDifferenceMonthlyResponse && Object.keys(categoryDifferenceMonthlyResponse).length > 0)) {
      setCategoryDifferenceMonthlyChart(mapCategoryForDifferencesBarChart(categoryDifferenceMonthlyResponse));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryDifferenceMonthlyLoading, categoryDifferenceMonthlyResponse, categoryDifferenceMonthlyError]);

  const [categoryDifferenceYTDResponse, categoryDifferenceYTDLoading, categoryDifferenceYTDError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/differences/pnl?companyId=${company.companyId}` +
         `&category=${'SG&A'.replace(/&/ig, '%26')}&date=${getDate(selectedDate)}&breakdown=YTD`
  });

  useEffect(() => {
    if (!categoryDifferenceYTDLoading && (categoryDifferenceYTDResponse && Object.keys(categoryDifferenceYTDResponse).length > 0)) {
      setCategoryDifferenceYTDChart(mapCategoryForDifferencesBarChart(categoryDifferenceYTDResponse));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryDifferenceYTDLoading, categoryDifferenceYTDResponse, categoryDifferenceYTDError]);

  const [bsOverallWriteupResponse, bsOverallWriteupLoading, bsOverallWriteupError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/writeup/overall/bs?companyId=${company.companyId}` +
         `&date=${getDate(selectedDate)}`
  });

  useEffect(() => {
    if (!bsOverallWriteupLoading && (Object.keys(bsOverallWriteupResponse).length > 0)) {
      setBsOverallWriteup(bsOverallWriteupResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bsOverallWriteupLoading, bsOverallWriteupResponse, bsOverallWriteupError]);

  const [categoryCfGenerationMonthlyResponse, categoryCfGenerationMonthlyLoading, categoryCfGenerationMonthlyError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/cfGeneration?companyId=${company.companyId}` +
         `&category=${'CF Summary'?.replace(/&/ig, '%26')}` +
         `&date=${getDate(selectedDate)}` +
         `&breakdown=MONTH`
  });

  useEffect(() => {
    if (!categoryCfGenerationMonthlyLoading && (Object.keys(categoryCfGenerationMonthlyResponse).length > 0)) {
      setCategoryCfGenerationMonthlyChart(waterfallChartMap(categoryCfGenerationMonthlyResponse));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryCfGenerationMonthlyLoading, categoryCfGenerationMonthlyResponse, categoryCfGenerationMonthlyError]);

  const [cashflowGenerationMonthWriteupResponse, cashflowGenerationMonthWriteupLoading, cashflowGenerationMonthWriteupError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/cfGeneration/writeup?` +
         `companyId=${company.companyId}` +
         `&date=${getDate(selectedDate)}` +
         `&breakdown=MONTH`
  });

  useEffect(() => {
    if (!cashflowGenerationMonthWriteupLoading && (cashflowGenerationMonthWriteupResponse)) {
      setCashflowGenerationMonthWriteup(cashflowGenerationMonthWriteupResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cashflowGenerationMonthWriteupResponse,
    cashflowGenerationMonthWriteupLoading,
    cashflowGenerationMonthWriteupError,
  ]);

  const [categoryCfGenerationYTDResponse, categoryCfGenerationYTDLoading, categoryCfGenerationYTDError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/cfGeneration?companyId=${company.companyId}` +
         `&category=${'CF Summary'.replace(/&/ig, '%26')}` +
         `&date=${getDate(selectedDate)}` +
         `&breakdown=YTD`
  });

  useEffect(() => {
    if (!categoryCfGenerationYTDLoading && (Object.keys(categoryCfGenerationYTDResponse).length > 0)) {
      setCategoryCfGenerationYTDChart(waterfallChartMap(categoryCfGenerationYTDResponse));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryCfGenerationYTDLoading, categoryCfGenerationYTDResponse, categoryCfGenerationYTDError]);

  const [cashflowGenerationYTDWriteupResponse, cashflowGenerationYTDWriteupLoading, cashflowGenerationYTDWriteupError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/cfGeneration/writeup?` +
         `companyId=${company.companyId}` +
         `&date=${getDate(selectedDate)}` +
         `&breakdown=YTD`
  });

  useEffect(() => {
    if (!cashflowGenerationYTDWriteupLoading && (cashflowGenerationYTDWriteupResponse)) {
      setCashflowGenerationYTDWriteup(cashflowGenerationYTDWriteupResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cashflowGenerationYTDWriteupResponse,
    cashflowGenerationYTDWriteupLoading,
    cashflowGenerationYTDWriteupError,]);

  const [lTMPrevChartResponse, lTMPrevChartLoading, lTMPrevChartError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/ltm-vs-previous/category?companyId=${company.companyId}&category=Sales&date=${getDate(selectedDate)}&sheet=PNL`
  });

  useEffect(() => {
    if (!lTMPrevChartLoading && (lTMPrevChartResponse && Object.keys(lTMPrevChartResponse).length > 0)) {
      setLTMPrevChart(mapLTMPrevChartData(lTMPrevChartResponse));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lTMPrevChartResponse, lTMPrevChartLoading, lTMPrevChartError])


  const [monthlyEvolutionChartResponse, monthlyEvolutionChartLoading, monthlyEvolutionChartError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/monthly-evolution/category/pnl?companyId=${company.companyId}&category=${'Cost of Sales'.replace(/&/ig, '%26')}&date=${getDate(selectedDate)}`,
  });

  useEffect(() => {
    if (!monthlyEvolutionChartLoading && (monthlyEvolutionChartResponse && Object.keys(monthlyEvolutionChartResponse).length > 0)) {
      setMonthlyEvolutionChart(mapEvolution(monthlyEvolutionChartResponse, null, 'Cost of Sales', 'pnl'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthlyEvolutionChartLoading, monthlyEvolutionChartResponse, monthlyEvolutionChartError])

  const [ltmEvolutionChartResponse, ltmEvolutionChartLoading, ltmEvolutionChartError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/ltm-evolution/category/pnl?companyId=${company.companyId}&category=${'Cost of Sales'.replace(/&/ig, '%26')}&date=${getDate(selectedDate)}`,
  });

  useEffect(() => {
      if (!ltmEvolutionChartLoading && (ltmEvolutionChartResponse && Object.keys(ltmEvolutionChartResponse).length > 0)) {
        setLtmEvolutionChart(mapEvolution(ltmEvolutionChartResponse, null, 'Cost of Sales', 'pnl'));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ltmEvolutionChartLoading, ltmEvolutionChartResponse, ltmEvolutionChartError]);

  const [overallEbitdaEvolutionMonthResponse, overallEbitdaEvolutionMonthLoading, overallEbitdaEvolutionMonthError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/monthly-evolution/derived-category/pnl?companyId=${company.companyId}` +
         `&category=EBITDA` +
         `&date=${getDate(selectedDate)}`
  });

  useEffect(() => {
    if (!overallEbitdaEvolutionMonthLoading && overallEbitdaEvolutionMonthResponse) {
      setOverallEbitdaEvolutionMonth(mapPnlSummaryEvolution(overallEbitdaEvolutionMonthResponse, 'EBITDA'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    overallEbitdaEvolutionMonthResponse,
    overallEbitdaEvolutionMonthLoading,
    overallEbitdaEvolutionMonthError,
  ]);

  const [overallEbitdaEvolutionLTMResponse, overallEbitdaEvolutionLTMLoading, overallEbitdaEvolutionLTMError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/ltm-evolution/derived-category/pnl?companyId=${company.companyId}` +
         `&category=EBITDA` +
         `&date=${getDate(selectedDate)}`
  });

  useEffect(() => {
    if (!overallEbitdaEvolutionLTMLoading && overallEbitdaEvolutionLTMResponse) {
      setOverallEbitdaEvolutionLTM(mapPnlSummaryEvolution(overallEbitdaEvolutionLTMResponse, 'EBITDA'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    overallEbitdaEvolutionLTMResponse,
    overallEbitdaEvolutionLTMLoading,
    overallEbitdaEvolutionLTMError,
  ])

  const [netIncomeEvolutionMonthResponse, netIncomeEvolutionMonthLoading, netIncomeEvolutionMonthError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/monthly-evolution/derived-category/pnl?companyId=${company.companyId}` +
         `&category=Net%20Income&date=${getDate(selectedDate)}`
  });

  useEffect(() => {
    if (!netIncomeEvolutionMonthLoading && (netIncomeEvolutionMonthResponse && Object.keys(netIncomeEvolutionMonthResponse).length > 0)) {
      setNetIncomeEvolutionMonth(mapPnlSummaryEvolution(netIncomeEvolutionMonthResponse, 'Net Income'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    netIncomeEvolutionMonthResponse,
    netIncomeEvolutionMonthLoading,
    netIncomeEvolutionMonthError,
  ])

  const [historicTrendComponentsChartResponse, historicTrendComponentsChartLoading, historicTrendComponentsChartError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/historic-data/category?` +
         `companyId=${company.companyId}&category=Current Assets&date=${getDate(selectedDate)}&breakdown=MONTH&sheet=BS`,
  });

  useEffect(() => {
    if (!historicTrendComponentsChartLoading && (Object.keys(historicTrendComponentsChartResponse).length > 0)) {
      setHistoricTrendComponentsChart(mapLargestComponentsTrends(historicTrendComponentsChartResponse, 'Current Assets'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    historicTrendComponentsChartLoading,
    historicTrendComponentsChartResponse,
    historicTrendComponentsChartError,
  ])

  const [historicTrendLiabilitiesComponentsChartResponse, historicTrendLiabilitiesComponentsChartLoading, historicTrendLiabilitiesComponentsChartError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/historic-data/category?` +
         `companyId=${company.companyId}&category=Current Liabilities&date=${getDate(selectedDate)}&breakdown=MONTH&sheet=BS`,
  });

  useEffect(() => {
    if (!historicTrendLiabilitiesComponentsChartLoading && (Object.keys(historicTrendLiabilitiesComponentsChartResponse).length > 0)) {
      setHistoricTrendLiabilitiesComponentsChart(mapLargestComponentsTrends(historicTrendLiabilitiesComponentsChartResponse, 'Current Liabilities'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    historicTrendLiabilitiesComponentsChartLoading,
    historicTrendLiabilitiesComponentsChartResponse,
    historicTrendLiabilitiesComponentsChartError,
  ])

  const [historicTrendEquityComponentsChartResponse, historicTrendEquityComponentsChartLoading, historicTrendEquityComponentsChartError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/historic-data/category?` +
         `companyId=${company.companyId}&category=Equity&date=${getDate(selectedDate)}&breakdown=MONTH&sheet=BS`,
  });

  useEffect(() => {
    if (!historicTrendEquityComponentsChartLoading && (Object.keys(historicTrendEquityComponentsChartResponse).length > 0)) {
      setHistoricTrendEquityComponentsChart(mapLargestComponentsTrends(historicTrendEquityComponentsChartResponse, 'Equity'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    historicTrendEquityComponentsChartLoading,
    historicTrendEquityComponentsChartResponse,
    historicTrendEquityComponentsChartError,
  ])

  const showBsCatagoryWriteUp = (obj) => {
    const response = [];
    for (const [key, value] of Object.entries(obj)) {
      response.push(<h2
        style={{fontWeight: '600', fontSize: '1em', color: '#305A80', padding: '1em 0 0 0', margin: '0'}}>{key}</h2>);
      response.push(value.map((entry, index) => {
        return (<p key={index} style={{fontSize: '1em', margin: '0.6em 0'}}>- {entry}</p>)
      }));
    }
    return response;
  }
  const sentenceWithColor = (value) => {
    if (Math.floor(value) > 0)
      return (<span style={{color: 'green', fontWeight: '500'}}>&nbsp;{formatCurrency(value, company.currency)}</span>)
    else if (Math.ceil(value) < 0)
      return (
        <span style={{color: 'red', fontWeight: '500'}}>&nbsp;{formatCurrency(value, company.currency)}</span>)
    else
      return (<span style={{fontWeight: '500'}}>&nbsp;{formatCurrency(value, company.currency)}</span>)
  }

  const showWriteup = (obj) => {
    if (Object.keys(obj).length) {
      return obj.map((entry, index) => {
        const titles = [];
        const content = [];
        const subPoints = [];
        //const {title:{text,postText}}= entry;
        titles.push(<h2 className="writeup-heading" style={{margin: '2em 0 0.5em 0'}}>{entry.title.text}&nbsp;
          {sentenceWithColor(entry.title.value)}
          &nbsp; {entry.title.postText}
        </h2>);
        content.push(
          <ul style={{padding: '0'}} key={index}>
            {entry.points.map((point, index) => (
              <li className="writeup-bullets" style={{fontWeight: '500'}} key={index}>{point.text}&nbsp;
                {sentenceWithColor(point.value)}
                &nbsp; {point.postText}
              </li>))
            }
          </ul>)
        subPoints.push(
          <ul style={{padding: '0 0 0 1em', margin: '0', marginTop: '-0.5em'}} key={index}>
            {entry.subPoints.map((subPoint, index) => (<li className="writeup-bullets" key={index}>{subPoint.text}&nbsp;
              {sentenceWithColor(subPoint.value)}&nbsp; {subPoint.postText}
            </li>))}
          </ul>
        )
        return (<div key={index}>{titles}{content}{subPoints}</div>)
      })
    }
  }

  const modifyToggleClick = () => {
    if (modifySectionToggle === true) {
      setModifySectionToggle(false);
    } else {
      setModifySectionToggle(true);
    }
  }

  const commentToggleClick = () => {
    if (commentModeToggle === true) {
      setCommentModeToggle(false);
    } else {
      setCommentModeToggle(true);
    }
  }

  const selectSectionWiseTabItem = (item) => {
    setActiveTab(item);
  }

  function addRemoveSection(sectionName, action) {
    if (action === 'add') {
      const list = addRemoveSectionList;
      list.push(sectionName);
      setAddRemoveSectionList(list);
    } else if (action === 'remove') {
      const list = addRemoveSectionList.filter((item) => item !== sectionName);
      setAddRemoveSectionList(list);
    }
  }

  return <div data-testid='new-reports-page'>
    <SubHeader text={'Prepare Reports for'}/>
    <div className={`new-reports-fixed-section`}>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <CustomSelect
          dataTestId={'reports-type-select'}
          selectedValue={reportType}
          options={[{label: 'Individual Section View', selectable: true, disconnected: false}, {
            label: 'Full Report View',
            selectable: true,
            disconnected: false
          }]}
          className={`companySwitcher`}
          callback={(text) => setReportType(text)}/>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flex: '0 0 20%'}}>
          <img data-testid={`${modifySectionToggle ? 'modifySectionToggleOn' : 'modifySectionToggleOff'}`}
               src={modifySectionToggle ? ToggleOn : ToggleOff}
               alt="toggle-on-off"
               onClick={modifyToggleClick}
               className="toggle-image"/>
          <span style={{padding: '0 1em 3px 1em'}}>Show/Hide Sections</span>
        </div>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flex: '0 0 20%'}}>
          <img data-testid={`${commentModeToggle ? 'commentModeToggleOn' : 'commentModeToggleOff'}`}
               src={commentModeToggle ? ToggleOn : ToggleOff}
               alt="toggle-on-off"
               onClick={commentToggleClick}
               className="toggle-image"/>
          <span style={{padding: '0 1em 3px 1em'}}>Add Your Comments</span>
        </div>
      </div>
      {reportType === 'Individual Section View' &&
       <div data-testid='section-wise-report-tabs' style={{display: 'inline-block'}}>
         <ul className='section-wise-report-tabs'>
           <li
             className={`section-wise-tabs-item ${activeTab === 'Monthly' ? 'active' : ''}`}
             onClick={() => selectSectionWiseTabItem('Monthly')}>Monthly
           </li>
           <li
             className={`section-wise-tabs-item ${activeTab === 'Year to Date' ? 'active' : ''}`}
             onClick={() => selectSectionWiseTabItem('Year to Date')}>Year to Date
           </li>
           <li
             className={`section-wise-tabs-item ${activeTab === 'Balance Sheet' ? 'active' : ''}`}
             onClick={() => selectSectionWiseTabItem('Balance Sheet')}>Balance Sheet
           </li>
           <li
             className={`section-wise-tabs-item ${activeTab === 'Profit Improvements' ? 'active' : ''}`}
             onClick={() => selectSectionWiseTabItem('Profit Improvements')}>Profit Improvements
           </li>
           <li
             className={`section-wise-tabs-item ${activeTab === 'Trends' ? 'active' : ''}`}
             onClick={() => selectSectionWiseTabItem('Trends')}>Trends
           </li>
         </ul>
       </div>}
      <div style={{float: 'right', padding: '2em 2em 0 2em'}}>
        <button className="primary-btn small" style={{marginRight: '1em'}} onClick={()=>_exportPdf(company.companyName)}>Save as PDF</button>
        <ReactToPrint
          trigger={() => <button className="primary-btn small">Print Preview</button>}
          content={() => reportRef.current}
        />
      </div>
    </div>

    <div className='new-reports-container' id="new-reports-container" ref={reportRef}>
      <div className="report-style-wrapper">
        <div className={"report-header"}>
          <p className="report-heading">{company.companyName}</p>
          <span className="report-duration">(As of {selectedDate.month} {selectedDate.year})</span>
        </div>
        {/*monthly report*/}
        {(activeTab === 'Monthly' || reportType !== 'Individual Section View') &&
         <MonthlyReportSection
           reportType={reportType}
           modifySectionToggle={modifySectionToggle}
           addRemoveSectionList={addRemoveSectionList}
           commentModeToggle={commentModeToggle}
           addRemoveSection={addRemoveSection}
           keyInsightsLoading={keyInsightsLoading}
           keyInsightsError={keyInsightsError}
           keyInsights={keyInsights}
           company={company}
           overallEbitdaContriWriteupLoading={overallEbitdaContriWriteupLoading}
           overallEbitdaContriWriteupError={overallEbitdaContriWriteupError}
           overallEbitdaContriWriteup={overallEbitdaContriWriteup}
           changeEbitdaMonthLoading={changeEbitdaMonthLoading}
           changeEbitdaMonthError={changeEbitdaMonthError}
           changeEbitdaMonth={changeEbitdaMonth}
           changeEbitdaMarginMonthLoading={changeEbitdaMarginMonthLoading}
           changeEbitdaMarginMonthError={changeEbitdaMarginMonthError}
           changeEbitdaMarginMonth={changeEbitdaMarginMonth}
           categoryDifferenceMonthlyLoading={categoryDifferenceMonthlyLoading}
           categoryDifferenceMonthlyError={categoryDifferenceMonthlyError}
           categoryDifferenceMonthlyChart={categoryDifferenceMonthlyChart}
           selectedDate={selectedDate}
           bsOverallWriteupLoading={bsOverallWriteupLoading}
           bsOverallWriteupError={bsOverallWriteupError}
           bsOverallWriteup={bsOverallWriteup}
           showBsCatagoryWriteUp={showBsCatagoryWriteUp}
           categoryCfGenerationMonthlyLoading={categoryCfGenerationMonthlyLoading}
           categoryCfGenerationMonthlyError={categoryCfGenerationMonthlyError}
           categoryCfGenerationMonthlyChart={categoryCfGenerationMonthlyChart}
           cashflowGenerationMonthWriteupLoading={cashflowGenerationMonthWriteupLoading}
           cashflowGenerationMonthWriteupError={cashflowGenerationMonthWriteupError}
           showWriteup={showWriteup}
           cashflowGenerationMonthWriteup={cashflowGenerationMonthWriteup}
         />}

        {(activeTab === 'Year to Date' || reportType !== 'Individual Section View') &&
         <YTDReportSection
           reportType={reportType}
           modifySectionToggle={modifySectionToggle}
           addRemoveSectionList={addRemoveSectionList}
           commentModeToggle={commentModeToggle}
           addRemoveSection={addRemoveSection}
           keyInsightsLoading={keyInsightsLoading}
           keyInsightsError={keyInsightsError}
           keyInsights={keyInsights}
           company={company}
           overallEbitdaContriWriteupLoading={overallEbitdaContriWriteupLoading}
           overallEbitdaContriWriteupError={overallEbitdaContriWriteupError}
           activeTab={activeTab}
           overallEbitdaContriWriteup={overallEbitdaContriWriteup}
           changeEbitdaYTDLoading={changeEbitdaYTDLoading}
           changeEbitdaYTDError={changeEbitdaYTDError}
           changeEbitdaYTD={changeEbitdaYTD}
           changeEbitdaMarginYTDLoading={changeEbitdaMarginYTDLoading}
           changeEbitdaMarginYTDError={changeEbitdaMarginYTDError}
           changeEbitdaMarginYTD={changeEbitdaMarginYTD}
           categoryDifferenceYTDLoading={categoryDifferenceYTDLoading}
           categoryDifferenceYTDError={categoryDifferenceYTDError}
           categoryDifferenceYTDChart={categoryDifferenceYTDChart}
           selectedDate={selectedDate}
           bsOverallWriteupLoading={bsOverallWriteupLoading}
           bsOverallWriteupError={bsOverallWriteupError}
           bsOverallWriteup={bsOverallWriteup}
           showBsCatagoryWriteUp={showBsCatagoryWriteUp}
           categoryCfGenerationYTDLoading={categoryCfGenerationYTDLoading}
           categoryCfGenerationYTDError={categoryCfGenerationYTDError}
           categoryCfGenerationYTDChart={categoryCfGenerationYTDChart}
           cashflowGenerationYTDWriteupLoading={cashflowGenerationYTDWriteupLoading}
           cashflowGenerationYTDWriteupError={cashflowGenerationYTDWriteupError}
           showWriteup={showWriteup}
           cashflowGenerationYTDWriteup={cashflowGenerationYTDWriteup}
         />}
        {(activeTab === 'Balance Sheet') && <Fragment>
          <ReportSection
            showControls={modifySectionToggle}
            name={'Balance Sheet Commentary'}
            addRemoveSectionList={addRemoveSectionList}
            toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
            commentMode={commentModeToggle}
          >
            {<p className={`bs-representation-title`}>Balance Sheet Representation as of&nbsp;
              {selectedDate.month} {selectedDate.year}</p>}
            {
              <div className={`line-charts-wrapper`}>
                <BsRepresentationBarChart
                  dataTestId={`bs-overall-writeup-chart`}
                  dataValue={null}
                  formatter={(value) => formatCurrency(value, company.currency)}
                  loading={bsOverallWriteupLoading}
                  error={bsOverallWriteupError}
                  data={bsOverallWriteup.Representation ? bsOverallWriteup.Representation : null}
                  chartInfoCopy={infoCopies.balanceSheetRepresentation}
                />
                <div style={{padding: '0 2em', flex: '0 0 50%', marginTop: '-3.5em'}}>
                  {bsOverallWriteupLoading &&
                   <div style={{margin: '1em auto 2em auto'}}><Loader dataTestId={`bs-overall-writeup-loading`}
                                                                      type={'pulse'} size={10}/></div>}
                  {!bsOverallWriteupLoading && Object.keys(bsOverallWriteup).length > 0 && showBsCatagoryWriteUp(bsOverallWriteup.WriteUp)}
                </div>
              </div>}
          </ReportSection>
        </Fragment>
        }

        {(activeTab === 'Profit Improvements' || reportType !== 'Individual Section View') &&
           <ProfitImprovementScenario
             modifySectionToggle={modifySectionToggle}
             addRemoveSectionList={addRemoveSectionList}
             addRemoveSection={addRemoveSection}
             commentModeToggle={commentModeToggle}
             company={company}
           />
        }
        {(activeTab === 'Trends' || reportType !== 'Individual Section View') &&
         <TrendsReportSection
           modifySectionToggle={modifySectionToggle}
           addRemoveSectionList={addRemoveSectionList}
           addRemoveSection={addRemoveSection}
           commentModeToggle={commentModeToggle}
           company={company}
           lTMPrevChartLoading={lTMPrevChartLoading}
           lTMPrevChartError={lTMPrevChartError}
           lTMPrevChart={lTMPrevChart}
           monthlyEvolutionChartLoading={monthlyEvolutionChartLoading}
           monthlyEvolutionChartError={monthlyEvolutionChartError}
           monthlyEvolutionChart={monthlyEvolutionChart}
           ltmEvolutionChartLoading={ltmEvolutionChartLoading}
           ltmEvolutionChartError={ltmEvolutionChartError}
           ltmEvolutionChart={ltmEvolutionChart}
           overallEbitdaEvolutionMonthLoading={overallEbitdaEvolutionMonthLoading}
           overallEbitdaEvolutionMonthError={overallEbitdaEvolutionMonthError}
           overallEbitdaEvolutionMonth={overallEbitdaEvolutionMonth}
           overallEbitdaEvolutionLTMLoading={overallEbitdaEvolutionLTMLoading}
           overallEbitdaEvolutionLTMError={overallEbitdaEvolutionLTMError}
           overallEbitdaEvolutionLTM={overallEbitdaEvolutionLTM}
           netIncomeEvolutionMonthLoading={netIncomeEvolutionMonthLoading}
           netIncomeEvolutionMonthError={netIncomeEvolutionMonthError}
           netIncomeEvolutionMonth={netIncomeEvolutionMonth}
           historicTrendComponentsChartLoading={historicTrendComponentsChartLoading}
           historicTrendComponentsChartError={historicTrendComponentsChartError}
           historicTrendComponentsChart={historicTrendComponentsChart}
           historicTrendLiabilitiesComponentsChartLoading={historicTrendLiabilitiesComponentsChartLoading}
           historicTrendLiabilitiesComponentsChartError={historicTrendLiabilitiesComponentsChartError}
           historicTrendLiabilitiesComponentsChart={historicTrendLiabilitiesComponentsChart}
           historicTrendEquityComponentsChartLoading={historicTrendEquityComponentsChartLoading}
           historicTrendEquityComponentsChartError={historicTrendEquityComponentsChartError}
           historicTrendEquityComponentsChart={historicTrendEquityComponentsChart}
         />
        }
      </div>
    </div>
  </div>
}
export default ReportsNew;
