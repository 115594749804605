import React, {Fragment} from "react";
import Loader from "../reusables/Loader";
import {formatCurrency} from "../analysis-helper";
import ErrorResponse from "../error-response/error-response";
import arrowUp from "../img/arrow-positive-up.svg";
import arrowDown from "../img/arrow-negative-down.svg";
import arrowNeutral from "../img/arrow-right-neutral.svg";
import thumbsUp from "../img/thumbs-up.svg";
import thumbsDown from "../img/thumbs-down.svg";
import thumbsNeutral from "../img/thumbs-neutral.svg";

const ReportsKeyInsights =({isLoading,isError,keyInsights,company})=>{

  const showArrow=(value)=>{
    if(value > 0) {
      return arrowUp;
    }
    else if(value < 0){
      return arrowDown;
    }
    else{
      return arrowNeutral;
    }
  }
  const showArrowClass=(value)=>{
    if(value > 0) {
      return 'arrow-positive-up';
    }
    else if(value < 0){
      return 'arrow-negative-down';
    }
    else{
      return 'arrow-neutral';
    }
  }

  const showThumb =(value)=>{
    if(value > 0) {
      return thumbsUp;
    }
    else if(value < 0){
      return thumbsDown;
    }
    else{
      return thumbsNeutral;
    }
  }

  return(
    <Fragment>
      {isLoading && <Loader dataTestId={`reports-key-insights-loading`} type={'pulse'} size={10}/>}
      { !isLoading && !isError &&
        <div className='key-insights-container' style={{display:'flex',flexWrap:'wrap'}}>
          <div className='key-insight'>
            <label>Month EBITDA</label>
            <span>{keyInsights.ebitda && <span style={{color:keyInsights.ebitda.month.value >= 0? '#090909':'red',padding:'0'}}>
             {formatCurrency(keyInsights.ebitda.month.value,company.currency)}</span>}
           </span>
            <div style={{paddingTop:'0.5em'}}>
              {keyInsights.ebitda && <img className={showArrowClass(keyInsights.ebitda.month.difference)}
                                          src={showArrow(keyInsights.ebitda.month.difference)}
                                          alt={keyInsights.ebitda.month.difference >= 0? 'Up':'Down'}/>}
              <span>{keyInsights.ebitda &&
                     <span style={{color:keyInsights.ebitda.month.difference >= 0? '#090909':'red',padding:'0'}}>
                      {formatCurrency(keyInsights.ebitda.month.difference,company.currency)}
                       &nbsp;<span>({parseFloat(keyInsights.ebitda.month.percentage).toFixed(1)}%)</span></span>
              }
            </span>
            </div>
          </div>
          <div className='key-insight'>
            <label>Month Free Cashflow</label>
            <span style={{
              display:'block',
              padding:'0',
              marginTop:'0.5em'}}>
            {keyInsights.fcf && <img className='thumbs-positive-negative'
                                     src={showThumb(keyInsights.fcf.month)}
                                     alt={keyInsights.fcf.month >= 0? 'Up':'Down'}/>}
              {keyInsights.fcf &&
               <span style={{color:keyInsights.fcf.month >= 0? '#090909':'red',padding:'0'}}>
               {formatCurrency(keyInsights.fcf.month,company.currency)}
            </span>}
          </span>
          </div>
          <div className='key-insight'>
            <label>Month Cashflow Generation</label>
            <span style={{
              display:'block',
              padding:'0',
              marginTop:'0.5em'}}>
            {keyInsights.cfGeneration && <img className='thumbs-positive-negative'
                                              src={showThumb(keyInsights.cfGeneration.month)}
                                              alt={keyInsights.cfGeneration.month >= 0? 'Up':'Down'}/>}
              {keyInsights.cfGeneration &&
               <span style={{color:keyInsights.cfGeneration.month >= 0? '#090909':'red',padding:'0'}}>
               {formatCurrency(keyInsights.cfGeneration.month,company.currency)}
            </span>}
          </span>
          </div>
          <div className='key-insight'>
            <label>Cash Balance</label>
            <span style={{
              display:'block',
              padding:'0',
              marginTop:'0.5em'}}>
            {keyInsights.balance &&
             <span style={{color:keyInsights.balance.cash > 0? '#090909':'red',padding:'0'}}>
               {formatCurrency(keyInsights.balance.cash,company.currency)}
             </span>}
          </span>
          </div>
          <div className='key-insight'>
            <label>YTD EBITDA</label>
            <span>{keyInsights.ebitda &&
                   <span style={{color:keyInsights.ebitda.ytd.value >= 0? '#090909':'red',padding:'0'}}>
                   {formatCurrency(keyInsights.ebitda.ytd.value,company.currency)}
                 </span>}
          </span>
            <div style={{paddingTop:'0.5em'}}>
              {keyInsights.ebitda &&
               <img className={showArrowClass(keyInsights.ebitda.ytd.difference)}
                    src={showArrow(keyInsights.ebitda.ytd.difference)}
                    alt={keyInsights.ebitda.ytd.difference>=0? 'Up':'Down'}/>}
              <span>{keyInsights.ebitda &&
                     <span style={{color:keyInsights.ebitda.ytd.difference >= 0? '#090909':'red',padding:'0'}}>
                {formatCurrency(keyInsights.ebitda.ytd.difference,company.currency)}
                       &nbsp;({parseFloat(keyInsights.ebitda.ytd.percentage).toFixed(1)}%)</span>}
            </span>
            </div>
          </div>
          <div className='key-insight'>
            <label>YTD Free Cashflow</label>
            <span style={{
              display:'block',
              padding:'0',
              marginTop:'0.5em'}}>
             {keyInsights.fcf && <img className='thumbs-positive-negative'
                                      src={showThumb(keyInsights.fcf.ytd)}
                                      alt={keyInsights.fcf.ytd >= 0? 'Up':'Down'}/>}
              {keyInsights.fcf &&
               <span style={{color:keyInsights.fcf.ytd >= 0? '#090909':'red',padding:'0'}}>
               {formatCurrency(keyInsights.fcf.ytd,company.currency)}
             </span>
              }</span>
          </div>
          <div className='key-insight'>
            <label>YTD Cashflow Generation</label>
            <span style={{
              display:'block',
              padding:'0',
              marginTop:'0.5em'}}>
            {keyInsights.cfGeneration && <img className='thumbs-positive-negative'
                                              src={showThumb(keyInsights.cfGeneration.ytd)}
                                              alt={keyInsights.cfGeneration.ytd >= 0? 'Up':'Down'}/>}
              {keyInsights.cfGeneration &&
               <span style={{color:keyInsights.cfGeneration.ytd >= 0? '#090909':'red',padding:'0'}}>
               {formatCurrency(keyInsights.cfGeneration.ytd,company.currency)}
            </span>}
          </span>
          </div>
          <div className='key-insight'>
            <label>Debt Balance </label>
            <span style={{
              display:'block',
              padding:'0',
              marginTop:'0.5em'}}>
            {keyInsights.balance &&
             <span style={{color:keyInsights.balance.debt >  0? 'red':'#090909',padding:'0'}}>
               {formatCurrency(keyInsights.balance.debt,company.currency)}
             </span>}
          </span>
          </div>
        </div>
      }
      {
        isError && <ErrorResponse dataTestId={`reports-key-insights-error`} error={isError}/>
      }

    </Fragment>
  )
}
export default ReportsKeyInsights;