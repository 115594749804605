import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import React from "react";
import notify from "../notify/notify";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list[1]);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {removed:removed};
  result[droppableSource.droppableId.substr(0,1)] = sourceClone;
  result[droppableDestination.droppableId.substr(0,1)] = destClone;

  return result;
};

const grid = 0.5;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: `${grid * 1.5}em`,
  margin: `0.5em ${grid}em`,
  borderRadius:'3px',
  boxShadow:'0 1px 2px rgba(0,0,0,0.5)',
  // change background colour if dragging
  background: isDragging ? "#3B7298" : "#ffffff",
  color: isDragging ? "#fff" : "#090909",
  // styles we need to apply on draggables
  ...draggableStyle
});
const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#6DB0CC" : "#ECF0F4",
  padding: `0 ${grid}em`,
  color:isDraggingOver ? "#ffffff" : "#090909",
  //margin:'0.5em 0.5em 0 0.5em',
});

const getListStyleRightNav = isDraggingOver => ({
  background: isDraggingOver ? "#6DB0CC" : "#ECF0F4",
  padding: '0.7em',
  color:isDraggingOver ? "#ffffff" : "#090909",
  margin:'0.5em 0.5em 0 0.5em',
});

const rightCategories= { display: "flex",flexDirection:'column',flex:'0 0 286px'};
const dragdropContent= { display: "flex"};

const PnlAccounts=({state,track,stateChanged})=>{

  function onDragEnd(result) {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId.substr(0,1);
    const dInd = +destination.droppableId.substr(0,1);

    if (sInd === dInd) {
      const items = reorder(state[sInd], source.index, destination.index);
      const newState = [...state];
      newState[sInd][1] = items;
      track();
      stateChanged(newState);
     // setState(newState);
    } else {
      const result = move(state[sInd][1], state[dInd][1], source, destination);
      notify({type:'SUCCESS',message:`${result.removed.name} moved from '${state[sInd][0]}' to '${state[dInd][0]}'`});
      const newState = [...state];
      newState[sInd][1] = result[sInd];
      newState[dInd][1] = result[dInd];
      track();
      stateChanged(newState);
     // setState(newState);
    }
  }
  return (
    <div style={{marginLeft:'1em'}} className={'pnl-drag-drop'}>
      <DragDropContext onDragEnd={onDragEnd}>
        <div style={{display:'flex',flexFlow:'row nowrap',maxWidth: 'calc(100vw - 1em)',height: 'calc(100vh - 287px)', overflow: 'hidden'}}>
          <div style={{flex:'0 0 calc(100vw - 286px)',overflow: 'auto',border: '1px solid rgb(48, 90, 128)'}}>
            <div style={dragdropContent}>
            {state.map((el, ind) => {
              return(
              <Droppable key={`${ind}1`} droppableId={`${ind}1`}>
                {(provided, snapshot) => {
                  return (
                    <div
                      ref={provided.innerRef}
                      className="draggable-item"
                      style={getListStyle(snapshot.isDraggingOver)}
                      {...provided.droppableProps}
                    >
                      <p className="sectionHeading">{el[0]}</p>
                      {el[1].map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around"
                                }}
                              >
                                {item.name}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}}
              </Droppable>
            )}
            )}
          </div>
          </div>
          <div style={{overflow:'hidden auto',width:'100%'}}>
            <div className="rightCategories" style={rightCategories}>
            <p className={'rightCategoriesHeading'}>P&L Categories</p>
            {state.map((el, ind) => (
              <Droppable key={`${ind}2`} droppableId={`${ind}2`}>
                {(provided, snapshot) => {
                  return (
                    <div
                      ref={provided.innerRef}
                      style={getListStyleRightNav(snapshot.isDraggingOver)}
                      {...provided.droppableProps}
                    >
                      <p className="sectionHeading">{el[0]} <span style={{position:'relative',top:'-3px',color:'rgb(48, 90, 128)'}}>{el[1].length}</span></p>

                      {provided.placeholder}
                    </div>
                  )}}
              </Droppable>
            ))}
          </div>
          </div>
        </div>
      </DragDropContext>
    </div>
  )
}
export default PnlAccounts;