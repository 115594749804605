import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {Mixpanel} from "../mixpanel";
import {useDispatch} from "react-redux";
import './style.css';
import icon from '../img/pop-out.svg';

const CustomLink =({url,name,dispatchAction,wrapperClass='customLinkWrapper',linkClass='customLink',isActive=false})=>{
  const history= useHistory();
  const dispatch = useDispatch();
  const [showIcon,setShowIcon]= useState(false);
  const [showToolTip, setShowToolTip] =useState(false);

  const mixPanelEvent=()=>{
    Mixpanel.track('Url Change',{url});
  }
  const linkClick=()=>{
    history.push(url);
    dispatch(dispatchAction);
    mixPanelEvent();
  }
  const popoutLinkClick =()=>{
    const category=dispatchAction.payload.category;
    const subCategory = dispatchAction.payload.subCategory;
    mixPanelEvent();
    const anchor = document.createElement('a');
    anchor.href=`#${url}?tab=true`+
                `${category? `&category=${(category).replaceAll('&','%26')}`:''}`+
                `${subCategory? `&subCategory=${(subCategory).replaceAll('&','%26')}`:''}`;
    anchor.target='_blank';
    anchor.click();

  }
  const showPopupIcon=()=>{
    setShowIcon(true);
  }
  const hidePopupIcon=()=>{
    setShowIcon(false);
  }
  return(
   <div data-testid={name} className={wrapperClass} onMouseEnter={showPopupIcon} onMouseLeave={hidePopupIcon}>
     <span className={linkClass} onClick={linkClick}>{name}</span>
     {
         showIcon &&
       <span className='popoutIcon'>
         <img
           src={icon}
           alt={'popout'}
           style={{width:'12px',height:'12px'}}
           onClick={popoutLinkClick}
           onMouseEnter={()=>{setShowToolTip(true)}}
           onMouseLeave={()=>{setShowToolTip(false)}}
         />
         {/*{<span className='popoutTooltip'>Open in new tab</span>}*/}
         {showToolTip && <span className='popoutTooltip'>Open in new tab</span>}
       </span>
     }
   </div>
  )
}
export default CustomLink;