import React, {Fragment} from "react";
import ReportSection from "./report-section";
import ReportsKeyInsights from "./reports-key-insights";
import Loader from "../reusables/Loader";
import parse from 'html-react-parser';
import FinancialPerformanceSummary from "./financial-performance-table";
import RadarChartComp from "../radar-chart";
import WaterfallChart from "../waterfall-chart";
import {formatCurrency} from "../analysis-helper";
import infoCopies from "../chart-info-copies";
import SimpleBarChart from "../simple-bar-chart";
import BsRepresentationBarChart from "../bs-representation-bar-chart";
import ErrorResponse from "../error-response/error-response";

const MonthlyReportSection = (
  {modifySectionToggle,
    reportType,
    addRemoveSectionList,
    commentModeToggle,
    addRemoveSection,
    keyInsightsLoading,
    keyInsightsError,
    keyInsights,
    company,
    overallEbitdaContriWriteupLoading,
    overallEbitdaContriWriteupError,
    overallEbitdaContriWriteup,
    changeEbitdaMonthLoading,
    changeEbitdaMonthError,
    changeEbitdaMonth,
    changeEbitdaMarginMonthLoading,
    changeEbitdaMarginMonthError,
    changeEbitdaMarginMonth,
    categoryDifferenceMonthlyLoading,
    categoryDifferenceMonthlyError,
    categoryDifferenceMonthlyChart,
    selectedDate,
    bsOverallWriteupLoading,
    bsOverallWriteupError,
    bsOverallWriteup,
    showBsCatagoryWriteUp,
    categoryCfGenerationMonthlyLoading,
    categoryCfGenerationMonthlyError,
    categoryCfGenerationMonthlyChart,
    cashflowGenerationMonthWriteupLoading,
    cashflowGenerationMonthWriteupError,
    showWriteup,
    cashflowGenerationMonthWriteup
  })=>{
  return (
    <Fragment>
      <Fragment>
        <ReportSection
          showControls={modifySectionToggle}
          name={'Key Insights'}
          addRemoveSectionList={addRemoveSectionList}
          toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
          commentMode={commentModeToggle}>
          <div className='lineChartHeading report-full-section-heading'>
            <span className='lineChartHeadingLabel'>Monthly Financial Performance Summary</span>
          </div>
          <ReportsKeyInsights isLoading={keyInsightsLoading} isError={keyInsightsError} keyInsights={keyInsights}
                              company={company}/>
        </ReportSection>

        <ReportSection
          showControls={modifySectionToggle}
          name={'Key Insights Writeup'}
          addRemoveSectionList={addRemoveSectionList}
          toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
          commentMode={commentModeToggle}
        >
          <div style={{margin: '0 0.9em 1em 0.9em'}}>
            {overallEbitdaContriWriteupLoading && <div className={`align-loader`}>
              <Loader dataTestId={`overall-ebitda-contri-writeup-loading`} type={'pulse'} size={10}/></div>}
            {(!overallEbitdaContriWriteupLoading && !overallEbitdaContriWriteupError) &&
             <div className={`writeups-container`}>
                <div className='writeup-wrapper'>
                  <p>{parse(`${overallEbitdaContriWriteup.ebitda_monthly}`)}</p>
                  <p>{parse(`${overallEbitdaContriWriteup.ebitda_margin_monthly}`)}</p>
                </div>
             </div>
            }
          </div>
        </ReportSection>
      </Fragment>
      <div style={{display: 'flex'}}>
        <div style={{width: '50%'}}>
          <ReportSection
            showControls={modifySectionToggle}
            name={'Financial Performance Summary'}
            addRemoveSectionList={addRemoveSectionList}
            toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
            commentMode={commentModeToggle}
          >
            <FinancialPerformanceSummary company={company}/>
          </ReportSection>
        </div>
        <div style={{width: '50%'}}>
          <ReportSection
            showControls={modifySectionToggle}
            name={'Financial Performance Summary'}
            addRemoveSectionList={addRemoveSectionList}
            toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
            commentMode={commentModeToggle}
          >
            <RadarChartComp datatestid={'Radar-chart'} loading={false} error={null}
                            chartInfoCopy={'test radar infor copy'}/>
          </ReportSection>
        </div>
      </div>
      <div style={{display: 'flex',paddingTop:'2em'}}>
        <div style={{width: '50%'}}>
          <ReportSection
            showControls={modifySectionToggle}
            name={'Cost of Sales as % of Sales (month)'}
            addRemoveSectionList={addRemoveSectionList}
            toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
            commentMode={commentModeToggle}
          >
            <div style={{padding: '0 0 1em 1.5em'}}>
              <Fragment>
                <div className='lineChartHeading'>
                  <span className='lineChartHeadingLabel'>Cost of Sales as % of Sales (month)</span>
                </div>
                <WaterfallChart
                  dataTestId={'change-ebitda-month'}
                  dataValue={'P&L Summary'}
                  formatter={(value) => formatCurrency(value, company.currency)}
                  loading={changeEbitdaMonthLoading}
                  error={changeEbitdaMonthError}
                  data={changeEbitdaMonth}
                  chartInfoCopy={infoCopies.pnlSummary.changeInMonthEBITDAFromLastYear}
                />
              </Fragment>
            </div>
          </ReportSection>
        </div>
        <div style={{width: '50%'}}>
          <ReportSection
            showControls={modifySectionToggle}
            name={'Contributors to EBITDA change'}
            addRemoveSectionList={addRemoveSectionList}
            toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
            commentMode={commentModeToggle}
          >
            <div style={{padding: '0 0 1em 1.5em'}}>
              <Fragment>
                <div className='lineChartHeading'>
                  <span className='lineChartHeadingLabel'>Contributors to EBITDA change (month)</span>
                </div>
                <WaterfallChart
                  dataTestId={'change-ebitda-month'}
                  dataValue={'P&L Summary'}
                  formatter={(value) => formatCurrency(value, company.currency)}
                  loading={changeEbitdaMonthLoading}
                  error={changeEbitdaMonthError}
                  data={changeEbitdaMonth}
                  chartInfoCopy={infoCopies.pnlSummary.changeInMonthEBITDAFromLastYear}
                />
              </Fragment>
            </div>
          </ReportSection>
        </div>
      </div>
      <div style={{display: 'flex'}}>
        <div style={{width: '50%'}}>
          <ReportSection
            showControls={modifySectionToggle}
            name={'Contributors to EBITDA margin change'}
            addRemoveSectionList={addRemoveSectionList}
            toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
            commentMode={commentModeToggle}
          >
            <div style={{padding: '0 0 1em 1.5em'}}>
              <Fragment>
                <div className='lineChartHeading'>
                  <span className='lineChartHeadingLabel'>Contributors to EBITDA margin change (month)</span>
                </div>
                <WaterfallChart
                  dataTestId={`change-ebitda-margin-month`}
                  dataValue={'P&L Summary'}
                  formatter={(value) => `${parseFloat(value).toFixed(1)} %`}
                  loading={changeEbitdaMarginMonthLoading}
                  error={changeEbitdaMarginMonthError}
                  data={changeEbitdaMarginMonth}
                  chartInfoCopy={infoCopies.pnlSummary.changeInMonthEBITDAMarginFromLastYear}
                />
              </Fragment>
            </div>
          </ReportSection>
        </div>
        <div style={{width: '50%'}}>
          <ReportSection
            showControls={modifySectionToggle}
            name={'EBITDA to Net Income (month)'}
            addRemoveSectionList={addRemoveSectionList}
            toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
            commentMode={commentModeToggle}
          >
            <div style={{padding: '0 0 1em 1.5em'}}>
              <Fragment>
                <div className='lineChartHeading'>
                  <span className='lineChartHeadingLabel'>EBITDA to Net Income (month)</span>
                </div>
                <WaterfallChart
                  dataTestId={`change-ebitda-margin-month`}
                  dataValue={'P&L Summary'}
                  formatter={(value) => `${parseFloat(value).toFixed(1)} %`}
                  loading={changeEbitdaMarginMonthLoading}
                  error={changeEbitdaMarginMonthError}
                  data={changeEbitdaMarginMonth}
                  chartInfoCopy={infoCopies.pnlSummary.changeInMonthEBITDAMarginFromLastYear}
                />
              </Fragment>
            </div>
          </ReportSection>
        </div>
      </div>



      <div style={{display: 'flex'}}>
        <div style={{width: '50%'}}>
          <ReportSection
            showControls={modifySectionToggle}
            name={'Total SG&A difference vs Previous year'}
            addRemoveSectionList={addRemoveSectionList}
            toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
            commentMode={commentModeToggle}
          >
            <div style={{padding: '0 0 1em 1.5em'}}>
              <Fragment>
                <div className='lineChartHeading'>
                  <span className='lineChartHeadingLabel'>Total SG&A difference vs Previous year</span>
                </div>
                <SimpleBarChart
                  dataTestId={'bar-category-difference-monthly'}
                  dataValue={'SG&A'}
                  fullWidth={true}
                  formatter={(value) => formatCurrency(value, company.currency)}
                  loading={categoryDifferenceMonthlyLoading}
                  error={categoryDifferenceMonthlyError}
                  data={categoryDifferenceMonthlyChart}
                  chartInfoCopy={infoCopies.majorInceresesAndDecresesFromLastYearMonth}
                />
              </Fragment>
            </div>
          </ReportSection>
        </div>
        <div style={{width: '50%'}}>
          <ReportSection
            showControls={modifySectionToggle}
            name={'Total SG&A difference vs Previous year'}
            addRemoveSectionList={addRemoveSectionList}
            toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
            commentMode={commentModeToggle}
          >
            <div style={{padding: '0 0 1em 1.5em'}}>
              <Fragment>
                <div className='lineChartHeading'>
                  <span className='lineChartHeadingLabel'>Total SG&A difference vs Previous year</span>
                </div>
                <SimpleBarChart
                  dataTestId={'bar-category-difference-monthly'}
                  dataValue={'SG&A'}
                  fullWidth={true}
                  formatter={(value) => formatCurrency(value, company.currency)}
                  loading={categoryDifferenceMonthlyLoading}
                  error={categoryDifferenceMonthlyError}
                  data={categoryDifferenceMonthlyChart}
                  chartInfoCopy={infoCopies.majorInceresesAndDecresesFromLastYearMonth}
                />
              </Fragment>
            </div>
          </ReportSection>
        </div>
      </div>

      {(reportType !== 'Individual Section View') &&
       <Fragment>
        <ReportSection
          showControls={modifySectionToggle}
          name={'Balance Sheet Commentary'}
          addRemoveSectionList={addRemoveSectionList}
          toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
          commentMode={commentModeToggle}
        >
          {<p className={`bs-representation-title`}>Balance Sheet Representation as of&nbsp;
            {selectedDate.month} {selectedDate.year}</p>}
          {
            <div className={`line-charts-wrapper`}>
              <BsRepresentationBarChart
                dataTestId={`bs-overall-writeup-chart`}
                dataValue={null}
                formatter={(value) => formatCurrency(value, company.currency)}
                loading={bsOverallWriteupLoading}
                error={bsOverallWriteupError}
                data={bsOverallWriteup.Representation ? bsOverallWriteup.Representation : null}
                chartInfoCopy={infoCopies.balanceSheetRepresentation}
              />
              <div style={{padding: '0 2em', flex: '0 0 50%', marginTop: '-3.5em'}}>
                {bsOverallWriteupLoading &&
                 <div style={{margin: '1em auto 2em auto'}}><Loader dataTestId={`bs-overall-writeup-loading`}
                                                                    type={'pulse'} size={10}/></div>}
                {!bsOverallWriteupLoading && Object.keys(bsOverallWriteup).length > 0 && showBsCatagoryWriteUp(bsOverallWriteup.WriteUp)}
              </div>
            </div>}
        </ReportSection>
      </Fragment>
      }

       <ReportSection
         showControls={modifySectionToggle}
         name={'CashFlow Commentary'}
         addRemoveSectionList={addRemoveSectionList}
         toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
         commentMode={commentModeToggle}
       >
        <Fragment>
           <div style={{display: 'flex'}}>
             <div style={{flex: '0 0 50%', padding: '0px 0px 1em 1.5em'}}>
               <div className='lineChartHeading'>
                 <span className='lineChartHeadingLabel'>CashFlow Generation</span>
               </div>
               <WaterfallChart
                 dataTestId={'category-cf-generation-monthly'}
                 dataValue={'CF Summary'}
                 formatter={(value) => formatCurrency(value, company.currency)}
                 loading={categoryCfGenerationMonthlyLoading}
                 error={categoryCfGenerationMonthlyError}
                 data={categoryCfGenerationMonthlyChart}
                 chartInfoCopy={infoCopies.cfSummary.cashflowGenerationMonth}
               />
             </div>
             <div style={{padding: '0 2em', flex: '0 0 50%'}}>
               <div className='lineChartHeading'>
                 <div className={`writeup-wrapper`} style={{margin: '0 0'}}>
                   {cashflowGenerationMonthWriteupLoading && <div style={{margin: '1em 0 2em 0'}}>
                     <Loader dataTestId={`cashflow-generation-month-writeup-loading`} type={'pulse'} size={10}/>
                   </div>}
                   {cashflowGenerationMonthWriteupError &&
                    <ErrorResponse dataTestId={`cashflow-generation-month-writeup-error`}
                                   error={cashflowGenerationMonthWriteupError}/>}
                   {!cashflowGenerationMonthWriteupLoading && showWriteup(cashflowGenerationMonthWriteup, 'CF')}
                 </div>
               </div>
             </div>
           </div>
         </Fragment>
       </ReportSection>
      <ReportSection
        showControls={modifySectionToggle}
        name={'Month P&L Commentary VS Previous Year'}
        addRemoveSectionList={addRemoveSectionList}
        toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
        commentMode={commentModeToggle}
      >

        <Fragment>
          <div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>Month P&L Commentary VS Previous Year</span>
          </div>
          <div style={{display: 'flex'}}>
            <div style={{padding: '0 2em', flex: '0 0 50%'}}>
              <div className='lineChartHeading'>
                <div className={`writeup-wrapper-commentary`} style={{margin: '0 0'}}>
                  {cashflowGenerationMonthWriteupLoading && <div style={{margin: '1em 0 2em 0'}}>
                    <Loader dataTestId={`cashflow-generation-month-writeup-loading`} type={'pulse'} size={10}/>
                  </div>}
                  {cashflowGenerationMonthWriteupError &&
                   <ErrorResponse dataTestId={`cashflow-generation-month-writeup-error`}
                                  error={cashflowGenerationMonthWriteupError}/>}
                  {!cashflowGenerationMonthWriteupLoading && showWriteup(cashflowGenerationMonthWriteup, 'CF')}
                </div>
              </div>
            </div>
            <div style={{padding: '0 2em', flex: '0 0 50%'}}>
              <div className='lineChartHeading'>
                <div className={`writeup-wrapper-commentary`} style={{margin: '0 0'}}>
                  {cashflowGenerationMonthWriteupLoading && <div style={{margin: '1em 0 2em 0'}}>
                    <Loader dataTestId={`cashflow-generation-month-writeup-loading`} type={'pulse'} size={10}/>
                  </div>}
                  {cashflowGenerationMonthWriteupError &&
                   <ErrorResponse dataTestId={`cashflow-generation-month-writeup-error`}
                                  error={cashflowGenerationMonthWriteupError}/>}
                  {!cashflowGenerationMonthWriteupLoading && showWriteup(cashflowGenerationMonthWriteup, 'CF')}
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      </ReportSection>
    </Fragment>
  )
}

export default MonthlyReportSection;