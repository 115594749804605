import React, {useEffect, useState} from 'react';
import CustomMonthYearSelect from "../custom-month-year-select";
import {useDispatch, useSelector} from 'react-redux';
import './subHeader.css';
import {Mixpanel} from "../mixpanel";
import { timeSince } from '../util/dateHelper';
import CustomSelect from "../custom-select";
import {useHistory} from "react-router-dom";
import useGet from "../hooks/use-get";
import settingsIcon from "../img/settings-icon.png";

import {some} from "lodash";

const env ={
    DEV: 'DEV',
    LOCAL: 'LOCAL',
    PROD: 'PROD',
};
const getEnvironment = () => {
    if (some(['localhost'], el => window.location.hostname.indexOf(el) >= 0) ||
        window.location.protocol === 'http:') {
        return env.LOCAL;
    } else if (some(['dev'], el => window.location.hostname.indexOf(el) >= 0)) {
        return env.DEV;
    }
    return env.PROD;
};
function SubHeader({text,heading='',version2=false,wrapperClass=''}) {
    const monthsInAnYear = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const dispatch = useDispatch();
    const history = useHistory();
    const company = useSelector(state => state.selectedCompany);
    const selectedDate = useSelector(state => state.endDate);
    const lastUpdated = company.updatedOn;
    const currentEnv = getEnvironment();
    const [monthOptions, setMonthOptions] = useState(getMonthOptionsBasedOnYearSelected(selectedDate.year));

    const paramSection = useSelector(({selectedNavigation:{sheet}}) => sheet);
    const paramCategory = useSelector(({selectedNavigation:{category}}) => category);
    const paramSubCategory = useSelector(({selectedNavigation:{subCategory}}) => subCategory);

    const companyList = useSelector(state => state.companyList);
    const companySort=(list)=>{
        return list.sort((a,b)=>{
            return b.companyName - a.companyName
        })
    }
    const [ytdMonth,setYtdMonth]=useState('');
    const getDate=(date)=> `${date.month} ${date.year.slice(-2)}`;

    const [ytdMonthResponse, ytdMonthLoading, ytdMonthError,] = useGet({
        url: `${process.env.REACT_APP_BACKEND_BASE_URL}/months-ytd?companyId=${company.companyId}`+
             `&date=${getDate(selectedDate)}`,
    });

    useEffect(()=>{
            if (!ytdMonthLoading && ytdMonthResponse) {
                setYtdMonth(ytdMonthResponse.monthsYtd);
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ytdMonthResponse, ytdMonthLoading, ytdMonthError,company,selectedDate]);

    function getMonthOptionsBasedOnYearSelected(selectedYear) {
        if (parseInt(selectedYear) < new Date().getFullYear()) {
            return monthsInAnYear.map(month => ({
                label: month,
                selectable: true
            }))
        } else {
            const lastSelectableMonthIndex = new Date().getMonth();
            return monthsInAnYear.map(month => {
                if (monthsInAnYear.indexOf(month) <= lastSelectableMonthIndex) {
                    return {
                        label: month,
                        selectable: true
                    }
                } else {
                    return {
                        label: month,
                        selectable: false
                    }
                }
            })
        }
    }
    const handleChange =(text)=>{
        const selectedText = text;
        Mixpanel.track('Company Selected',{company:companyList.find((obj) => obj.companyName === selectedText)});
        dispatch({type:'selectCompany',payload:companyList.find((obj) => obj.companyName === selectedText)});
        dispatch(
          {
              type:'urlChange',
              payload:{
                  section: 'insights',
                  sheet:null,
                  category:null,
                  subCategory: null,
              }});
        history.push('/insights');
    }

    const handleYearChange = (selectedYearString) => {
        setMonthOptions(getMonthOptionsBasedOnYearSelected(selectedYearString));
        Mixpanel.track('Year Change',{year:selectedYearString});
        dispatch({type: 'changeEndYear', payload: selectedYearString});
    }
    const lastUpdatedStyle= {color:'#39505E',fontSize:'0.9em',flex: '1 1 20%',display:`${version2?`none`:`block`}`}


    const toggleSettingsMenu =(e)=>{
        const elem =e.target.parentElement.querySelector('.settings-menu');
        if(elem.classList.contains('show')){
            elem.classList.remove('show');
        }
        else{
            elem.classList.add('show');
        }
    }


    return (
        <div className={`${version2? `version2`:`version1`} ${wrapperClass}`}>
            <div className="subHeader">
                { Object.entries(company).length > 0 && history.location.pathname !== '/' ?
                  <CustomSelect
                    dataTestId={'company-switcher'}
                    selectedValue={company.companyName}
                    options={companySort(companyList).map(obj=> ({label: obj.companyName, selectable: true, disconnected:obj.disconnected}))}
                    className={`companySwitcher`}
                    callback={handleChange}/>
                  : ''}
                {heading !=='' && <h3 className='subHeaderHeadingText'>{heading}</h3>}
                {!version2 && <div className='header-center-section'>
                <div className='subheader-date-picker'>
                    <span className="subheaderText">{text}</span>
                    <CustomMonthYearSelect monthOptions={monthOptions}
                                           selectedMonth={selectedDate.month}
                                           selectedYear={selectedDate.year}
                                           callbackMonthChange={(text) => {
                                               Mixpanel.track('Month Change',{month:text});
                                               dispatch({type: 'changeEndMonth', payload: text})}}
                                           callBackYearChange={handleYearChange}
                    />
                </div>
                <div className='ytd-month'>
                    (YTD is {!ytdMonthLoading && <span>{ytdMonth} {parseInt(ytdMonth) === 1 ?'month':'months'})</span>}
                    {!ytdMonthLoading && ytdMonthError && <span className="chart-error">Error fetching data. Try again later</span>}
                </div>
                </div>}
                {version2 && <p className="version2Heading">{text}</p>}
            <div style={lastUpdatedStyle}>
                Last updated : <span style={{fontSize:'1em',color:'#090909'}}>{timeSince(lastUpdated)} ago</span>
            </div>
            <div>
              { !(text === 'Chart of Accounts') && currentEnv !==  'PROD' && <span style={{position:'relative'}}>
                        <img src={settingsIcon} alt='settings' className="settings-icon" onClick={toggleSettingsMenu}/>
                        <div className="settings-menu">
                          <a
                            onClick={()=>{
                                dispatch(
                                  {
                                      type:'urlChange',
                                      payload:{
                                          section: 'Chart of Accounts',
                                          sheet:paramSection,
                                          category:paramCategory,
                                          subCategory: paramSubCategory,
                                      }});
                                Mixpanel.track('Url Change',{'company':company.companyName,url:'/chart-of-accounts'});
                            }}
                            href={`#/chart-of-accounts`}
                            className="">
                        Chart of Accounts
                      </a>
                        </div>
                      </span>}
            </div>
            </div>
        </div>
    )
}

export default SubHeader;
