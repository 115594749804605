import React from 'react';

import {
  CartesianGrid,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
  LineChart,
} from "recharts";
import Loader from "../reusables/Loader";
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';
import ErrorResponse from "../error-response/error-response";
import ChartInfoIcon from "../chart-info";

const colors = scaleOrdinal(schemeCategory10).range();

const CustomTooltip = (props) => {
  const { active, payload, label,formatter } = props;
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="tooltip-heading">{`${label}`}</p>
        { payload.map((item,index) => (
          <div key={index}>
            <div style={{color: item.stroke}}>
            <span className="tooltip-label">{item.name} : </span>
            <span className="tooltip-value">{formatter(item.value)}</span>
            </div>
          </div>
          ))}
      </div>
    );
  }

  return null;
};

const LINE_CHART_HEIGHT = 340;
const LineChartMultipleSeries =({series,loading,error,showGrid=false, formatter,dataTestId,chartInfoCopy})=>{

  if(loading) {
    return <div style={{margin:'1em 0 2em 0'}}><Loader dataTestId={`${dataTestId}-loading`} type={'pulse'} size={10}/></div>
  }
  if(error){
    return (<ErrorResponse dataTestId={`${dataTestId}-error`} error={error}/>)
  }
  if(series.length > 0 && !loading) {
    return (
      <div style={{ fontSize: '0.8em', width: '100%',maxWidth:'700px' }} data-testid={dataTestId}>
          <ChartInfoIcon text={chartInfoCopy}/>
        <ResponsiveContainer height={LINE_CHART_HEIGHT}>
          <LineChart height={LINE_CHART_HEIGHT} margin={{top: 5, right: 30, left: 20, bottom: 5}}>
          {showGrid && <CartesianGrid strokeDasharray="3 3"/>}
          <XAxis name="date" dataKey="date" allowDuplicatedCategory={false} />
          <YAxis dataKey="value" tickFormatter={(value)=> formatter(value)}/>
          <Tooltip content={<CustomTooltip/>} formatter={(value)=> formatter(value)}/>
          <Legend wrapperStyle={{padding:'1em 0'}} />
          {series.map((s,index) => (
            <Line type="monotone" dataKey="value" stroke={colors[index % 20]} data={s.data} name={s.name} key={s.name} dot={false} activeDot={{ r: 8 }} strokeWidth="2"/>
          ))}
        </LineChart>
        </ResponsiveContainer>
      </div>
    )
  }
  return <div></div>

}
export default LineChartMultipleSeries;
