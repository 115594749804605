//import settings_icon from "../img/settings-icon.png";
import {useDispatch} from "react-redux";
import xero_logo from "../img/xero_logo.png";
import sync_icon from "../img/sync-alt.svg";
import settingsIcon from "../img/settings-icon.png";
import axios from "axios";
import React, {useEffect, useRef, useState} from "react";
import notify from "../notify/notify";
import Loader from "../reusables/Loader";
import crossIcon from '../img/cross-icon-red.svg';
import qokoon_symbol from '../img/qokoon-logo.png';
import {Mixpanel} from "../mixpanel";
import {timeSince} from "../util/dateHelper";
import Modal from "react-modal";
import {some} from "lodash";

const env ={
  DEV: 'DEV',
  LOCAL: 'LOCAL',
  PROD: 'PROD',
};
const getEnvironment = () => {
  if (some(['localhost'], el => window.location.hostname.indexOf(el) >= 0) ||
      window.location.protocol === 'http:') {
    return env.LOCAL;
  } else if (some(['dev'], el => window.location.hostname.indexOf(el) >= 0)) {
    return env.DEV;
  }
  return env.PROD;
};

function CompanyRow({companiesList,deleteCallBack}) {
const [showModal,setShowModal] =useState(false);
const [deleteConfirmed,setDeleteConfirmed]=useState(false);
const [deleteLoading,setDeleteLoading] =useState(false);

const [selectedCompanyForDelete,setSelectedCompanyForDelete]=useState({});
  const itemsRef = useRef([]);
  const currentEnv = getEnvironment();
const [settingMenuIconKey,setSettingMenuIconKey]=useState(null);

  const sortCompanyList =(list)=> {
    let companyList = list;
    const indexOfDemo = Array.isArray(list) ? list.findIndex((a)=> a.companyName ==='Demo Company'):-1;
    if(indexOfDemo !== -1){
      const dummyCompany = companyList[indexOfDemo];
      companyList = companyList.filter((company)=>
        company.companyName !== 'Demo Company'
      )
      //companyList.forEach(comp=>comp.disconnected =true);
      companyList.sort((a, b) => a.companyName.localeCompare(b.companyName))
      companyList = [dummyCompany,...companyList];
    }
    return companyList;
  }

  const sortedCompanyData = sortCompanyList(companiesList);
  const updateCompany = async (companyId)=>{
    const headers = {
      'Content-Type': 'text/plain;charset=UTF-8',
    };
    return await axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/company/${companyId}`,{companyId:companyId},{
      headers:headers,
      withCredentials: true
    })
  }
  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, companiesList.length);
  }, [companiesList]);

  const onDataSync= async (e,data)=>{
    const showWrapper=e.currentTarget.parentElement.parentElement.firstElementChild;
    showWrapper.style.display='block';
    const syncRowWrapper = e.currentTarget.parentElement.parentElement;
    syncRowWrapper.classList.add('hide-sync-row');
    const response = await updateCompany(data.companyId);
    if(response.status === 200){
      notify({type: 'SUCCESS', message: 'Data updated successfully'});
      Mixpanel.track('Company update successful',{'companyId':data.companyName});
      dispatch({type:'modifyCompanyDetails',payload: {response: response.data,companyId:data.companyId}});
    }
    else{
      Mixpanel.track('Company update failed',{'companyId':data.companyName});
      notify({type: 'ERROR', message: 'Something went wrong. Please try updating later'});
    }
    syncRowWrapper.classList.remove('hide-sync-row');
    showWrapper.style.display='none';
  }

  const getConfirmation=()=>{
    setShowModal(true);
  }
  const deleteCompany= async(companyId)=>{
    const headers = {
      'Content-Type': 'text/plain;charset=UTF-8',
    };

    let parameters = {
      companyId
    };
    setDeleteLoading(true);
    const response = await axios(`${process.env.REACT_APP_BACKEND_BASE_URL}/companies/${companyId}`, {
      method: "delete",
      data: parameters,
      withCredentials: true,
      headers:headers
    });
    // const response ={status:204};
    return response;
  }
  const deleteCompanyClick =(e,data)=>{
    setSelectedCompanyForDelete(data);
    getConfirmation();
}
  // const removeCompany =(e,data)=>{
  //   setSelectedCompanyForDelete(data);
  //   setDeleteConfirmed(true);
  // }
  useEffect( ()=>{
      if(deleteConfirmed && Object.keys(selectedCompanyForDelete).length >0 ) {
        deleteCompany(selectedCompanyForDelete.companyId).then(
          (response)=>{
            setDeleteLoading(false);
            if(response.status === 204){
              notify({type:'success',message:`${selectedCompanyForDelete.companyName} deleted successfully!`});
              deleteCallBack();
            }
            else{
              notify({type:'error',message:`Something went wrong. Please try again later`});
              deleteCallBack();
            }
            setShowModal(false);
          }
        );
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedCompanyForDelete,deleteConfirmed]);
  useEffect(() => {
    document.addEventListener('click', closeSettingsMenu);

    return(() => document.removeEventListener('click', closeSettingsMenu));

  });

  const closeSettingsMenu=(e)=>{
    if(settingMenuIconKey !==null) {
      if(e.target.className !== 'settings-icon') {
        settingMenuIconKey.nextElementSibling.classList.remove('show');
        setSettingMenuIconKey(null);
      }
    }
  }
  const dispatch=useDispatch();
  const toggleSettingsMenu =(e,index)=>{
    e.stopPropagation();
    itemsRef.current.forEach((elem)=>{
      elem.nextSibling.classList.remove('show');
    })
    setSettingMenuIconKey(itemsRef.current[index]);
    itemsRef.current[index].nextSibling.classList.add('show');
  }
  return (
    <div>
      { sortedCompanyData.map((data,index) => {
        if(data.disconnected) {
          return (
            <p key={index} className={`disconnected-company`}>You don’t have access to {data.companyName} in Xero any more.
              For security reasons, QoKoon has deleted all Financial data associated with {data.companyName}.</p>)
        }
        else{
          return <span key={index}> </span>
        }
      })}
    <table data-testid="companies-list" id="companies-list" className="company-table table">
      <thead>
      <tr className="table-header">
        <th className="company-name">Company name</th>
        <th className="data-source">Data Source</th>
        <th className="last-updated">Last updated <span style={{paddingLeft:'0'}}>(Local Time)</span></th>
        <th className="actions">Actions</th>
      </tr>
      </thead>
      <tbody>
      {
        sortedCompanyData.map((data,index) => {
          const updationDate = timeSince(Date.parse(data.updatedOn));
           return(
          <tr className={`company-data table-data ${data.disconnected ?'hide-row-disconnected':''}`} key={data.companyId}>
            <div className={`hide-row`}><Loader type={'pulse'} size={20}/> </div>
            <td className="company-name">{data.companyName}</td>
            <td className="data-source">{
              data.companyName === 'Demo Company' && <img id="qokoon-logo" src={qokoon_symbol} alt="" style={{width: '3em'}} />}
              {
                data.companyName !== 'Demo Company' && <img id="xero-logo" src={xero_logo} alt="" style={{width: '3em'}} />
            }</td>
            <td className="last-updated">
              <span style={{fontWeight:'500'}}>{`${updationDate}`} ago</span>
              <img id={`sync-img`} src={sync_icon} alt={"sync"} style={{width:'1.5em',cursor:'pointer'}} title={`Sync data`} onClick={(e)=>onDataSync(e,data)} />
            </td>
            <td>
              <span className="actions">
                      <a
                        onClick={()=>{
                          dispatch({type: 'selectCompany', payload: data});
                          dispatch(
                            {
                              type:'urlChange',
                              payload:{
                                section: 'insights',
                                sheet:null,
                                category:null,
                                subCategory: null,
                              }});
                          Mixpanel.track('Url Change',{'company':data.companyName,url:'/insights'});
                        }}
                        href={`#/insights`}
                        className="primary-btn small">
                        Key Insights
                      </a>
                      <a
                        onClick={(e)=>{
                          Mixpanel.track('Url Change',{'company':data.companyName,url:'/analyse'});
                          dispatch(
                            {
                              type:'urlChange',
                              payload:{
                                section: 'analyse',
                                sheet:'Profit and Loss',
                                category:'P&L Summary',
                                subCategory: null,
                              }});
                          dispatch({type: 'selectCompany', payload: data})}}
                        href={`#/analyse/Profit%20and%20Loss`}
                        className="primary-btn small"
                      >Analyse</a>
                      <a
                        onClick={()=>{
                          Mixpanel.track('Url Change',{'company':data.companyName,url:'/anomalies'});
                          dispatch(
                            {
                              type:'urlChange',
                              payload:{
                                section: 'anomalies',
                                sheet:null,
                                category: null,
                                subCategory: null,
                              }});
                          dispatch({type: 'selectCompany', payload: data})}}
                        href={`#/anomalies`}
                        className="primary-btn small"
                      >Anomalies</a>
                      <a
                        onClick={()=> {
                          Mixpanel.track('Url Change',{'company':data.companyName,url:'/reports'});
                          dispatch(
                            {
                              type:'urlChange',
                              payload:{
                                section: 'reports',
                                sheet:null,
                                category: null,
                                subCategory: null,
                              }});
                          dispatch({type: 'selectCompany', payload: data})}}
                        href={`#/reports`}
                        className="primary-btn small"
                      >Reports</a>
                {currentEnv !==  'PROD' && <a
                  onClick={() => {
                    Mixpanel.track('Url Change', {'company': data.companyName, url: '/financials'});
                    dispatch(
                      {
                        type: 'urlChange',
                        payload: {
                          section: 'financials',
                          sheet: null,
                          category: null,
                          subCategory: null,
                        }
                      });
                    dispatch({type: 'selectCompany', payload: data})
                  }}
                  href={`#/financials`}
                  className="primary-btn small"
                >Financials</a>
                }
                      {currentEnv !==  'PROD' &&
                        <span style={{position:'relative'}}>
                          <img src={settingsIcon} alt='settings' key={index}
                               ref={el => itemsRef.current[index] = el}  className="settings-icon" onClick={(e)=>{toggleSettingsMenu(e,index)}}/>
                          <div className="settings-menu">
                            <a
                              onClick={()=>{
                                dispatch({type: 'selectCompany', payload: data});
                                dispatch(
                                  {
                                    type:'urlChange',
                                    payload:{
                                      section: 'Chart of Accounts',
                                      sheet:null,
                                      category:null,
                                      subCategory: null,
                                    }});
                                Mixpanel.track('Url Change',{'company':data.companyName,url:'/chart-of-accounts'});
                              }}
                              href={`#/chart-of-accounts`}
                              className="">
                              Chart of Accounts
                            </a>
                          </div>
                      </span>
                      }
                      <span className={`delete-company`}>
                      {data.companyName !== 'Demo Company'?
                        <div style={{position:'relative'}}>
                          {!data.disconnected && <img className='delete-button' src={crossIcon} onClick={(e)=>deleteCompanyClick(e,data)} alt='delete'/>}
                          {/*{data.disconnected && <img className='delete-button' src={crossIcon} onClick={(e)=>removeCompany(e,data)} alt='delete'/>}*/}
                          <span className='popoutTooltip'>Delete Company</span>
                        </div>:
                        <div style={{width:"22px",height:'33px'}}> </div>
                      }
                    </span>
                  </span>
            </td>
          </tr>)
        })
      }
      </tbody>
    </table>
      <Modal
        style={{
          content: {
          }
        }}
        className="simpleModal"
        overlayClassName="modalOverlay"
        isOpen={showModal}
        contentLabel="Add a company"
        data-testid="delete-company-modal"
      >
        <div className="modalHeader"><h2>Delete company</h2>
          <button
            className="modalCloseButton"
            onClick={()=>{
              setShowModal(false);
            }}>
          </button>
        </div>
        <div className='modalBody'>
            <p style={{fontSize:'1.1em',fontWeight:'bold'}}>Please confirm that you want to remove {selectedCompanyForDelete.companyName} from QoKoon?</p>
            <p style={{fontSize: '1.1em',width: '90%',textAlign: 'center',margin: '0 auto',display: 'block',padding: '1em',lineHeight:'1.5'}}>{selectedCompanyForDelete.companyName} will be disconnected from Xero and QoKoon will delete all financial data associated with it.</p>
          {deleteLoading && <div style={{margin:'1em auto',position:'relative',right:'50%'}}><Loader dataTestId={`delete-loading`} type={'pulse'} size={10}/></div>}
          <div style={{paddingTop:'2em'}}>
            <button className='primary-btn' disabled={deleteLoading} onClick={()=>{setDeleteConfirmed(true)}}>Delete</button>
            <button className='secondary-btn' disabled={deleteLoading} style={{marginLeft:'2em'}} onClick={()=>{setShowModal(false)}}>Cancel</button>
          </div>
        </div>
      </Modal>

    </div>
  )
}
export default CompanyRow;