import React, {Fragment} from "react";
import ReportSection from "./report-section";
import ReportsKeyInsights from "./reports-key-insights";
import Loader from "../reusables/Loader";
import parse from 'html-react-parser';
import FinancialPerformanceSummary from "./financial-performance-table";
import RadarChartComp from "../radar-chart";
import WaterfallChart from "../waterfall-chart";
import {formatCurrency} from "../analysis-helper";
import infoCopies from "../chart-info-copies";
import SimpleBarChart from "../simple-bar-chart";
import BsRepresentationBarChart from "../bs-representation-bar-chart";
import ErrorResponse from "../error-response/error-response";

const YTDReportSection = (
  {modifySectionToggle,
    reportType,
    addRemoveSectionList,
    commentModeToggle,
    addRemoveSection,
    keyInsightsLoading,
    keyInsightsError,
    keyInsights,
    company,
    overallEbitdaContriWriteupLoading,
    overallEbitdaContriWriteupError,
    overallEbitdaContriWriteup,
    changeEbitdaYTDLoading,
    changeEbitdaYTDError,
    changeEbitdaYTD,
    changeEbitdaMarginYTDLoading,
    changeEbitdaMarginYTDError,
    changeEbitdaMarginYTD,
    categoryDifferenceYTDLoading,
    categoryDifferenceYTDError,
    categoryDifferenceYTDChart,
    selectedDate,
    bsOverallWriteupLoading,
    bsOverallWriteupError,
    bsOverallWriteup,
    showBsCatagoryWriteUp,
    categoryCfGenerationYTDLoading,
    categoryCfGenerationYTDError,
    categoryCfGenerationYTDChart,
    cashflowGenerationYTDWriteupLoading,
    cashflowGenerationYTDWriteupError,
    showWriteup,
    cashflowGenerationYTDWriteup
  })=>{
  return (
    <Fragment>
      <Fragment>
        <ReportSection
          showControls={modifySectionToggle}
          name={'Key Insights'}
          addRemoveSectionList={addRemoveSectionList}
          toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
          commentMode={commentModeToggle}>
          <div className='lineChartHeading report-full-section-heading'>
            <span className='lineChartHeadingLabel'>Year to Date Financial Performance Summary</span>
          </div>
          <ReportsKeyInsights isLoading={keyInsightsLoading} isError={keyInsightsError} keyInsights={keyInsights}
                              company={company}/>
        </ReportSection>

        <ReportSection
          showControls={modifySectionToggle}
          name={'Key Insights Writeup'}
          addRemoveSectionList={addRemoveSectionList}
          toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
          commentMode={commentModeToggle}
        >
          <div style={{margin: '0 0.9em 1em 0.9em'}}>
            {overallEbitdaContriWriteupLoading && <div className={`align-loader`}>
              <Loader dataTestId={`overall-ebitda-contri-writeup-loading`} type={'pulse'} size={10}/></div>}
            {(!overallEbitdaContriWriteupLoading && !overallEbitdaContriWriteupError) &&
             <div className={`writeups-container`}>
                <div className='writeup-wrapper'>
                  <p>{parse(`${overallEbitdaContriWriteup.ebitda_yearly}`)}</p>
                  <p>{parse(`${overallEbitdaContriWriteup.ebitda_margin_yearly}`)}</p>
                </div>
             </div>
            }
          </div>
        </ReportSection>
      </Fragment>
      <div style={{display: 'flex'}}>
        <div style={{width: '50%'}}>
          <ReportSection
            showControls={modifySectionToggle}
            name={'Financial Performance Summary'}
            addRemoveSectionList={addRemoveSectionList}
            toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
            commentMode={commentModeToggle}
          >
            <FinancialPerformanceSummary company={company}/>
          </ReportSection>
        </div>
        <div style={{width: '50%',alignSelf:'center'}}>
          <ReportSection
            showControls={modifySectionToggle}
            name={'Financial Performance Summary'}
            addRemoveSectionList={addRemoveSectionList}
            toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
            commentMode={commentModeToggle}
          >
            <RadarChartComp datatestid={'Radar-chart'} loading={false} error={null}
                            chartInfoCopy={'test radar infor copy'}/>
          </ReportSection>
        </div>
      </div>
      <div style={{display: 'flex',paddingTop:'2em'}}>
        <div style={{width: '50%'}}>
          <ReportSection
            showControls={modifySectionToggle}
            name={'Contributors to EBITDA change'}
            addRemoveSectionList={addRemoveSectionList}
            toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
            commentMode={commentModeToggle}
          >
            <div style={{padding: '0 0 1em 1.5em'}}>
              <Fragment>
                <div className='lineChartHeading'>
                  <span className='lineChartHeadingLabel'>Contributors to EBITDA change (YTD)</span>
                </div>
                <WaterfallChart
                  dataTestId={'change-ebitda-ytd'}
                  dataValue={'P&L Summary'}
                  formatter={(value) => formatCurrency(value, company.currency)}
                  loading={changeEbitdaYTDLoading}
                  error={changeEbitdaYTDError}
                  data={changeEbitdaYTD}
                  chartInfoCopy={infoCopies.pnlSummary.changeInYtdEBITDAFromLastYear}
                />
              </Fragment>
            </div>
          </ReportSection>
        </div>
        <div style={{width: '50%'}}>
          <ReportSection
            showControls={modifySectionToggle}
            name={'Contributors to EBITDA margin change'}
            addRemoveSectionList={addRemoveSectionList}
            toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
            commentMode={commentModeToggle}
          >
            <div style={{padding: '0 0 1em 1.5em'}}>
                <Fragment>
                  <div className='lineChartHeading'>
                    <span className='lineChartHeadingLabel'>Contributors to EBITDA margin change (YTD)</span>
                  </div>
                  <WaterfallChart
                    dataTestId={`change-ebitda-margin-ytd`}
                    dataValue={'P&L Summary'}
                    formatter={(value) => `${parseFloat(value).toFixed(1)} %`}
                    loading={changeEbitdaMarginYTDLoading}
                    error={changeEbitdaMarginYTDError}
                    data={changeEbitdaMarginYTD}
                    chartInfoCopy={infoCopies.pnlSummary.changeInYTDEBITDAMarginFromLastYear}
                  />
                </Fragment>
            </div>
          </ReportSection>
        </div>
      </div>
      <div style={{display: 'flex'}}>
        <div style={{width: '50%'}}>
          <ReportSection
            showControls={modifySectionToggle}
            name={'Total SG&A difference vs Previous year'}
            addRemoveSectionList={addRemoveSectionList}
            toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
            commentMode={commentModeToggle}
          >
            <div style={{padding: '0 0 1em 1.5em'}}>
                <Fragment>
                  <div className='lineChartHeading'>
                    <span className='lineChartHeadingLabel'>Total SG&A difference vs Previous year</span>
                  </div>
                  <SimpleBarChart
                    dataTestId={'bar-category-difference-ytd'}
                    dataValue={'SG&A'}
                    fullWidth={true}
                    formatter={(value) => formatCurrency(value, company.currency)}
                    loading={categoryDifferenceYTDLoading}
                    error={categoryDifferenceYTDError}
                    data={categoryDifferenceYTDChart}
                    chartInfoCopy={infoCopies.majorInceresesAndDecresesFromLastYearYTD}
                  />
                </Fragment>
            </div>
          </ReportSection>
        </div>
        <div style={{width: '50%'}}>
          <ReportSection
            showControls={modifySectionToggle}
            name={'Total SG&A difference vs Previous year'}
            addRemoveSectionList={addRemoveSectionList}
            toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
            commentMode={commentModeToggle}
          >
            <div style={{padding: '0 0 1em 1.5em'}}>
                <Fragment>
                  <div className='lineChartHeading'>
                    <span className='lineChartHeadingLabel'>Total SG&A difference vs Previous year</span>
                  </div>
                  <SimpleBarChart
                    dataTestId={'bar-category-difference-ytd'}
                    dataValue={'SG&A'}
                    fullWidth={true}
                    formatter={(value) => formatCurrency(value, company.currency)}
                    loading={categoryDifferenceYTDLoading}
                    error={categoryDifferenceYTDError}
                    data={categoryDifferenceYTDChart}
                    chartInfoCopy={infoCopies.majorInceresesAndDecresesFromLastYearYTD}
                  />
                </Fragment>
            </div>
          </ReportSection>
        </div>
      </div>

      {(reportType !== 'Individual Section View') &&
       <Fragment>
         <ReportSection
           showControls={modifySectionToggle}
           name={'Balance Sheet Commentary'}
           addRemoveSectionList={addRemoveSectionList}
           toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
           commentMode={commentModeToggle}
         >
           {<p className={`bs-representation-title`}>Balance Sheet Representation as of&nbsp;
             {selectedDate.month} {selectedDate.year}</p>}
           {
             <div className={`line-charts-wrapper`}>
               <BsRepresentationBarChart
                 dataTestId={`bs-overall-writeup-chart`}
                 dataValue={null}
                 formatter={(value) => formatCurrency(value, company.currency)}
                 loading={bsOverallWriteupLoading}
                 error={bsOverallWriteupError}
                 data={bsOverallWriteup.Representation ? bsOverallWriteup.Representation : null}
                 chartInfoCopy={infoCopies.balanceSheetRepresentation}
               />
               <div style={{padding: '0 2em', flex: '0 0 50%', marginTop: '-3.5em'}}>
                 {bsOverallWriteupLoading &&
                  <div style={{margin: '1em auto 2em auto'}}><Loader dataTestId={`bs-overall-writeup-loading`}
                                                                     type={'pulse'} size={10}/></div>}
                 {!bsOverallWriteupLoading && Object.keys(bsOverallWriteup).length > 0 && showBsCatagoryWriteUp(bsOverallWriteup.WriteUp)}
               </div>
             </div>}
         </ReportSection>
       </Fragment>
      }
      <ReportSection
        showControls={modifySectionToggle}
        name={'CashFlow Commentary'}
        addRemoveSectionList={addRemoveSectionList}
        toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
        commentMode={commentModeToggle}
      >
        <Fragment>
          <div style={{display: 'flex'}}>
            <div style={{flex: '0 0 50%', padding: '0px 0px 1em 1.5em'}}>
              <div className='lineChartHeading'>
                <span className='lineChartHeadingLabel'>CashFlow Generation</span>
              </div>
              <WaterfallChart
                dataTestId={'category-cf-generation-ytd'}
                dataValue={'CF Summary'}
                formatter={(value) => formatCurrency(value, company.currency)}
                loading={categoryCfGenerationYTDLoading}
                error={categoryCfGenerationYTDError}
                data={categoryCfGenerationYTDChart}
                chartInfoCopy={infoCopies.cfSummary.cashflowGenerationYtd}
              />
            </div>
            <div style={{padding: '0 2em', flex: '0 0 50%'}}>
              <div className='lineChartHeading'>
                <div className={`writeup-wrapper`} style={{margin: '0 0'}}>
                  {cashflowGenerationYTDWriteupLoading && <div style={{margin: '1em 0 2em 0'}}>
                    <Loader dataTestId={`cashflow-generation-ytd-writeup-loading`} type={'pulse'} size={10}/></div>}
                  {cashflowGenerationYTDWriteupError &&
                   <ErrorResponse dataTestId={`cashflow-generation-ytd-writeup-error`}
                                  error={cashflowGenerationYTDWriteupError}/>}
                  {!cashflowGenerationYTDWriteupLoading && showWriteup(cashflowGenerationYTDWriteup, 'CF')}
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      </ReportSection>

      <ReportSection
        showControls={modifySectionToggle}
        name={'YTD P&L Commentary VS Previous Year'}
        addRemoveSectionList={addRemoveSectionList}
        toggleCallback={(sectionName, action) => addRemoveSection(sectionName, action)}
        commentMode={commentModeToggle}
      >
        <Fragment>
          <div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>YTD P&L Commentary VS Previous Year</span>
          </div>
          <div style={{display: 'flex'}}>
            <div style={{padding: '0 2em', flex: '0 0 50%'}}>
              <div className='lineChartHeading'>
                <div className={`writeup-wrapper-commentary`} style={{margin: '0 0'}}>
                  {cashflowGenerationYTDWriteupLoading && <div style={{margin: '1em 0 2em 0'}}>
                    <Loader dataTestId={`cashflow-generation-ytd-writeup-loading`} type={'pulse'} size={10}/></div>}
                  {cashflowGenerationYTDWriteupError &&
                   <ErrorResponse dataTestId={`cashflow-generation-ytd-writeup-error`}
                                  error={cashflowGenerationYTDWriteupError}/>}
                  {!cashflowGenerationYTDWriteupLoading && showWriteup(cashflowGenerationYTDWriteup, 'CF')}
                </div>
              </div>
            </div>
            <div style={{padding: '0 2em', flex: '0 0 50%'}}>
              <div className='lineChartHeading'>
                <div className={`writeup-wrapper-commentary`} style={{margin: '0 0'}}>
                  {cashflowGenerationYTDWriteupLoading && <div style={{margin: '1em 0 2em 0'}}>
                    <Loader dataTestId={`cashflow-generation-ytd-writeup-loading`} type={'pulse'} size={10}/></div>}
                  {cashflowGenerationYTDWriteupError &&
                   <ErrorResponse dataTestId={`cashflow-generation-ytd-writeup-error`}
                                  error={cashflowGenerationYTDWriteupError}/>}
                  {!cashflowGenerationYTDWriteupLoading && showWriteup(cashflowGenerationYTDWriteup, 'CF')}
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      </ReportSection>
    </Fragment>
  )
}

export default YTDReportSection;