import React, {useCallback, useEffect, useState} from "react";
import './analysis.css';
import {useHistory } from 'react-router-dom';
import SubHeader from "../subHeader";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import SimplePieChart from "../simple-pie-chart/simple-pie-chart";
import useGet from "../hooks/use-get";
import SimpleLineChart from "../simple-line-chart";
import "../simple-line-chart/simple-line-chart.css";
import HistoricTrendChart from "../historic-trend-chart";
import LineChartDoubleYAxis from "../line-chart-double-y-axis";
import SimpleBarChart from "../simple-bar-chart";
import './analysis.css';
import {
  formatCurrency,
  mapCategoryForDifferencesBarChart, mapEvolution,
  mapHistoricTrend,
  mapLTMPrevChartData,
  mapCategoryForPieChart, mapPnlSummaryEvolution, waterfallChartMap, showWriteupPnL
} from '../analysis-helper';
import Loader from "../reusables/Loader";
import ErrorResponse from "../error-response/error-response";
import PnlSummary from "./pnlSummary";
import infoCopies from "../chart-info-copies";
import withErrorBoundary from "../error/ErrorBoundary";

function AnalysisPnl() {
  const history = useHistory();
  const dispatch = useDispatch();

  const company = useSelector(({selectedCompany}) => selectedCompany);
  const selectedDate = useSelector(({endDate}) => endDate);
  const startDate = useSelector(({startDate }) => startDate);

  const paramSection = useSelector(({selectedNavigation:{sheet}}) => sheet);
  const paramCategory = useSelector(({selectedNavigation:{category}}) => category);
  const paramSubCategory = useSelector(({selectedNavigation:{subCategory}}) => subCategory);

  const [pieMonthly,setPieMonthly]=useState({loading:true, error:null,data:{}});
  const [pieYTD,setPieYTD]=useState({loading:true, error:null,data:{}});
  const [pieLTM,setPieLTM]=useState({loading:true, error:null,data:{}});
  const [lTMPrevChart,setLTMPrevChart]= useState([]);
  const [historicTrendChart,setHistoricTrendChart]=useState([]);

  const [monthlyEvolutionChart,setMonthlyEvolutionChart]=useState([]);
  const [ltmEvolutionChart,setLtmEvolutionChart]=useState([]);
  const [categoryMonthlyChart,setCategoryMonthlyChart]=useState({});
  const [categoryPieLTMChart,setCategoryPieLTMChart]=useState({});
  const [categoryPieYTDChart,setCategoryPieYTDChart]=useState({});
  const [categoryDifferenceMonthlyChart,setCategoryDifferenceMonthlyChart]=useState({});
  const [categoryDifferenceYTDChart,setCategoryDifferenceYTDChart]=useState({});
  const [grossProfitEvolutionMonth,setGrossProfitEvolutionMonth]=useState({});
  const [grossProfitEvolutionLTM,setGrossProfitEvolutionLTM]=useState({});

  const [netIncomeEvolutionMonth,setNetIncomeEvolutionMonth]=useState({});
  const [netIncomeEvolutionLTM,setNetIncomeEvolutionLTM]=useState({});

  const [salesEvolutionMonth,setSalesEvolutionMonth]=useState({});
  const [salesEvolutionLTM,setSalesEvolutionLTM]=useState({});

  const [costOfSalesEvolutionMonth,setCostOfSalesEvolutionMonth]=useState({});
  const [costOfSalesEvolutionLTM,setCostOfSalesEvolutionLTM]=useState({});

  const [sgnaEvolutionMonth,setSgnaEvolutionMonth]=useState({});
  const [sgnaEvolutionLTM,setSgnaEvolutionLTM]=useState({});

  const [overallValueMonth,setOverallValueMonth]=useState('');
  const [overallValueLTM,setOverallValueLTM]=useState('');
  const [overallValueYTD,setOverallValueYTD]=useState('');

  const [summaryMonth,setSummaryMonth]=useState({});
  const [summaryLTM,setSummaryLTM]=useState({});
  const [summaryYTD,setSummaryYTD]=useState({});

  const [pnlSubCatWriteup,setPnlSubCatWriteup]=useState({});
  const [pnlCatWriteup,setPnlCatWriteup]=useState({});

  const [changeEbitdaMonth,setChangeEbitdaMonth]=useState([]);
  const [changeEbitdaYTD,setChangeEbitdaYTD]=useState([]);

  const [changeEbitdaMarginMonth,setChangeEbitdaMarginMonth]=useState([]);
  const [changeEbitdaMarginYTD,setChangeEbitdaMarginYTD]=useState([]);

 const [overallEbitdaEvolutionMonth,setOverallEbitdaEvolutionMonth]=useState([]);
  const [overallEbitdaEvolutionLTM,setOverallEbitdaEvolutionLTM]=useState([]);

  const [overallEbitdaContriWriteup,setOverallEbitdaContriWriteup]=useState([]);


  const setPieMonthlyState = useCallback((data) => {
    setPieMonthly(prevState => ({ ...prevState, ...data }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pieMonthly]);

  const setPieYTDState = useCallback((data) => {
    setPieYTD(prevState => ({ ...prevState, ...data }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pieYTD]);

  const setPieLTMState = useCallback((data) => {
    setPieLTM(prevState => ({ ...prevState, ...data }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pieLTM]);

  const getDate=(date)=> `${date.month} ${date.year.slice(-2)}`

  const [overallEbitdaContriWriteupResponse, overallEbitdaContriWriteupLoading, overallEbitdaContriWriteupError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/writeup/overall/pnl?companyId=${company.companyId}`+
         `&date=${getDate(selectedDate)}`,
    skip:paramCategory !=='P&L Summary'});


  const [summaryMonthResponse, summaryMonthLoading, summaryMonthError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/summary/pnl?companyId=${company.companyId}`+
         `&date=${getDate(selectedDate)}&breakdown=MONTH`,
    skip:paramCategory !=='P&L Summary'});

  const [overallEbitdaEvolutionMonthResponse, overallEbitdaEvolutionMonthLoading, overallEbitdaEvolutionMonthError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/monthly-evolution/derived-category/pnl?companyId=${company.companyId}`+
         `&category=EBITDA`+
         `&date=${getDate(selectedDate)}`,
    skip:paramCategory !=='P&L Summary'});

  const [overallEbitdaEvolutionLTMResponse, overallEbitdaEvolutionLTMLoading, overallEbitdaEvolutionLTMError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/ltm-evolution/derived-category/pnl?companyId=${company.companyId}`+
         `&category=EBITDA`+
         `&date=${getDate(selectedDate)}`,
    skip:paramCategory !=='P&L Summary'});

  const [summaryLTMResponse, summaryLTMLoading, summaryLTMError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/summary/pnl?companyId=${company.companyId}`+
         `&date=${getDate(selectedDate)}&breakdown=LTM`,
    skip:paramCategory !=='P&L Summary'});

  const [summaryYTDResponse, summaryYTDLoading, summaryYTDError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/summary/pnl?companyId=${company.companyId}`+
    `&date=${getDate(selectedDate)}&breakdown=YTD`,
    skip:paramCategory !=='P&L Summary'});

  const [overallValueMonthResponse, overallValueMonthLoading, overallValueMonthError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/absolute/derived-category/pnl?companyId=${company.companyId}`+
    `&category=EBITDA&date=${getDate(selectedDate)}&breakdown=MONTH`,
    skip:paramCategory !=='P&L Summary'});

  const [overallValueYTDResponse, overallValueYTDLoading, overallValueYTDError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/absolute/derived-category/pnl?companyId=${company.companyId}`+
         `&category=EBITDA&date=${getDate(selectedDate)}&breakdown=YTD`,
    skip:paramCategory !=='P&L Summary'});

  const [overallValueLTMResponse, overallValueLTMLoading, overallValueLTMError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/absolute/derived-category/pnl?companyId=${company.companyId}`+
         `&category=EBITDA&date=${getDate(selectedDate)}&breakdown=LTM`,
    skip:paramCategory !=='P&L Summary'});

  const [lTMPrevChartResponse, lTMPrevChartLoading, lTMPrevChartError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/ltm-vs-previous${paramSubCategory?'':'/category'}?`+
         `companyId=${company.companyId}${paramSubCategory?`&subCategory=${paramSubCategory.replace(/&/ig,'%26')}`:''}`+
         `&category=${paramCategory.replace(/&/ig,'%26')}&date=${getDate(selectedDate)}&sheet=PNL`,
    skip:paramCategory ==='P&L Summary'});

  const [historicTrendChartResponse, historicTrendChartLoading, historicTrendChartError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/historic-data${paramSubCategory?'':'/category/summed-over-month'}?`+
         `companyId=${company.companyId}${paramSubCategory?`&subCategory=${paramSubCategory.replace(/&/ig,'%26')}`:''}`+
         `&category=${paramCategory.replace(/&/ig,'%26')}&date=${getDate(selectedDate)}&sheet=PNL`,
    skip:paramCategory ==='P&L Summary'});

  const [monthlyEvolutionChartResponse, monthlyEvolutionChartLoading, monthlyEvolutionChartError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/monthly-evolution${paramSubCategory?'':'/category'}/pnl?companyId=${company.companyId}`+
         `&subCategory=${paramSubCategory? paramSubCategory.replace(/&/ig,'%26'):''}`+
         `&category=${paramCategory.replace(/&/ig,'%26')}&date=${getDate(selectedDate)}`,
    skip:paramCategory === 'P&L Summary'});

  const [ltmEvolutionChartResponse, ltmEvolutionChartLoading, ltmEvolutionChartError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/ltm-evolution${paramSubCategory?'':'/category'}/pnl?`+
         `companyId=${company.companyId}&subCategory=${paramSubCategory? paramSubCategory.replace(/&/ig,'%26'):''}`+
         `&category=${paramCategory.replace(/&/ig,'%26')}&date=${getDate(selectedDate)}`,
    skip:paramCategory === 'P&L Summary'});

  const [categoryMonthlyResponse, categoryMonthlyLoading, categoryMonthlyError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/contributors/pnl?companyId=${company.companyId}`+
         `&category=${paramCategory.replace(/&/ig,'%26')}&date=${getDate(selectedDate)}&breakdown=MONTH`,
    skip: paramSubCategory !== null || paramCategory === 'P&L Summary'});

  const [categoryPieLTMResponse, categoryPieLTMLoading, categoryPieLTMError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/contributors/pnl?companyId=${company.companyId}`+
         `&category=${paramCategory.replace(/&/ig,'%26')}&date=${getDate(selectedDate)}&breakdown=LTM`,
    skip: paramSubCategory !== null || paramCategory === 'P&L Summary'});

  const [categoryPieYTDResponse, categoryPieYTDLoading, categoryPieYTDError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/contributors/pnl?companyId=${company.companyId}`+
         `&category=${paramCategory.replace(/&/ig,'%26')}&date=${getDate(selectedDate)}&breakdown=YTD`,
    skip: paramSubCategory !== null || paramCategory === 'P&L Summary'});

  const [categoryDifferenceMonthlyResponse, categoryDifferenceMonthlyLoading, categoryDifferenceMonthlyError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/differences/pnl?companyId=${company.companyId}`+
         `&category=${paramCategory.replace(/&/ig,'%26')}&date=${getDate(selectedDate)}&breakdown=MONTH`,
    skip: paramSubCategory !== null || paramCategory === 'P&L Summary'});

  const [categoryDifferenceYTDResponse, categoryDifferenceYTDLoading, categoryDifferenceYTDError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/differences/pnl?companyId=${company.companyId}`+
         `&category=${paramCategory.replace(/&/ig,'%26')}&date=${getDate(selectedDate)}&breakdown=YTD`,
    skip: paramSubCategory !== null || paramCategory === 'P&L Summary'});

  const [pnlSubCatWriteupResponse, pnlSubCatWriteupLoading, pnlSubCatWriteupError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/writeup/subcategory/pnl?companyId=${company.companyId}`+
         `&subCategory=${paramSubCategory?.replace(/&/ig,'%26')}&date=${getDate(selectedDate)}`,
    skip:paramCategory === 'P&L Summary' || paramSubCategory === null});

  const [pnlCatWriteupResponse, pnlCatWriteupLoading, pnlCatWriteupError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/writeup/category/pnl?companyId=${company.companyId}`+
         `&category=${paramCategory.replace(/&/ig,'%26')}`+
         `&date=${getDate(selectedDate)}`,
    skip:paramCategory === 'P&L Summary' || paramSubCategory !== null});

  const [grossProfitEvolutionMonthResponse, grossProfitEvolutionMonthLoading, grossProfitEvolutionMonthError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/monthly-evolution/derived-category/pnl?companyId=${company.companyId}`+
         `&category=Gross%20Profit&date=${getDate(selectedDate)}`,
    skip:paramCategory !== 'P&L Summary'});

  const [grossProfitEvolutionLTMResponse, grossProfitEvolutionLTMLoading, grossProfitEvolutionLTMError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/ltm-evolution/derived-category/pnl?companyId=${company.companyId}`+
         `&category=Gross%20Profit&date=${getDate(selectedDate)}`,
    skip:paramCategory !== 'P&L Summary'});

  const [netIncomeEvolutionMonthResponse, netIncomeEvolutionMonthLoading, netIncomeEvolutionMonthError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/monthly-evolution/derived-category/pnl?companyId=${company.companyId}`+
         `&category=Net%20Income&date=${getDate(selectedDate)}`,
    skip:paramCategory !== 'P&L Summary'});

  const [netIncomeEvolutionLTMResponse, netIncomeEvolutionLTMLoading, netIncomeEvolutionLTMError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/ltm-evolution/derived-category/pnl?companyId=${company.companyId}`+
         `&category=Net%20Income&date=${getDate(selectedDate)}`,
    skip:paramCategory !== 'P&L Summary'});

  const [salesEvolutionMonthResponse, salesEvolutionMonthLoading, salesEvolutionMonthError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/monthly-evolution/category/pnl?companyId=${company.companyId}`+
         `&category=Sales&date=${getDate(selectedDate)}`});

  const [salesEvolutionLTMResponse, salesEvolutionLTMLoading, salesEvolutionLTMError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/ltm-evolution/category/pnl?companyId=${company.companyId}`+
         `&category=Sales&date=${getDate(selectedDate)}`});

  const [costOfSalesEvolutionMonthResponse, costOfSalesEvolutionMonthLoading, costOfSalesEvolutionMonthError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/monthly-evolution/category/pnl?companyId=${company.companyId}`+
         `&category=Cost%20of%20Sales&date=${getDate(selectedDate)}`});

  const [costOfSalesEvolutionLTMResponse, costOfSalesEvolutionLTMLoading, costOfSalesEvolutionLTMError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/ltm-evolution/category/pnl?companyId=${company.companyId}`+
         `&category=Cost%20of%20Sales&date=${getDate(selectedDate)}`} );

  const [sgnaEvolutionMonthResponse, sgnaEvolutionMonthLoading, sgnaEvolutionMonthError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/monthly-evolution/category/pnl?companyId=${company.companyId}`+
         `&category=SG%26A&date=${getDate(selectedDate)}`});

  const [sgnaEvolutionLTMResponse, sgnaEvolutionLTMLoading, sgnaEvolutionLTMError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/ltm-evolution/category/pnl?companyId=${company.companyId}`+
         `&category=SG%26A&date=${getDate(selectedDate)}`});

  const [changeEbitdaMonthResponse, changeEbitdaMonthLoading, changeEbitdaMonthError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/change/ebitda?companyId=${company.companyId}`+
         `&category=SG%26A&date=${getDate(selectedDate)}&breakdown=MONTH`});

  const [changeEbitdaYTDResponse, changeEbitdaYTDLoading, changeEbitdaYTDError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/change/ebitda?companyId=${company.companyId}`+
         `&category=SG%26A&date=${getDate(selectedDate)}&breakdown=YTD`});

  const [changeEbitdaMarginMonthResponse, changeEbitdaMarginMonthLoading, changeEbitdaMarginMonthError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/change/ebitda-margin?companyId=${company.companyId}`+
         `&category=SG%26A&date=${getDate(selectedDate)}&breakdown=MONTH`});

  const [changeEbitdaMarginYTDResponse, changeEbitdaMarginYTDLoading, changeEbitdaMarginYTDError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/change/ebitda-margin?companyId=${company.companyId}`+
         `&category=SG%26A&date=${getDate(selectedDate)}&breakdown=YTD`});

  async function getChartData({
                                date,
                                category,
                                subCategory,
                                breakdownType,
                                companyId
                              }) {
    const headers = {
      'Content-Type': 'text/plain;charset=UTF-8',
    };

    let parameters = {
      date,
      category,
      subCategory,
      breakdownType,
      companyId,
    };
    return axios(`${process.env.REACT_APP_BACKEND_BASE_URL}/contribution/pnl`, {
      method: "post",
      data: parameters,
      withCredentials: true,
      headers:headers
    })
      .then(response => response.data)
      .catch(error => {throw error.response});
  }

  const dataMapper=(obj)=>{
    const {absolute,contribution} =obj;
    return ({
      absolute: parseInt(absolute),
      contribution: contribution.map((item) => ({name: item.name, value: parseInt(item.value)}))
    })
  }

  function getPieMonthlyData() {
    setPieMonthlyState({ loading:true , error:false});
    getChartData({
      date: getDate(selectedDate),
      companyId: company.companyId,
      category: paramCategory,
      subCategory: paramSubCategory,
      breakdownType: 'MONTH',
    }).then(
      response => {
        setPieMonthlyState({ loading:false, data:dataMapper(response)});
      }
    ).catch(
      error => {
        if(error && error.data === 'Given sub-category is not present in company data'){
          history.push({
            pathname: '/insights',
          });
          dispatch(
            {
              type:'urlChange',
              payload:{
                section: 'insights',
                sheet:null,
                category:null,
                subCategory: null,
              }});
        }
        else {
          setPieMonthlyState({loading: false, error: {response:error}})
        }
      }
    );
  }

  function getPieYTDData() {
    setPieYTDState({ loading:true , error:false});
    getChartData({
      date: getDate(selectedDate),
      companyId: company.companyId,
      category: paramCategory,
      subCategory: paramSubCategory,
      breakdownType: 'YTD',
    }).then(
      response => {
        setPieYTDState({ loading:false, data:dataMapper(response)});
      }
    ).catch(
      error => {
        if(error && error.data === 'Given sub-category is not present in company data'){
          history.push({
            pathname: '/insights',
          });
          dispatch(
            {
              type:'urlChange',
              payload:{
                section: 'insights',
                sheet:null,
                category:null,
                subCategory: null,
              }});
        }
        else {
          setPieYTDState({loading: false, error: {response:error}})
        }
      }
    );
  }

  function getPieLTMData() {
    setPieLTMState({ loading:true , error:false});
    getChartData({
      date: getDate(selectedDate),
      companyId: company.companyId,
      category: paramCategory,
      subCategory: paramSubCategory,
      breakdownType: 'LTM',
    }).then(
      response => {
        setPieLTMState({ loading:false, data:dataMapper(response)});
      }
    ).catch(
      error => {
        if(error && error.data === 'Given sub-category is not present in company data'){
          history.push({
            pathname: '/insights',
          });
          dispatch(
            {
              type:'urlChange',
              payload:{
                section: 'insights',
                sheet:null,
                category:null,
                subCategory: null,
              }});
        }
        else {
          setPieLTMState({loading: false, error: {response:error}})
        }
      }
    );
  }
  useEffect(()=> {
    if(paramSubCategory !== null && paramCategory !== 'P&L Summary'){
      getPieMonthlyData();
      getPieYTDData();
      getPieLTMData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedDate,startDate,company,paramSection, paramCategory,paramSubCategory])

  useEffect(()=>{
    if(paramCategory === 'P&L Summary') {
      if (!changeEbitdaMonthLoading && changeEbitdaMonthResponse) {
        setChangeEbitdaMonth(waterfallChartMap(changeEbitdaMonthResponse));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[changeEbitdaMonthResponse, changeEbitdaMonthLoading, changeEbitdaMonthError,paramSection,paramCategory,paramSubCategory]);

  useEffect(()=>{
    if(paramCategory === 'P&L Summary') {
      if (!overallEbitdaContriWriteupLoading && overallEbitdaContriWriteupResponse) {
        setOverallEbitdaContriWriteup(overallEbitdaContriWriteupResponse);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[overallEbitdaContriWriteupResponse,
    overallEbitdaContriWriteupLoading,
    overallEbitdaContriWriteupError,
    paramSection,
    paramCategory,
    paramSubCategory]);

  useEffect(()=>{
    if(paramCategory === 'P&L Summary') {
      if (!changeEbitdaYTDLoading && changeEbitdaYTDResponse) {
        setChangeEbitdaYTD(waterfallChartMap(changeEbitdaYTDResponse));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[changeEbitdaYTDResponse, changeEbitdaYTDLoading, changeEbitdaYTDError,paramSection,paramCategory,paramSubCategory])

  useEffect(()=>{
      if(paramCategory === 'P&L Summary') {
        if (!overallEbitdaEvolutionMonthLoading && overallEbitdaEvolutionMonthResponse) {
          setOverallEbitdaEvolutionMonth(mapPnlSummaryEvolution(overallEbitdaEvolutionMonthResponse,'EBITDA'));
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[
      overallEbitdaEvolutionMonthResponse,
    overallEbitdaEvolutionMonthLoading,
    overallEbitdaEvolutionMonthError,
    paramSection,
    paramCategory,
    paramSubCategory])

  useEffect(()=>{
      if(paramCategory === 'P&L Summary') {
        if (!overallEbitdaEvolutionLTMLoading && overallEbitdaEvolutionLTMResponse) {
          setOverallEbitdaEvolutionLTM(mapPnlSummaryEvolution(overallEbitdaEvolutionLTMResponse,'EBITDA'));
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[
      overallEbitdaEvolutionLTMResponse,
    overallEbitdaEvolutionLTMLoading,
    overallEbitdaEvolutionLTMError,
    paramSection,
    paramCategory,
    paramSubCategory])

  useEffect(()=>{
    if(paramCategory === 'P&L Summary') {
      if (!changeEbitdaMarginMonthLoading && (changeEbitdaMarginMonthResponse && Object.keys(changeEbitdaMarginMonthResponse).length > 0)) {
        setChangeEbitdaMarginMonth(waterfallChartMap(changeEbitdaMarginMonthResponse));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    changeEbitdaMarginMonthResponse,
    changeEbitdaMarginMonthLoading,
    changeEbitdaMarginMonthError,
    paramSection,
    paramCategory,
    paramSubCategory
  ]);

  useEffect(()=>{
    if(paramCategory === 'P&L Summary') {
      if (!changeEbitdaMarginYTDLoading && (changeEbitdaMarginYTDResponse && Object.keys(changeEbitdaMarginYTDResponse).length > 0)) {
        setChangeEbitdaMarginYTD(waterfallChartMap(changeEbitdaMarginYTDResponse));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[changeEbitdaMarginYTDResponse, changeEbitdaMarginYTDLoading, changeEbitdaMarginYTDError,paramSection,paramCategory,paramSubCategory])


  useEffect(()=>{
    if(paramCategory === 'P&L Summary') {
      if (!summaryMonthLoading && (summaryMonthResponse && Object.keys(summaryMonthResponse).length > 0)) {
        setSummaryMonth(summaryMonthResponse);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[summaryMonthResponse, summaryMonthLoading, summaryMonthError,paramSection,paramCategory,paramSubCategory])
  useEffect(()=>{
    if(paramCategory === 'P&L Summary') {
      if (!summaryYTDLoading && ( summaryYTDResponse && Object.keys(summaryYTDResponse).length > 0)) {
        setSummaryYTD(summaryYTDResponse);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[summaryYTDResponse, summaryYTDLoading, summaryYTDError,paramSection,paramCategory,paramSubCategory])

  useEffect(()=>{
    if(paramCategory === 'P&L Summary') {
      if (!summaryLTMLoading && (summaryLTMResponse && Object.keys(summaryLTMResponse).length > 0)) {
        setSummaryLTM(summaryLTMResponse);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[summaryLTMResponse, summaryLTMLoading, summaryLTMError,paramSection,paramCategory,paramSubCategory])

  useEffect(()=>{
    if(paramCategory === 'P&L Summary') {
      if (!overallValueMonthLoading && (overallValueMonthResponse)) {
        setOverallValueMonth(overallValueMonthResponse);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[overallValueMonthResponse, overallValueMonthLoading, overallValueMonthError,paramSection,paramCategory,paramSubCategory])

  useEffect(()=>{
    if(paramCategory === 'P&L Summary') {
      if (!overallValueYTDLoading && (overallValueYTDResponse)) {
        setOverallValueYTD(overallValueYTDResponse);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[overallValueYTDResponse, overallValueYTDLoading, overallValueYTDError,paramSection,paramCategory,paramSubCategory])

  useEffect(()=>{
    if(paramCategory === 'P&L Summary') {
      if (!overallValueLTMLoading && (overallValueLTMResponse)) {
        setOverallValueLTM(overallValueLTMResponse);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[overallValueLTMResponse, overallValueLTMLoading, overallValueLTMError,paramSection,paramCategory,paramSubCategory])

  useEffect(()=>{
    if(!lTMPrevChartLoading && (lTMPrevChartResponse && Object.keys(lTMPrevChartResponse).length > 0)){
      setLTMPrevChart(mapLTMPrevChartData(lTMPrevChartResponse));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[lTMPrevChartResponse, lTMPrevChartLoading, lTMPrevChartError,paramSection,paramCategory,paramSubCategory])

  useEffect(()=>{
    if(paramCategory === 'P&L Summary') {
      if (!grossProfitEvolutionMonthLoading && (grossProfitEvolutionMonthResponse && Object.keys(grossProfitEvolutionMonthResponse).length > 0)) {
        setGrossProfitEvolutionMonth(mapPnlSummaryEvolution(grossProfitEvolutionMonthResponse,'Gross Profit'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    grossProfitEvolutionMonthResponse,
    grossProfitEvolutionMonthLoading,
    grossProfitEvolutionMonthError,
    paramSection,
    paramCategory,
    paramSubCategory])

  useEffect(()=>{
    if(paramCategory === 'P&L Summary') {
      if (!grossProfitEvolutionLTMLoading && (grossProfitEvolutionLTMResponse && Object.keys(grossProfitEvolutionLTMResponse).length > 0)) {
        setGrossProfitEvolutionLTM(mapPnlSummaryEvolution(grossProfitEvolutionLTMResponse,'Gross Profit'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    grossProfitEvolutionLTMResponse,
    grossProfitEvolutionLTMLoading,
    grossProfitEvolutionLTMError,
    paramSection,
    paramCategory,
    paramSubCategory])

  useEffect(()=>{
    if(paramCategory === 'P&L Summary') {
      if (!netIncomeEvolutionMonthLoading && (netIncomeEvolutionMonthResponse && Object.keys(netIncomeEvolutionMonthResponse).length > 0)) {
        setNetIncomeEvolutionMonth(mapPnlSummaryEvolution(netIncomeEvolutionMonthResponse,'Net Income'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    netIncomeEvolutionMonthResponse,
    netIncomeEvolutionMonthLoading,
    netIncomeEvolutionMonthError,
    paramSection,
    paramCategory,
    paramSubCategory])

  useEffect(()=>{
    if(paramCategory === 'P&L Summary') {
      if (!netIncomeEvolutionLTMLoading && (netIncomeEvolutionLTMResponse && Object.keys(netIncomeEvolutionLTMResponse).length > 0)) {
        setNetIncomeEvolutionLTM(mapPnlSummaryEvolution(netIncomeEvolutionLTMResponse,'Net Income'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[netIncomeEvolutionLTMResponse, netIncomeEvolutionLTMLoading, netIncomeEvolutionLTMError,paramSection,paramCategory,paramSubCategory])

  useEffect(()=>{
    if(paramCategory === 'P&L Summary') {
      if (!salesEvolutionMonthLoading && (salesEvolutionMonthResponse && Object.keys(salesEvolutionMonthResponse).length > 0)) {
        setSalesEvolutionMonth(mapPnlSummaryEvolution(salesEvolutionMonthResponse,'Sales'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[salesEvolutionMonthResponse, salesEvolutionMonthLoading, salesEvolutionMonthError,paramSection,paramCategory,paramSubCategory])

  useEffect(()=>{
    if(paramCategory === 'P&L Summary') {
      if (!salesEvolutionLTMLoading && (salesEvolutionLTMResponse && Object.keys(salesEvolutionLTMResponse).length > 0)) {
        setSalesEvolutionLTM(mapPnlSummaryEvolution(salesEvolutionLTMResponse,'Sales'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[salesEvolutionLTMResponse, salesEvolutionLTMLoading, salesEvolutionLTMError,paramSection,paramCategory,paramSubCategory])

  useEffect(()=>{
    if(paramCategory === 'P&L Summary') {
      if (!costOfSalesEvolutionMonthLoading && (costOfSalesEvolutionMonthResponse && Object.keys(costOfSalesEvolutionMonthResponse).length > 0)) {
        setCostOfSalesEvolutionMonth(mapPnlSummaryEvolution(costOfSalesEvolutionMonthResponse,'Cost of Sales'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    costOfSalesEvolutionMonthResponse,
    costOfSalesEvolutionMonthLoading,
    costOfSalesEvolutionMonthError,
    paramSection,
    paramCategory,
    paramSubCategory
  ])

  useEffect(()=>{
    if(paramCategory === 'P&L Summary') {
      if (!costOfSalesEvolutionLTMLoading && (costOfSalesEvolutionLTMResponse && Object.keys(costOfSalesEvolutionLTMResponse).length > 0)) {
        setCostOfSalesEvolutionLTM(mapPnlSummaryEvolution(costOfSalesEvolutionLTMResponse,'Cost of Sales'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    costOfSalesEvolutionLTMResponse,
    costOfSalesEvolutionLTMLoading,
    costOfSalesEvolutionLTMError,
    paramSection,
    paramCategory,
    paramSubCategory])

  useEffect(()=>{
    if(paramCategory === 'P&L Summary') {
      if (!sgnaEvolutionMonthLoading && (sgnaEvolutionMonthResponse && Object.keys(sgnaEvolutionMonthResponse).length > 0)) {
        setSgnaEvolutionMonth(mapPnlSummaryEvolution(sgnaEvolutionMonthResponse,'SG&A'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[sgnaEvolutionMonthResponse, sgnaEvolutionMonthLoading, sgnaEvolutionMonthError,paramSection,paramCategory,paramSubCategory])

  useEffect(()=>{
    if(paramCategory === 'P&L Summary') {
      if (!sgnaEvolutionLTMLoading && (sgnaEvolutionLTMResponse && Object.keys(sgnaEvolutionLTMResponse).length > 0)) {
        setSgnaEvolutionLTM(mapPnlSummaryEvolution(sgnaEvolutionLTMResponse,'SG&A'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[sgnaEvolutionLTMResponse, sgnaEvolutionLTMLoading, sgnaEvolutionLTMError,paramSection,paramCategory,paramSubCategory])

  useEffect(()=>{
    if(!historicTrendChartLoading && (historicTrendChartResponse && Object.keys(historicTrendChartResponse).length > 0)){
      setHistoricTrendChart(mapHistoricTrend(historicTrendChartResponse));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[historicTrendChartLoading, historicTrendChartResponse, historicTrendChartError,paramSection,paramCategory,paramSubCategory])

  useEffect(()=>{
    if(!monthlyEvolutionChartLoading && (monthlyEvolutionChartResponse && Object.keys(monthlyEvolutionChartResponse).length > 0)){
      setMonthlyEvolutionChart(mapEvolution(monthlyEvolutionChartResponse,paramSubCategory,paramCategory,'pnl'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[monthlyEvolutionChartLoading, monthlyEvolutionChartResponse, monthlyEvolutionChartError,paramSection,paramCategory,paramSubCategory])

  useEffect(()=> {
      if (!ltmEvolutionChartLoading && (ltmEvolutionChartResponse && Object.keys(ltmEvolutionChartResponse).length > 0)) {
        setLtmEvolutionChart(mapEvolution(ltmEvolutionChartResponse,paramSubCategory,paramCategory,'pnl'));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  [ltmEvolutionChartLoading, ltmEvolutionChartResponse, ltmEvolutionChartError,paramSection,paramCategory,paramSubCategory]);

  useEffect(()=>{
    if(paramSubCategory === null) {
      if (!categoryMonthlyLoading && (categoryMonthlyResponse && Object.keys(categoryMonthlyResponse).length > 0)) {
        setCategoryMonthlyChart(mapCategoryForPieChart(categoryMonthlyResponse));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[categoryMonthlyLoading, categoryMonthlyResponse, categoryMonthlyError,paramSection,paramCategory]);

  useEffect(()=>{
    if(paramSubCategory === null) {
      if (!categoryPieLTMLoading && (categoryPieLTMResponse && Object.keys(categoryPieLTMResponse).length > 0)) {
        setCategoryPieLTMChart(mapCategoryForPieChart(categoryPieLTMResponse));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[categoryPieLTMLoading, categoryPieLTMResponse, categoryPieLTMError,paramSection,paramCategory]);

  useEffect(()=>{
    if(paramSubCategory === null) {
      if (!categoryPieYTDLoading && (categoryPieYTDResponse && Object.keys(categoryPieYTDResponse).length > 0)) {
        setCategoryPieYTDChart(mapCategoryForPieChart(categoryPieYTDResponse));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[categoryPieYTDLoading, categoryPieYTDResponse, categoryPieYTDError,paramSection,paramCategory]);

  useEffect(()=>{
    if(paramSubCategory === null) {
      if (!categoryDifferenceMonthlyLoading && (categoryDifferenceMonthlyResponse && Object.keys(categoryDifferenceMonthlyResponse).length > 0)) {
        setCategoryDifferenceMonthlyChart(mapCategoryForDifferencesBarChart(categoryDifferenceMonthlyResponse));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[categoryDifferenceMonthlyLoading, categoryDifferenceMonthlyResponse, categoryDifferenceMonthlyError,paramSection,paramCategory]);

  useEffect(()=>{
    if(paramSubCategory === null) {
      if (!categoryDifferenceYTDLoading && (categoryDifferenceYTDResponse && Object.keys(categoryDifferenceYTDResponse).length > 0)) {
        setCategoryDifferenceYTDChart(mapCategoryForDifferencesBarChart(categoryDifferenceYTDResponse));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[categoryDifferenceYTDLoading, categoryDifferenceYTDResponse, categoryDifferenceYTDError,paramSection,paramCategory]);

  useEffect(()=>{
    if(paramSubCategory !== null) {
      if (!pnlSubCatWriteupLoading && (Object.keys(pnlSubCatWriteupResponse).length > 0)) {
        setPnlSubCatWriteup(pnlSubCatWriteupResponse);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pnlSubCatWriteupLoading, pnlSubCatWriteupResponse, pnlSubCatWriteupError,paramSection,paramCategory]);

  useEffect(()=>{
    if(paramSubCategory === null) {
      if (!pnlCatWriteupLoading && (Object.keys(pnlCatWriteupResponse).length > 0)) {
        setPnlCatWriteup(pnlCatWriteupResponse);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pnlCatWriteupLoading, pnlCatWriteupResponse, pnlCatWriteupError,paramSection,paramCategory]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="content-wrapper">
      <SubHeader text={'Analysis as of'}/>
      <h5 className='page-title'>{`${paramSection !== undefined && paramSubCategory ? paramSubCategory : paramCategory}`}</h5>
      <div className="analysisContainer">
        {
          paramCategory === 'P&L Summary' &&
          <PnlSummary props={{
         paramCategory,
         overallValueMonthLoading,
        overallValueMonth,
        company,
        overallValueMonthError,
        overallValueYTDLoading,
        overallValueYTDError,
        overallValueYTD,
        overallValueLTM,
        overallValueLTMLoading,
        overallValueLTMError,
        summaryMonth,
        summaryMonthLoading,
        summaryMonthError,
        summaryYTD,
        summaryYTDLoading,
        summaryYTDError,
        summaryLTM,
        summaryLTMLoading,
        summaryLTMError,
        changeEbitdaMonthLoading,
        changeEbitdaMonthError,
        changeEbitdaMonth,
        changeEbitdaYTDLoading,
        changeEbitdaYTDError,
        changeEbitdaYTD,
        overallEbitdaContriWriteupLoading,
        overallEbitdaContriWriteupError,
        overallEbitdaContriWriteup,
        overallEbitdaEvolutionMonthLoading,
        overallEbitdaEvolutionMonthError,
        overallEbitdaEvolutionMonth,
        changeEbitdaMarginMonthLoading,
        changeEbitdaMarginMonthError,
        changeEbitdaMarginMonth,
        changeEbitdaMarginYTDLoading,
        changeEbitdaMarginYTDError,
        changeEbitdaMarginYTD,
        overallEbitdaEvolutionLTMLoading,
        overallEbitdaEvolutionLTMError,
        overallEbitdaEvolutionLTM,
        salesEvolutionMonthLoading,
        salesEvolutionMonthError,
        salesEvolutionMonth,
        salesEvolutionLTMLoading,
        salesEvolutionLTMError,
        salesEvolutionLTM,
        costOfSalesEvolutionMonth,
        costOfSalesEvolutionMonthError,
        costOfSalesEvolutionMonthLoading,
        costOfSalesEvolutionLTMLoading,
        costOfSalesEvolutionLTMError,
        costOfSalesEvolutionLTM,
        grossProfitEvolutionMonthLoading,
        grossProfitEvolutionMonthError,
        grossProfitEvolutionMonth,
        grossProfitEvolutionLTMLoading,
        grossProfitEvolutionLTMError,
        grossProfitEvolutionLTM,
        sgnaEvolutionMonthLoading,
        sgnaEvolutionMonthError,
        sgnaEvolutionMonth,
        sgnaEvolutionLTMLoading,
        sgnaEvolutionLTMError,
        sgnaEvolutionLTM,
        netIncomeEvolutionMonthLoading,
        netIncomeEvolutionMonthError,
        netIncomeEvolutionMonth,
        netIncomeEvolutionLTMLoading,
        netIncomeEvolutionLTMError,
        netIncomeEvolutionLTM
       }}/>
        }
        { paramSubCategory !== null && paramCategory !== 'P&L Summary' &&
        <div className='pieChartContainer'>
          <div className={`pieChart`}>
            <div style={{fontSize: '1em'}} className='pieChartHeading'>
              <span className='pieChartHeadingLabel'>Month {paramSubCategory? paramSubCategory:paramCategory}
              <span className={`pieChartHeadingLabelAmount`}>{!pieMonthly.loading && !pieMonthly.error && (pieMonthly.data.absolute >= 0 ?
                formatCurrency(pieMonthly.data.absolute,company.currency) :
                <span style={{color:'red'}}>{formatCurrency(pieMonthly.data.absolute,company.currency)}</span>)}
              </span>
              </span>
            </div>
            <div style={{fontSize: '1em'}} className='pieChartHeading'>
              <span className='pieChartHeadingLabel'>YTD {paramSubCategory? paramSubCategory:paramCategory}
              <span className={`pieChartHeadingLabelAmount`}>{!pieYTD.loading && !pieYTD.error && (pieYTD.data.absolute >= 0 ?
                formatCurrency(pieYTD.data.absolute,company.currency) :
                <span style={{color:'red'}}>{formatCurrency(pieYTD.data.absolute,company.currency)}</span>)}
              </span>
              </span>
            </div>
            <div style={{fontSize: '1em'}} className='pieChartHeading'>
              <span className='pieChartHeadingLabel'>LTM {paramSubCategory? paramSubCategory: paramCategory} <span
                className={`pieChartHeadingLabelAmount`}>{!pieLTM.loading && !pieLTM.error && (pieLTM.data.absolute >= 0 ?
                formatCurrency(pieLTM.data.absolute,company.currency) :
                <span style={{color: 'red'}}>{formatCurrency(pieLTM.data.absolute,company.currency)}</span>)}
              </span>
              </span>
            </div>
          </div>
          <div className={`pieChart`}>
            <SimplePieChart
              dataTestId={'pie-monthly-contribution'}
              isLoading={pieMonthly.loading}
              items={pieMonthly.data.contribution}
              error={pieMonthly.error}
              formatter={(value)=>formatCurrency(value,company.currency)}
              titleKey={``}/>
            <SimplePieChart
              dataTestId={'pie-ytd-contribution'}
              isLoading={pieYTD.loading}
              items={pieYTD.data.contribution}
              error={pieYTD.error}
              formatter={(value)=>formatCurrency(value,company.currency)}
              titleKey={``}/>

             <SimplePieChart
               dataTestId={'pie-ltm-contribution'}
              isLoading={pieLTM.loading}
              items={pieLTM.data.contribution}
              error={pieLTM.error}
              formatter={(value)=>formatCurrency(value,company.currency)}
              titleKey={``}/>
          </div>
        </div>}
        {paramSubCategory == null && paramCategory !== 'P&L Summary' &&
        <div className='pieChartContainer'>
          <div className={`pieChart`}>
            {<div style={{fontSize: '1em'}} className='pieChartHeading'>
              <span className='pieChartHeadingLabel'>Month {paramSubCategory? paramSubCategory: paramCategory}
              <span className={`pieChartHeadingLabelAmount`}>{!categoryMonthlyLoading && !categoryMonthlyError && (categoryMonthlyChart.absolute >= 0 ?
                formatCurrency(categoryMonthlyChart.absolute,company.currency) :
                <span style={{color:'red'}}>{formatCurrency(categoryMonthlyChart.absolute,company.currency)}</span>)}</span></span>
            </div>}
            {
            <div style={{fontSize: '1em'}} className='pieChartHeading'>
              <span className='pieChartHeadingLabel'>YTD {paramSubCategory? paramSubCategory: paramCategory}
              <span className={`pieChartHeadingLabelAmount`}>{!categoryPieYTDLoading && !categoryPieYTDError && (categoryPieYTDChart.absolute >= 0 ?
                formatCurrency(categoryPieYTDChart.absolute,company.currency) :
                <span style={{color:'red'}}>{formatCurrency(categoryPieYTDChart.absolute,company.currency)}</span>)}</span></span>
            </div>}
            {
              <div style={{fontSize: '1em'}} className='pieChartHeading'>
                <span className='pieChartHeadingLabel'>LTM {paramSubCategory? paramSubCategory: paramCategory} <span
                  className={`pieChartHeadingLabelAmount`}>{!categoryPieLTMLoading && !categoryPieLTMError && (categoryPieLTMChart.absolute >= 0 ?
                  formatCurrency(categoryPieLTMChart.absolute,company.currency) :
                  <span style={{color: 'red'}}>{formatCurrency(categoryPieLTMChart.absolute,company.currency)}</span>)}</span></span>
              </div>
            }
          </div>
          <div className={`pieChart`}>
            {<SimplePieChart
              dataTestId={'pie-category-monthly'}
              isLoading={categoryMonthlyLoading}
              items={categoryMonthlyChart.contribution}
              error={categoryMonthlyError}
              formatter={(value)=>formatCurrency(value,company.currency)}
              containerHeight={330}
              titleKey={``}/>}
            {<SimplePieChart
              dataTestId={'pie-category-ytd'}
              isLoading={categoryPieYTDLoading}
              items={categoryPieYTDChart.contribution}
              error={categoryPieYTDError}
              formatter={(value)=>formatCurrency(value,company.currency)}
              containerHeight={330}
              titleKey={``}/>}
            {<SimplePieChart
              dataTestId={'pie-category-ltm'}
              isLoading={categoryPieLTMLoading}
              items={categoryPieLTMChart.contribution}
              error={categoryPieLTMError}
              formatter={(value)=>formatCurrency(value,company.currency)}
              containerHeight={330}
              titleKey={``}/>}
          </div>
        </div>
        }
        {paramCategory !== 'P&L Summary' && <hr className='separator-line' style={{margin: '2em 0'}}/>}
        {paramCategory !== 'P&L Summary' && <div className={`writeups-container`}>
          <div className={`writeup-wrapper heading`}>Growth trends vs previous year</div>
          <div className={`writeup-wrapper heading`}>Recent growth trends</div>
        </div>}
        {
          paramSubCategory &&
          <div>
            {pnlSubCatWriteupLoading && <div className={`align-loader`}><Loader type={'pulse'} dataTestId={'pnl-subcat-writeup-loading'} size={10}/></div>}
              {(!pnlSubCatWriteupLoading && !pnlSubCatWriteupError) && showWriteupPnL(pnlSubCatWriteup,paramCategory)}
            {pnlSubCatWriteupError && <ErrorResponse dataTestId={'pnlSubCatWriteup'} error={pnlSubCatWriteupError}/> }
          </div>
        }
        {
          (paramSubCategory === null && paramCategory !=='P&L Summary') &&
          <div>
            {pnlCatWriteupLoading && <div className={`align-loader`}><Loader type={'pulse'} dataTestId={'pnl-cat-writeup-loading'} size={10}/></div>}
            {!pnlCatWriteupLoading && !pnlCatWriteupError && showWriteupPnL(pnlCatWriteup,paramCategory)}
            { pnlCatWriteupError && <ErrorResponse dataTestId={'pnlCatWriteup'} error={pnlCatWriteupError}/>}
          </div>
        }
        { (paramSubCategory === null && paramCategory !== 'P&L Summary') && <hr className='separator-line'/>}
        { !paramSubCategory && paramCategory !== 'P&L Summary' &&
        <div className={`line-charts-wrapper`}>
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>Major Increases And Decreases In {paramCategory} From Last Year –&nbsp;Month</span>
          </div>}
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>Major Increases And Decreases In {paramCategory} From Last Year –&nbsp;YTD</span>
          </div>}
        </div>}
        {!paramSubCategory && paramCategory !== 'P&L Summary' &&
        <div className={`line-charts-wrapper`}>
          {<div className={`line-chart-container`}>
            <SimpleBarChart
              dataTestId={'bar-category-difference-monthly'}
              dataValue={paramCategory}
              formatter={(value)=>formatCurrency(value,company.currency)}
              loading={ categoryDifferenceMonthlyLoading}
              error={categoryDifferenceMonthlyError}
              data={categoryDifferenceMonthlyChart}
              chartInfoCopy={infoCopies.majorInceresesAndDecresesFromLastYearMonth}
            />
          </div>}
          {<div className={`line-chart-container`}>
            <SimpleBarChart
              dataTestId={'bar-category-difference-ytd'}
              dataValue={paramCategory}
              formatter={(value)=>formatCurrency(value,company.currency)}
              loading={ categoryDifferenceYTDLoading}
              error={categoryDifferenceYTDError}
              data={categoryDifferenceYTDChart}
              chartInfoCopy={infoCopies.majorInceresesAndDecresesFromLastYearYTD}
            />
          </div>}
        </div>}

        {paramCategory !== 'P&L Summary' && <hr className='separator-line'/>}
        {paramCategory !== 'P&L Summary' &&
        <div className={`line-charts-wrapper`} >
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>{paramSubCategory? paramSubCategory : paramCategory} – Last Twelve Months Trend Vs Previous Year</span>
          </div>}
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>{paramSubCategory? paramSubCategory : paramCategory}
              &nbsp;Evolution – Monthly</span>
          </div>}
        </div>
        }
        {paramCategory !== 'P&L Summary' &&

         <div className={`line-charts-wrapper`}>
           {<div className={`line-chart-container`}>
             <SimpleLineChart
               dataTestId={'line-ltm-prev'}
               formatter={(value) => formatCurrency(value, company.currency)}
               loading={lTMPrevChartLoading}
               error={lTMPrevChartError}
               data={lTMPrevChart}
               chartInfoCopy={infoCopies.lastTwelveMonthTrendVsYear}
             />
           </div>}
           {<div className={`line-chart-container`}>
             <HistoricTrendChart
               dataTestId={'historic-trend'}
               categoryText={paramSubCategory?paramSubCategory:paramCategory}
               formatter={(value) => formatCurrency(value, company.currency)}
               loading={historicTrendChartLoading}
               error={historicTrendChartError}
               data={historicTrendChart}
               chartInfoCopy={paramSubCategory ?(paramSubCategory==='Sales'||'SalesConsulting'||'SalesConstruction'?infoCopies.salesMonthlySubcategory(paramSubCategory):infoCopies.allMonthlyCharts(paramSubCategory)):(paramCategory==='Sales'||'SG&A'?infoCopies.salesMonthlySubcategory(paramCategory):infoCopies.allMonthlyCharts(paramCategory))}
             />
           </div>}
         </div>
        }
        {paramCategory !== 'P&L Summary' && <hr className='separator-line'/>}
        { paramCategory !== 'P&L Summary' &&
          <div className={`line-charts-wrapper`}>
            {<div className='lineChartHeading'>
              <span className='lineChartHeadingLabel'>{paramSubCategory ? paramSubCategory : paramCategory} Evolution - Monthly</span>
            </div>}
            {<div className='lineChartHeading'>
              <span className='lineChartHeadingLabel'>{paramSubCategory ? paramSubCategory : paramCategory} Evolution - LTM
              </span>
            </div>}
          </div>
        }
        {paramCategory !== 'P&L Summary' &&
         <div className={`line-charts-wrapper`}>
           {<div className={`line-chart-container`}>
             <LineChartDoubleYAxis
               dataTestId={'line-area-monthly-evolution'}
               dataValue={paramSubCategory ? paramSubCategory : paramCategory}
               percentageOf={'% of Sales'}
               showGrid
               formatter={(value) => formatCurrency(value, company.currency)}
               loading={monthlyEvolutionChartLoading} error={monthlyEvolutionChartError}
               data={monthlyEvolutionChart}
              chartInfoCopy={paramSubCategory ?(paramSubCategory==='Sales'?infoCopies.salesMonthly:infoCopies.allMonthlyCharts(paramSubCategory)):(paramCategory==='Sales'?infoCopies.salesMonthly:infoCopies.allMonthlyCharts(paramCategory))}
             />
           </div>}
           {<div className={`line-chart-container`}>

             <LineChartDoubleYAxis
               dataTestId={'line-area-ltm-evolution'}
               dataValue={paramSubCategory ? paramSubCategory : paramCategory}
               percentageOf={'% of Sales'}
               formatter={(value) => formatCurrency(value, company.currency)}
               loading={ltmEvolutionChartLoading} error={ltmEvolutionChartError}
               data={ltmEvolutionChart}
             chartInfoCopy={paramSubCategory?(paramSubCategory==='Sales'?infoCopies.salesLTM:infoCopies.allLtmCharts(paramSubCategory)):(paramCategory==='Sales'?infoCopies.salesLTM:infoCopies.allLtmCharts(paramCategory))}
             />
           </div>}
         </div>
        }
      </div>
    </div>
  );
}

export default withErrorBoundary(AnalysisPnl);
