/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import CompaniesList from "../companies_list/CompaniesList";
import withErrorBoundary from "../error/ErrorBoundary";
import { HomeCSS } from './HomeCSS';
import {useEffect } from 'react';
import notify from '../notify/notify';
import {Mixpanel} from "../mixpanel";
import useGet from "../hooks/use-get";

function Home() {

    const [userDetailsResponse, userDetailsLoading, userDetailsError] = useGet({
        url: `${process.env.REACT_APP_BACKEND_BASE_URL}/user-details`});


    useEffect(()=>{
        if(!userDetailsLoading && userDetailsResponse && !userDetailsError){
            const {
                authOrg,
                isDev,
                name,
            } = userDetailsResponse;
            isValidParam(authOrg) && localStorage.setItem("authOrg", authOrg);
            isValidParam(name) && localStorage.setItem("name", name);
            isValidParam(isDev) && localStorage.setItem("isDev", isDev);
            Mixpanel.identify(name);
            Mixpanel.people.set({
                $first_name: name,
                $last_name:'',
            });
            Mixpanel.track('Successful login');
        }
        if(userDetailsError){
            Mixpanel.track('Unsuccessful login');
            notify({ type: 'error', message: 'Something went wrong while fetching your user details' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userDetailsResponse, userDetailsLoading, userDetailsError]);

    return (
        <div className="home" css={HomeCSS}>
            <CompaniesList />
        </div>
    );
}

const isValidParam = param => param !== null && param !== undefined && param !== '';

export default withErrorBoundary(Home);