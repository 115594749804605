import {formatCurrency} from "../analysis-helper";
import React from "react";

const monthsInAnYear = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const date= new Date();

date.getDate() <= 15 ? date.setMonth(date.getMonth() - 2): date.setMonth(date.getMonth() - 1);

const currentYear = date.getFullYear();
const currentMonth = monthsInAnYear[date.getMonth()];


function getMonthOptionsBasedOnYearSelected(selectedYear) {
  if (parseInt(selectedYear) < new Date().getFullYear()) {
    return monthsInAnYear.map(month => ({
      label: month,
      selectable: true
    }))
  } else {
    const lastSelectableMonthIndex = new Date().getMonth();
    return monthsInAnYear.map(month => {
      if (monthsInAnYear.indexOf(month) <= lastSelectableMonthIndex) {
        return {
          label: month,
          selectable: true
        }
      } else {
        return {
          label: month,
          selectable: false
        }
      }
    })
  }
}

const formatCurrencyAndCheckSign=(currency,company)=>{
  const formattedCurrency = formatCurrency(currency,company.currency);
  if(formattedCurrency.includes('-')){
    return(
      <span style={{color:'red'}}>({formattedCurrency.split('-')[1].substr(1,formattedCurrency.length-1)})</span>
    )
  }
  return formattedCurrency.substr(1,formattedCurrency.length-1);
}

const formatPercentAndCheckSign=(value)=>{
  if(value === 'N/A'){
    return (
      <span>{value}</span>
    )
  }
  const formattedValue = parseFloat(value).toFixed(1)+'%';
  if(formattedValue.includes('-')){
    return(
      <span style={{color:'red'}}>({formattedValue.split('-')[1]})</span>
    )
  }
  return formattedValue;
}
const sortSections=(res,period,value,sortType)=>{
  return res.map((section) => {
    const sorted = section.subcategories ?
      section.subcategories.sort((a, b) => {
        if (sortType === 'descending') {
          return b.values[period][value] - a.values[period][value]
        } else {
          return a.values[period][value] - b.values[period][value]
        }
      })
      : null;
    return ({name: section.name, subcategories: sorted, values: section.values});
  })
}

export {getMonthOptionsBasedOnYearSelected,currentMonth,currentYear,monthsInAnYear,date,formatCurrencyAndCheckSign,formatPercentAndCheckSign,sortSections};