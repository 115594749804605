import React, {useState} from "react";
import SubHeader from "../subHeader";
import PnlFinancials from "./pnl-financials";
import './financials.css';
import CustomSelect from "../custom-select";
import BsFinancials from "./bs-financials";
import CfFinancials from "./cf-financials";
import withErrorBoundary from "../error/ErrorBoundary";

const Financials=()=>{
  const [showBs,setShowBs] =useState(false);
  const [showPnl,setShowPnl] =useState(true);
  const [showCf,setShowCf] =useState(false);
  const [financialView,setFinancialView] =useState('Intermediate View');

  const profitTabClick =()=>{
    setShowPnl(true);
    setShowCf(false);
    setShowBs(false);
  }
  const bsTabClick =()=>{
    setShowPnl(false);
    setShowCf(false);
    setShowBs(true);
  }
  const cfTabClick =()=>{
    setShowPnl(false);
    setShowBs(false);
    setShowCf(true);
  }

  return (
    <div className="content-wrapper">
      <SubHeader version2 text={'Financials'} wrapperClass={'financials-wrapper'}/>
      <div className={'financial-fixed-header'}>
        <div className={'financial-view-wrapper'}>
          <CustomSelect
            dataTestId={''}
            selectedValue={financialView}
            options={[
              {label: 'Intermediate View', selectable: true, disconnected:false},
              {label: 'Summary View', selectable: true, disconnected:false},
              {label: 'Full Detail View', selectable: true, disconnected:false}
            ]}
            className={`companySwitcher`}
            callback={(value)=>{ setFinancialView(value)}}/>
        </div>
        <div className="financialsTabs">
          <div className="tabs" style={{display:'flex'}}>
            <div className={`tab-button ${showPnl? 'active':''}`} onClick={profitTabClick}>Profit and Loss</div>
            <div className={`tab-button ${showBs? 'active':''}`} onClick={bsTabClick}>Balance Sheet</div>
            <div className={`tab-button ${showCf? 'active':''}`} onClick={cfTabClick}>CashFlow</div>
          </div>
        </div>
      </div>
      <div className="financialsContainer">
        <div className={'financial-tabs-content'}>
          {showPnl &&
           <PnlFinancials view={financialView}/>
          }
          {showBs &&
           <BsFinancials view={financialView}/>
          }
          {showCf &&
           <CfFinancials view={financialView}/>
          }
        </div>
      </div>
  </div>
  )
}
export default withErrorBoundary(Financials);