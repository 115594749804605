import httpClient from '../util/index';
import { useRef, useState, useEffect, useCallback } from 'react';

const { CancelToken } = httpClient.Axios;

const initialState = skip => ({
  response: {},
  error: null,
  loading: !skip,
});

const useGet = ({ url, skip = false, config,withoutCred=false }) => {
  const hasUnmounted = useRef(false);
  useEffect(() => () => { hasUnmounted.current = true; }, []);
  const [{ response, error, loading }, setter] = useState(() => initialState(skip));

  const setData = useCallback((newData) => {
    if (!hasUnmounted.current) {
      setter(prevState => ({ ...prevState, ...newData }));
    }
  }, []);

  const cancelRef = useRef(null);

  const fetchData = useCallback(async (getUrl) => {
    setData({ error: null, loading: true });
    try {
      if(withoutCred){
        const result = await httpClient.getWithoutCred(getUrl, {
          cancelToken: new CancelToken((cancel) => { cancelRef.current = cancel; }),
          ...config,
        });
        setData({response: result.data, error: null, loading: false});
        return result.data;
      }
      else {
        const result = await httpClient.get(getUrl, {
          cancelToken: new CancelToken((cancel) => { cancelRef.current = cancel; }),
          ...config,
        });
        setData({response: result.data, error: null, loading: false});
        return result.data;
      }
    } catch (err) {
      setData({ error: err, loading: false });
      return err;
    }
    // eslint-disable-next-line
  }, [config]);

  useEffect(() => {
    if (url && !skip) {
      fetchData(url);
    }
    return () => {
      if (cancelRef.current) {
        cancelRef.current();
      }
    };
    // eslint-disable-next-line
  }, [url, skip]);

  const refetch = useCallback(async () => {
    const result = await fetchData(url);
    return result;
    // eslint-disable-next-line
  }, [url]);

  if(skip) {
    return [{}, false, null, refetch];
  }

  return [response, loading, error, refetch];
};

export default useGet;
