import React, {useRef} from "react";
import './chart-info.css';
import { useHovering } from 'use-hovering';

function ChartInfoIcon({text,infoPopupWrapperStyle='infoPopupWrapperStyle'}) {
  const ref = useRef(null);
  const hovering = useHovering(ref, {
    enterDelay: 250,
    exitDelay: 250,
  });
  if(text){
    return (
      <div ref={ref} className={'charts-info-icon'} >
        {hovering &&
         <div className={`${infoPopupWrapperStyle}`} >
           {text.map((line,index)=> <p key={index}>{line}</p>)}
         </div>
        }
      </div>
    );
  }
  else{
    return <div></div>
  }
}
export default ChartInfoIcon;

