import React from "react"
import {Mixpanel} from "../mixpanel";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

const BackLink =({url})=>{
  const company = useSelector(state => state.selectedCompany);
  const history = useHistory();
  return(
  <span
      onClick={() =>{
        Mixpanel.track('Url Change', {'company': company.companyName, url: `#${url}`});
        history.goBack();
      }}
      style={{
        position: 'relative',
        top: '1em',
        left: '2em',
        fontWeight:'500',
        cursor:'pointer',
      }}
      >
       &lt; Back
  </span>
)
}
export default BackLink;