import React, {useState} from 'react';
import {Brush, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import Loader from "../reusables/Loader";
import ErrorResponse from "../error-response/error-response";
import ChartInfoIcon from "../chart-info";

const CustomTooltip = (props) => {
  const { active, payload, label,formatter } = props;

  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="tooltip-heading">{`${label}`}</p>
        {
          payload.map((entry,index)=>{
            return (
            <div key={index} style={{color: entry.stroke}}>
            <span className="tooltip-label">{entry.name} : </span>
            <span className="tooltip-value">{formatter(entry.value)}</span>
            </div>
            )
          })
        }
      </div>
    );
  }

  return null;
};

const LINE_CHART_HEIGHT = 300;
const SimpleLineChart =({
                          data,
                          loading,
                          error,
                          formatter,
                          dataKeyXAxis="Month",
                          dataKeyLine1='Previous Year',
                          dataKeyLine2='Current Year',
                          showBrush=false,
                          showLegend=true,
                          showActiveDot=true,
                          dataTestId='simpleLineChart',
                          chartInfoCopy


})=>{
  const [state,setState]=useState( {opacity: { 'Previous Year': 1, 'Current Year': 1}});

  const handleMouseEnter = (o) => {
    const { dataKey } = o;
    const { opacity } = state;
    setState({
      opacity: { ...opacity, [dataKey]: 0.5 },
    });
  };
  const handleMouseLeave = (o) => {
    const { dataKey } = o;
    const { opacity } = state;
    setState({
      opacity: { ...opacity, [dataKey]: 1 },
    });
  };
   if(loading) {
     return <div style={{margin:'1em 0 2em 0'}}><Loader dataTestId={`${dataTestId}-loading`} type={'pulse'} size={10}/></div>
   }

  if(data.length > 0 && !loading) {
    return (
      <div style={{ fontSize: '0.8em', marginLeft: '-20px' }} data-testid={dataTestId}>
      <ChartInfoIcon text={chartInfoCopy}/>
      <ResponsiveContainer height={LINE_CHART_HEIGHT}>
        <LineChart
          height={LINE_CHART_HEIGHT}
          data={data}
          margin={{top: 5, right: 30, left: 20, bottom: 5}}
        >
          {/*<CartesianGrid strokeDasharray="3 3"/>*/}
          <XAxis dataKey={dataKeyXAxis}/>
          <YAxis tickFormatter={(value)=> formatter(value)}/>
          <Tooltip content={<CustomTooltip/>} formatter={(value)=> formatter(value)}/>
          {showLegend && <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}/>}
          <Line type="monotone" dataKey={dataKeyLine1} strokeOpacity={state.opacity['Previous Year']} stroke="#FE7602" dot={showActiveDot} activeDot={showActiveDot?{ r: 8 }:false} strokeWidth={3}/>
          <Line type="monotone" dataKey={dataKeyLine2} strokeOpacity={state.opacity[['Current Year']]} stroke="#3571CA" dot={showActiveDot} activeDot={showActiveDot?{ r: 8 }:false} strokeWidth={3}/>
          {showBrush && <Brush/>}
        </LineChart>
      </ResponsiveContainer>
      </div>
    )
  }
  if(error){
    return (<ErrorResponse dataTestId={`${dataTestId}-error`} error={error}/>)
  }
  return (<div className="chart-error error-icon"><span>Data not available</span></div>)

}
export default SimpleLineChart;
