import React, {useEffect, useState} from 'react';
import useGet from "../hooks/use-get";
import {useSelector} from "react-redux";
import SubHeader from "../subHeader";
import SimpleBarChart from "../simple-bar-chart";
import {
  formatCurrency,
  mapCategoryForDifferencesBarChart,
  mapCategoryForPieChart, mapEvolution,
  mapHistoricTrend,
  mapLTMPrevChartData,
  mapLargestComponentsTrends, waterfallChartMap, showWriteupBS,
} from '../analysis-helper';
import SimpleLineChart from "../simple-line-chart";
import HistoricTrendChart from "../historic-trend-chart";
import SimplePieChart from "../simple-pie-chart/simple-pie-chart";
import LineChartMultipleSeries from "../line-chart-multiple-series";
import LineChartDoubleYAxis from "../line-chart-double-y-axis";
import WaterfallChart from "../waterfall-chart";
import BsRepresentationBarChart from "../bs-representation-bar-chart";
import Loader from "../reusables/Loader";
import './analysis-bs.css';
import ErrorResponse from "../error-response/error-response";
import infoCopies from "../chart-info-copies";
import withErrorBoundary from "../error/ErrorBoundary";

const AnalysisBs = ()=>{

  const company = useSelector(state => state.selectedCompany);
  const selectedDate = useSelector(state => state.endDate);
  //const startDate = useSelector(state => state.startDate);
  const paramSection = useSelector(state => state.selectedNavigation.sheet);
  const paramCategory = useSelector(state => state.selectedNavigation.category);
  const paramSubCategory = useSelector(state => state.selectedNavigation.subCategory);

  const [categoryDifferencePrevMonthChart,setCategoryDifferencePrevMonthChart]=useState({});
  const [categoryDifferencePrevYearMonthChart,setCategoryDifferencePrevYearMonthChart]=useState({});
  const [lTMPrevChart,setLTMPrevChart]=useState([]);
  const [historicTrendChart,setHistoricTrendChart]=useState([]);

  const [historicTrendComponentsChart,setHistoricTrendComponentsChart]=useState([]);
  const [evolutionChart,setEvolutionChart]=useState([]);

  const [equityChangeMonthChart,setEquityChangeMonthChart]=useState([]);
  const [equityChangeYearChart,setEquityChangeYearChart]=useState([]);

  const [categoryMonthlyChart,setCategoryMonthlyChart]=useState({});
  const [categoryPieLTMChart,setCategoryPieLTMChart]=useState({});
  const [categoryPieYTDChart,setCategoryPieYTDChart]=useState({});

  const [subCategoryPieMonthlyChart,setSubCategoryPieMonthlyChart]=useState({});
  const [subCategoryPieLTMChart,setSubCategoryPieLTMChart]=useState({});
  const [subCategoryPieYTDChart,setSubCategoryPieYTDChart]=useState({});

  const [bsOverallWriteup,setBsOverallWriteup]=useState({});

  const [overallContributionLastYear, setOverallContributionLastYear] = useState({});
  const [overallContributionMonth, setOverallContributionMonth] = useState({});
  const [overallContributionLastFinYear, setOverallContributionLastFinYear] = useState({});

  const [overallBSMonth, setOverallBSMonth] = useState('');
  const [overallBSLastFinYearEnd, setOverallBSLastFinYearEnd] = useState('');
  const [overallBSLastYearMonth, setOverallBSLastYearMonth] = useState('');

  const [bsSubCatWriteup,setBsSubCatWriteup]=useState({});
  const [bsCatWriteup,setBsCatWriteup]=useState({});

  const getDate=(date)=> `${date.month} ${date.year.slice(-2)}`;
  const isNull = (value) => typeof value === "object" && !value
  const [bsSubCatWriteupResponse, bsSubCatWriteupLoading, bsSubCatWriteupError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/writeup/sub-category/bs?companyId=${company.companyId}`+
         `${paramSubCategory?`&subCategory=${paramSubCategory.replace(/&/ig,'%26')}`:''}`+
         `&date=${getDate(selectedDate)}`,
    skip:paramCategory === 'BS Summary' || isNull(paramSubCategory)});

  const [bsCatWriteupResponse, bsCatWriteupLoading, bsCatWriteupError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/writeup/category/bs?companyId=${company.companyId}`+
         `&category=${paramCategory?.replace(/&/ig,'%26')}`+
         `&date=${getDate(selectedDate)}`,
    skip:paramCategory === 'BS Summary' || !isNull(paramSubCategory)});

  const [categoryDifferencePrevMonthResponse, categoryDifferencePrevMonthLoading, categoryDifferencePrevMonthError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/differences/bs?companyId=${company.companyId}`+
         `&category=${paramCategory === 'BS Summary'? 'Equity':paramCategory?.replace(/&/ig,'%26')}`+
         `&date=${getDate(selectedDate)}&differenceOn=PREV_MONTH`,skip: paramSubCategory !== null});

  const [categoryDifferencePrevYearMonthResponse, categoryDifferencePrevYearMonthLoading, categoryDifferencePrevYearMonthError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/differences/bs?companyId=${company.companyId}`+
         `&category=${paramCategory === 'BS Summary'? 'Equity':paramCategory?.replace(/&/ig,'%26')}`+
         `&date=${getDate(selectedDate)}&differenceOn=PREV_YEAR_MONTH`,skip: paramSubCategory !== null});

  const [lTMPrevChartResponse, lTMPrevChartLoading, lTMPrevChartError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/ltm-vs-previous${paramSubCategory?'':'/category'}?`+
         `companyId=${company.companyId}${paramSubCategory?`&subCategory=${paramSubCategory.replace(/&/ig,'%26')}`:''}`+
         `&category=${paramCategory === 'BS Summary'? 'Equity':paramCategory.replace(/&/ig,'%26')}`+
         `&date=${getDate(selectedDate)}&sheet=BS`});

  const [historicTrendChartResponse, historicTrendChartLoading, historicTrendChartError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/historic-data${paramSubCategory?'':'/category/summed-over-month'}`+
      `?companyId=${company.companyId}${paramSubCategory?`&subCategory=${paramSubCategory.replace(/&/ig,'%26')}`:''}`+
         `&category=${paramCategory === 'BS Summary'? 'Equity':paramCategory?.replace(/&/ig,'%26')}`+
         `&date=${getDate(selectedDate)}&sheet=BS`});

  const [categoryMonthlyResponse, categoryMonthlyLoading, categoryMonthlyError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/contributors/bs?companyId=${company.companyId}`+
         `&category=${paramCategory?.replace(/&/ig,'%26')}&date=${getDate(selectedDate)}`+
         `&breakdownCriteria=QUERY_MONTH`,skip: paramSubCategory !== null || paramCategory ==='BS Summary'});

  const [categoryPieLTMResponse, categoryPieLTMLoading, categoryPieLTMError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/contributors/bs?companyId=${company.companyId}`+
         `&category=${paramCategory?.replace(/&/ig,'%26')}&date=${getDate(selectedDate)}`+
         `&breakdownCriteria=LAST_FIN_YEAR_END`,skip: paramSubCategory !== null || paramCategory ==='BS Summary'});

  const [categoryPieYTDResponse, categoryPieYTDLoading, categoryPieYTDError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/contributors/bs?companyId=${company.companyId}`+
         `&category=${paramCategory?.replace(/&/ig,'%26')}&date=${getDate(selectedDate)}`+
         `&breakdownCriteria=LAST_YEAR_QUERY_MONTH`,skip: paramSubCategory !== null || paramCategory ==='BS Summary'});

  const [subCategoryPieMonthlyResponse, subCategoryPieMonthlyLoading, subCategoryPieMonthlyError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/contribution/bs?`+
         `companyId=${company.companyId}${paramSubCategory?`&subCategory=${paramSubCategory.replace(/&/ig,'%26')}`:''}`+
         `&category=${paramCategory?.replace(/&/ig,'%26')}`+
         `&date=${getDate(selectedDate)}&breakdownCriteria=QUERY_MONTH`,skip: paramSubCategory === null});

  const [subCategoryPieLTMResponse, subCategoryPieLTMLoading, subCategoryPieLTMError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/contribution/bs?`+
         `companyId=${company.companyId}${paramSubCategory?`&subCategory=${paramSubCategory.replace(/&/ig,'%26')}`:''}`+
         `&category=${paramCategory?.replace(/&/ig,'%26')}`+
         `&date=${getDate(selectedDate)}&breakdownCriteria=LAST_FIN_YEAR_END`,skip: paramSubCategory === null});

  const [subCategoryPieYTDResponse, subCategoryPieYTDLoading, subCategoryPieYTDError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/contribution/bs?`+
         `companyId=${company.companyId}${paramSubCategory?`&subCategory=${paramSubCategory.replace(/&/ig,'%26')}`:''}`+
         `&category=${paramCategory?.replace(/&/ig,'%26')}`+
         `&date=${getDate(selectedDate)}&breakdownCriteria=LAST_YEAR_QUERY_MONTH`,skip: paramSubCategory === null});

  const [historicTrendComponentsChartResponse, historicTrendComponentsChartLoading, historicTrendComponentsChartError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/historic-data/category?`+
         `companyId=${company.companyId}${paramSubCategory?`&subCategory=${paramSubCategory.replace(/&/ig,'%26')}`:''}`+
         `&category=${paramCategory === 'BS Summary'? 'Equity':paramCategory?.replace(/&/ig,'%26')}`+
         `&date=${getDate(selectedDate)}&breakdown=MONTH&sheet=BS`,skip: paramSubCategory !== null});

  const [evolutionChartResponse, evolutionChartLoading, evolutionChartError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/monthly-evolution${paramSubCategory?'':'/category'}/bs?`+
         `companyId=${company.companyId}&subCategory=${paramSubCategory? paramSubCategory.replace(/&/ig,'%26'):''}`+
         `&category=${paramCategory === 'BS Summary'? 'Equity':paramCategory?.replace(/&/ig,'%26')}`+
         `&date=${getDate(selectedDate)}`});

  const [equityChangeMonthChartResponse, equityChangeMonthChartLoading, equityChangeMonthChartError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/change-in-equity?companyId=${company.companyId}&category=Equity`+
         `&date=${getDate(selectedDate)}&changeOver=MONTH`,skip: paramCategory !== 'BS Summary'});

  const [equityChangeYearChartResponse, equityChangeYearChartLoading, equityChangeYearChartError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/change-in-equity?companyId=${company.companyId}&category=Equity`+
         `&date=${getDate(selectedDate)}&changeOver=YEAR`,skip: paramCategory !== 'BS Summary'});

  const [bsOverallWriteupResponse, bsOverallWriteupLoading, bsOverallWriteupError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/writeup/overall/bs?companyId=${company.companyId}`+
         `&date=${getDate(selectedDate)}`,skip: paramCategory !== 'BS Summary'});

  const [overallContributionLastYearResponse, overallContributionLastYearLoading, overallContributionLastYearError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/contribution/overall/bs?companyId=${company.companyId}`+
         `&date=${getDate(selectedDate)}&breakdownCriteria=LAST_YEAR_QUERY_MONTH`,skip: paramCategory !== 'BS Summary'});

  const [overallContributionMonthResponse, overallContributionMonthLoading, overallContributionMonthError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/contribution/overall/bs?companyId=${company.companyId}`+
         `&date=${getDate(selectedDate)}&breakdownCriteria=QUERY_MONTH`,skip: paramCategory !== 'BS Summary'});

  const [overallContributionLastFinYearResponse, overallContributionLastFinYearLoading, overallContributionLastFinYearError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/contribution/overall/bs?companyId=${company.companyId}`+
         `&date=${getDate(selectedDate)}&breakdownCriteria=LAST_FIN_YEAR_END`,skip: paramCategory !== 'BS Summary'});

  const [overallBSMonthResponse, overallBSMonthLoading, overallBSMonthError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/absolute/category/bs?companyId=${company.companyId}`+
         `&category=Equity&date=${getDate(selectedDate)}&breakdownCriteria=QUERY_MONTH`,skip: paramCategory !== 'BS Summary'});

  const [overallBSLastFinYearEndResponse, overallBSLastFinYearEndLoading, overallBSLastFinYearEndError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/absolute/category/bs?companyId=${company.companyId}`+
         `&category=Equity&date=${getDate(selectedDate)}&breakdownCriteria=LAST_FIN_YEAR_END`,skip: paramCategory !== 'BS Summary'});

  const [overallBSLastYearMonthResponse, overallBSLastYearMonthLoading, overallBSLastYearMonthError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/absolute/category/bs?companyId=${company.companyId}`+
         `&category=Equity&date=${getDate(selectedDate)}&breakdownCriteria=LAST_YEAR_QUERY_MONTH`,skip: paramCategory !== 'BS Summary'});

  const mapBSOverviewWaterfall = (obj)=>{
  return [
    {cat:'Assets',value:obj.assets, sum:0, percent:100},
    {cat:'Liabilities',value:obj.liabilities, sum: obj.assets - obj.liabilities,percent:(obj.liabilities/obj.assets)*100 },
    {cat:'Equity',value:obj.equity,sum: 0, percent:(obj.equity/obj.assets)*100}]
}

  useEffect(()=>{
    if(paramSubCategory !== null) {
      if (!bsSubCatWriteupLoading && (Object.keys(bsSubCatWriteupResponse).length > 0)) {
        setBsSubCatWriteup(bsSubCatWriteupResponse);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[bsSubCatWriteupLoading, bsSubCatWriteupResponse, bsSubCatWriteupError,paramSection,paramCategory]);

  useEffect(()=>{
    if(paramSubCategory === null) {
      if (!bsCatWriteupLoading && (Object.keys(bsCatWriteupResponse).length > 0)) {
        setBsCatWriteup(bsCatWriteupResponse);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[bsCatWriteupLoading, bsCatWriteupResponse, bsCatWriteupError,paramSection,paramCategory]);

  useEffect(()=>{
    if(paramCategory === 'BS Summary') {
      if (!overallBSMonthLoading && overallBSMonthResponse) {
        setOverallBSMonth(overallBSMonthResponse);
      }
    }
  },[overallBSMonthResponse,overallBSMonthLoading,overallBSMonthError,paramCategory,paramSection])

  useEffect(()=>{

    if(paramCategory === 'BS Summary') {
      if (!overallBSLastFinYearEndLoading && overallBSLastFinYearEndResponse) {
        setOverallBSLastFinYearEnd(overallBSLastFinYearEndResponse);
      }
    }
  },[
    overallBSLastFinYearEndResponse,
    overallBSLastFinYearEndLoading,
    overallBSLastFinYearEndError,
    paramCategory,
    paramSection])

  useEffect(()=>{
    if(paramCategory === 'BS Summary') {
      if (!overallBSLastYearMonthLoading && overallBSLastYearMonthResponse) {
        setOverallBSLastYearMonth(overallBSLastYearMonthResponse);
      }
    }
  },[overallBSLastYearMonthResponse,overallBSLastYearMonthLoading,overallBSLastYearMonthError,paramCategory,paramSection])

  useEffect(()=>{
    if(paramCategory === 'BS Summary') {
      if (!overallContributionLastYearLoading && (Object.keys(overallContributionLastYearResponse).length > 0)) {
        setOverallContributionLastYear(mapBSOverviewWaterfall(overallContributionLastYearResponse));
      }
    }
  },[overallContributionLastYearResponse,overallContributionLastYearLoading,overallContributionLastYearError,paramCategory,paramSection])

  useEffect(()=>{
    if(paramCategory === 'BS Summary') {
      if (!overallContributionMonthLoading && (Object.keys(overallContributionMonthResponse).length > 0)) {
        setOverallContributionMonth(mapBSOverviewWaterfall(overallContributionMonthResponse));
      }
    }
  },[overallContributionMonthResponse,overallContributionMonthLoading,overallContributionMonthError,paramCategory,paramSection])

  useEffect(()=>{
    if(paramCategory === 'BS Summary') {
      if (!overallContributionLastFinYearLoading && (Object.keys(overallContributionLastFinYearResponse).length > 0)) {
        setOverallContributionLastFinYear(mapBSOverviewWaterfall(overallContributionLastFinYearResponse));
      }
    }
  },[
    overallContributionLastFinYearResponse,
    overallContributionLastFinYearLoading,
    overallContributionLastFinYearError,
    paramCategory,
    paramSection])


  useEffect(()=>{
    if(paramCategory === 'BS Summary') {
      if (!bsOverallWriteupLoading && (Object.keys(bsOverallWriteupResponse).length > 0)) {
        setBsOverallWriteup(bsOverallWriteupResponse);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[bsOverallWriteupLoading, bsOverallWriteupResponse, bsOverallWriteupError,paramSection,paramCategory]);

  useEffect(()=>{
    if(paramSubCategory === null) {
      if (!categoryDifferencePrevMonthLoading && (Object.keys(categoryDifferencePrevMonthResponse).length > 0)) {
        setCategoryDifferencePrevMonthChart(mapCategoryForDifferencesBarChart(categoryDifferencePrevMonthResponse));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    categoryDifferencePrevMonthLoading,
    categoryDifferencePrevMonthResponse,
    categoryDifferencePrevMonthError,
    paramSection,
    paramCategory]);

  useEffect(()=>{
    if(paramSubCategory === null) {
      if (!categoryDifferencePrevYearMonthLoading && (Object.keys(categoryDifferencePrevYearMonthResponse).length > 0)) {
        setCategoryDifferencePrevYearMonthChart(mapCategoryForDifferencesBarChart(categoryDifferencePrevYearMonthResponse));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    categoryDifferencePrevYearMonthLoading,
    categoryDifferencePrevYearMonthResponse,
    categoryDifferencePrevYearMonthError,
    paramSection,
    paramCategory]);

  useEffect(()=>{
    if(!lTMPrevChartLoading && (Object.keys(lTMPrevChartResponse).length > 0)){
      setLTMPrevChart(mapLTMPrevChartData(lTMPrevChartResponse));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[lTMPrevChartResponse, lTMPrevChartLoading, lTMPrevChartError,paramSection,paramCategory,paramSubCategory])

  useEffect(()=>{
    if(!historicTrendChartLoading && (Object.keys(historicTrendChartResponse).length > 0)){
      setHistoricTrendChart(mapHistoricTrend(historicTrendChartResponse));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[historicTrendChartLoading, historicTrendChartResponse, historicTrendChartError,paramSection,paramCategory,paramSubCategory])

  useEffect(()=>{
    if(!paramSubCategory) {
      if (!historicTrendComponentsChartLoading && (Object.keys(historicTrendComponentsChartResponse).length > 0)) {
        setHistoricTrendComponentsChart(mapLargestComponentsTrends(historicTrendComponentsChartResponse, paramCategory));
      }
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[
      historicTrendComponentsChartLoading,
    historicTrendComponentsChartResponse,
    historicTrendComponentsChartError,
    paramSection,
    paramCategory,
    paramSubCategory])

  useEffect(()=>{
    if(paramSubCategory === null) {
      if (!categoryMonthlyLoading && (Object.keys(categoryMonthlyResponse).length > 0)) {
        setCategoryMonthlyChart(mapCategoryForPieChart(categoryMonthlyResponse));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[categoryMonthlyLoading, categoryMonthlyResponse, categoryMonthlyError,paramSection,paramCategory]);

  useEffect(()=>{
    if(paramSubCategory === null) {
      if (!categoryPieLTMLoading && (Object.keys(categoryPieLTMResponse).length > 0)) {
        setCategoryPieLTMChart(mapCategoryForPieChart(categoryPieLTMResponse));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[categoryPieLTMLoading, categoryPieLTMResponse, categoryPieLTMError,paramSection,paramCategory]);

  useEffect(()=>{
    if(paramSubCategory === null) {
      if (!categoryPieYTDLoading && (Object.keys(categoryPieYTDResponse).length > 0)) {
        setCategoryPieYTDChart(mapCategoryForPieChart(categoryPieYTDResponse));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[categoryPieYTDLoading, categoryPieYTDResponse, categoryPieYTDError,paramSection,paramCategory]);

  useEffect(()=>{
    if(paramSubCategory !== null) {
      if (!subCategoryPieMonthlyLoading && (Object.keys(subCategoryPieMonthlyResponse).length > 0)) {
        setSubCategoryPieMonthlyChart(mapCategoryForPieChart(subCategoryPieMonthlyResponse));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[subCategoryPieMonthlyLoading, subCategoryPieMonthlyResponse, subCategoryPieMonthlyError,paramSection,paramCategory]);

  useEffect(()=>{
    if(paramSubCategory !== null) {
      if (!subCategoryPieLTMLoading && (Object.keys(subCategoryPieLTMResponse).length > 0)) {
        setSubCategoryPieLTMChart(mapCategoryForPieChart(subCategoryPieLTMResponse));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[subCategoryPieLTMLoading, subCategoryPieLTMResponse, subCategoryPieLTMError,paramSection,paramCategory]);

  useEffect(()=>{
    if(paramSubCategory !== null) {
      if (!subCategoryPieYTDLoading && (Object.keys(subCategoryPieYTDResponse).length > 0)) {
        setSubCategoryPieYTDChart(mapCategoryForPieChart(subCategoryPieYTDResponse));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[subCategoryPieYTDLoading, subCategoryPieYTDResponse, subCategoryPieYTDError,paramSection,paramCategory]);

  useEffect(()=>{
    if(!evolutionChartLoading && (Object.keys(evolutionChartResponse).length > 0)){
      setEvolutionChart(mapEvolution(evolutionChartResponse,paramSubCategory,paramCategory,'bs'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[evolutionChartLoading, evolutionChartResponse, evolutionChartError,paramSection,paramCategory,paramSubCategory])

  useEffect(()=>{
    if(paramCategory === 'BS Summary') {
      if (!equityChangeMonthChartLoading && (Object.keys(equityChangeMonthChartResponse).length > 0)) {
        setEquityChangeMonthChart(waterfallChartMap(equityChangeMonthChartResponse));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[equityChangeMonthChartLoading, equityChangeMonthChartResponse, equityChangeMonthChartError,paramSection,paramCategory]);

  useEffect(()=>{
    if(paramCategory === 'BS Summary') {
      if (!equityChangeYearChartLoading && (Object.keys(equityChangeYearChartResponse).length > 0)) {
        setEquityChangeYearChart(waterfallChartMap(equityChangeYearChartResponse));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[equityChangeYearChartLoading, equityChangeYearChartResponse, equityChangeYearChartError,paramSection,paramCategory,paramSubCategory])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const showBsCatagoryWriteUp =(obj)=>{
    const response =[];
    for (const [key, value] of Object.entries(obj)) {
      response.push(<h2 style={{fontWeight:'600',fontSize:'1em',color:'#305A80',padding:'1em 0 0 0',margin:'0'}}>{key}</h2>);
      response.push(value.map((entry,index) => {
        return (<p key={index} style={{fontSize:'1em',margin:'0.6em 0'}}>- {entry}</p>)
      }));
    }
    return response;
  }

  return (
    <div className="content-wrapper">
      <SubHeader text={'Analysis as of'}/>
      <h5 className='page-title'>{`${paramSection !== undefined && paramSubCategory ? paramSubCategory : paramCategory}`}</h5>
      <div className="analysisContainer">
        {
          paramCategory ==='BS Summary' &&
          <div className={`line-charts-wrapper`}>
            {!overallBSMonthLoading &&
             <div className='lineChartHeading' style={{textAlign: 'center'}}>
              <span className='lineChartHeadingLabel'>{overallBSMonth.cat} Equity {parseInt(overallBSMonth.value) >=0 ?
                <span className='pieChartHeadingLabelAmount'>{formatCurrency(parseInt(overallBSMonth.value),company.currency)}</span>:
                <span className='pieChartHeadingLabelAmount' style={{color:'red'}}>
                  {formatCurrency(parseInt(overallBSMonth.value),company.currency)}
                </span>}
              </span>
              <span>{
                overallBSMonthError && <ErrorResponse dataTestId={`overall-bs-month-error`} error={overallBSMonthError}/>}</span>
            </div>}
            {!overallBSLastFinYearEndLoading &&
             <div className='lineChartHeading' style={{textAlign: 'center'}}>
               <span className='lineChartHeadingLabel'>{overallBSLastFinYearEnd.cat} Equity {parseInt(overallBSLastFinYearEnd.value) >=0 ?
                 <span className='pieChartHeadingLabelAmount'>{formatCurrency(parseInt(overallBSLastFinYearEnd.value),company.currency)}</span>:
                 <span className='pieChartHeadingLabelAmount' style={{color:'red'}}>
                   {formatCurrency(parseInt(overallBSLastFinYearEnd.value),company.currency)}
                 </span>}
              </span>
              <span>
                {overallBSLastFinYearEndError && <ErrorResponse dataTestId={`overall-bs-last-finn-year-end-error`} error={overallBSLastFinYearEndError}/>}
              </span>
            </div>}
            {!overallBSLastYearMonthLoading &&
             <div className='lineChartHeading' style={{textAlign: 'center'}}>
               <span className='lineChartHeadingLabel'>{overallBSLastYearMonth.cat} Equity {parseInt(overallBSLastYearMonth.value) >=0 ?
                 <span className='pieChartHeadingLabelAmount'>{formatCurrency(parseInt(overallBSLastYearMonth.value),company.currency)}</span>:
                 <span className='pieChartHeadingLabelAmount' style={{color:'red'}}>
                   {formatCurrency(parseInt(overallBSLastYearMonth.value),company.currency)}
                 </span>}
              </span>
              <span>{overallBSLastYearMonthError && <ErrorResponse dataTestId={`overall-bs-last-year-error`} error={overallBSLastYearMonthError}/>}
              </span>
            </div>}
          </div>
        }
        {paramCategory === 'BS Summary' &&
         <hr className='separator-line'/>
        }
        {paramCategory === 'BS Summary' &&
         <div className="overall-contribution-headings">
           <div className="lineChartHeading">
             <span className='lineChartHeadingLabel'>Balance Sheet - {overallContributionMonthResponse.label}</span>
           </div>
           <div className="lineChartHeading">
             <span className='lineChartHeadingLabel'>Balance Sheet - {overallContributionLastFinYearResponse.label}</span>
           </div>
           <div className="lineChartHeading">
             <span className='lineChartHeadingLabel'>Balance Sheet - {overallContributionLastYearResponse.label}</span>
           </div>
         </div>
        }
        {paramCategory === 'BS Summary' &&
         <div className="overall-contribution">
           <div className="overall-contribution-container container-for-chart-info-icon">
             <WaterfallChart
               dataTestId={`overall-contribution-month`}
               secondaryColor={'#d62728'}
               maxWidth={`500px`}
               barSize={40}
               formatter={(value)=>formatCurrency(value,company.currency)}
               loading={ overallContributionMonthLoading}
               error={overallContributionMonthError}
               data={overallContributionMonth}
               chartInfoCopy={infoCopies.bsSummary}
             />
           </div>
           <div className="overall-contribution-container container-for-chart-info-icon">
             <WaterfallChart
               dataTestId={`overall-contribution-last-fin-year`}
               secondaryColor={'#d62728'}
               maxWidth={`500px`}
               barSize={40}
               formatter={(value)=>formatCurrency(value,company.currency)}
               loading={ overallContributionLastFinYearLoading}
               error={overallContributionLastFinYearError}
               data={overallContributionLastFinYear}
               chartInfoCopy={infoCopies.bsSummary}
             />
           </div>
         <div className="overall-contribution-container container-for-chart-info-icon">
           <WaterfallChart secondaryColor={'#d62728'}
             dataTestId={`overall-contribution-last-year`}
             maxWidth={`500px`}
             barSize={40}
             formatter={(value)=>formatCurrency(value,company.currency)}
             loading={ overallContributionLastYearLoading}
             error={overallContributionLastYearError}
             data={overallContributionLastYear}
             chartInfoCopy={infoCopies.bsSummary}
           />
         </div>

         </div>
        }
        {paramCategory === 'BS Summary' &&
         <hr className='separator-line' style={{marginBottom:'2.5em',marginTop:'-1em'}}/>
        }
        {paramCategory === 'BS Summary' && <p className={`bs-representation-title`}>Balance Sheet Representation as of&nbsp;
          {selectedDate.month} {selectedDate.year}</p>}
        {paramCategory === 'BS Summary' &&
        <div className={`line-charts-wrapper`}>
        <BsRepresentationBarChart
          dataTestId={`bs-overall-writeup-chart`}
          dataValue={paramSubCategory}
          formatter={(value)=>formatCurrency(value,company.currency)}
          loading={ bsOverallWriteupLoading}
          error={bsOverallWriteupError}
          data={bsOverallWriteup.Representation ? bsOverallWriteup.Representation:null}
          chartInfoCopy={infoCopies.balanceSheetRepresentation}
        />
         <div style={{padding:'0 2em',flex:'0 0 50%',marginTop:'-3.5em'}}>
          {bsOverallWriteupLoading && <div style={{margin:'1em auto 2em auto'}}><Loader dataTestId={`bs-overall-writeup-loading`} type={'pulse'} size={10}/></div>}
          {!bsOverallWriteupLoading && Object.keys(bsOverallWriteup).length >0 && showBsCatagoryWriteUp(bsOverallWriteup.WriteUp)}
        </div>
        </div>}
        { paramCategory === 'BS Summary' && <hr className='separator-line' style={{marginBottom:'1.5em'}}/>}
        { paramCategory === 'BS Summary' &&
        <div className={`line-charts-wrapper`}>
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>Equity - Main Increases And Decreases Vs Previous Month</span>
          </div>}
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>Equity - Main Increases And Decreases Vs Same Month Of Previous Year
</span>
          </div>}
        </div>}
        {paramCategory === 'BS Summary' &&
        <div className={`line-charts-wrapper`}>
          {<div className={`line-chart-container`}>
            <WaterfallChart
              dataTestId={`equity-change-month-chart`}
              dataValue={paramCategory}
              formatter={(value)=>formatCurrency(value,company.currency)}
              loading={ equityChangeMonthChartLoading}
              error={equityChangeMonthChartError}
              data={equityChangeMonthChart}
              chartInfoCopy={infoCopies.equityInceresesAndDecresesVsMonth}
            />
          </div>}
          {<div className={`line-chart-container`}>
            <WaterfallChart
              dataTestId={`equity-change-year-chart`}
              dataValue={paramCategory}
              formatter={(value)=>formatCurrency(value,company.currency)}
              loading={ equityChangeYearChartLoading}
              error={equityChangeYearChartError}
              data={equityChangeYearChart}
              chartInfoCopy={infoCopies.equityInceresesAndDecresesVsPreviousYear}
            />
          </div>}
        </div>}

        {paramSubCategory == null && paramCategory!=='BS Summary' &&
        <div className='pieChartContainer'>
          <div className={`pieChart`}>
            {
            <div style={{fontSize: '1em'}} className='pieChartHeading'>
              <span className='pieChartHeadingLabel'> {paramSubCategory? paramSubCategory: paramCategory}
              <span className={`pieChartHeadingLabelAmount`}>
                {!categoryMonthlyLoading && !categoryMonthlyError && (categoryMonthlyChart.absolute >= 0 ?
                  <span>{formatCurrency(categoryMonthlyChart.absolute,company.currency)}
                  <p> as of - {`${categoryMonthlyChart.label}`}</p>
                  </span> :
                  <span style={{color:'red',whiteSpace:'nowrap'}}>
                    {formatCurrency(categoryMonthlyChart.absolute,company.currency)}
                    <p> as of - {`${categoryMonthlyChart.label}`}</p>
                  </span>)}
              </span>
              </span>
            </div>}
            {
            <div style={{fontSize: '1em'}} className='pieChartHeading'>
                <span className='pieChartHeadingLabel'> {paramSubCategory? paramSubCategory: paramCategory} <span
                  className={`pieChartHeadingLabelAmount`}>
                  {!categoryPieLTMLoading && !categoryPieLTMError && (categoryPieLTMChart.absolute >= 0 ?
                    <span>{formatCurrency(categoryPieLTMChart.absolute)}
                    <p> as of Last Financial Year End - {`${categoryPieLTMChart.label}`}</p>
                    </span>:
                  <span style={{color: 'red'}}>
                     <p> as of Last Financial Year End - {`${categoryPieLTMChart.label}`}</p>
                    {formatCurrency(categoryPieLTMChart.absolute,company.currency)}
                  </span>)}
                </span>
                </span>
            </div>
            }
            {
            <div style={{fontSize: '1em'}} className='pieChartHeading'>
              <span className='pieChartHeadingLabel'> {paramSubCategory? paramSubCategory: paramCategory}
              <span className={`pieChartHeadingLabelAmount`}>
                {!categoryPieYTDLoading && !categoryPieYTDError && (categoryPieYTDChart.absolute >= 0 ?
                <span>{formatCurrency(categoryPieYTDChart.absolute,company.currency)}
                  <p> as of Same Month Previous Year - {`${categoryPieYTDChart.label}`}</p>
                </span> :
                <span style={{color:'red'}}>{formatCurrency(categoryPieYTDChart.absolute,company.currency)}
                  <p> as of Same Month Previous Year - {`${categoryPieYTDChart.label}`}</p>
                </span>)}
              </span>
              </span>
            </div>}
          </div>
          <div className={`pieChart`}>
            {<SimplePieChart
              dataTestId={`category-pie-month-chart`}
              isLoading={categoryMonthlyLoading}
              items={categoryMonthlyChart.contribution}
              error={categoryMonthlyError}
              formatter={(value)=>formatCurrency(value,company.currency)}
              containerHeight={330}
              titleKey={``}/>}
            {<SimplePieChart
              dataTestId={`category-pie-ltm-chart`}
              isLoading={categoryPieLTMLoading}
              items={categoryPieLTMChart.contribution}
              error={categoryPieLTMError}
              formatter={(value)=>formatCurrency(value,company.currency)}
              containerHeight={330}
              titleKey={``}/>}
            {<SimplePieChart
              dataTestId={`category-pie-ytd-chart`}
              isLoading={categoryPieYTDLoading}
              items={categoryPieYTDChart.contribution}
              error={categoryPieYTDError}
              formatter={(value)=>formatCurrency(value,company.currency)}
              containerHeight={330}
              titleKey={``}/>}
          </div>
        </div>
        }
        {paramSubCategory !== null &&
        <div className='pieChartContainer'>
          <div className={`pieChart`}>
            {!categoryMonthlyError &&
            <div style={{fontSize: '1em'}} className='pieChartHeading'>
              <span className='pieChartHeadingLabel'> {paramSubCategory? paramSubCategory: paramCategory}
              <span className={`pieChartHeadingLabelAmount`}>
                {!subCategoryPieMonthlyLoading && !subCategoryPieMonthlyError && (subCategoryPieMonthlyChart.absolute >= 0 ?
                  <span>{formatCurrency(subCategoryPieMonthlyChart.absolute,company.currency)}
                   <p> as of - {`${subCategoryPieMonthlyChart.label}`}</p>
                  </span> :
                <span style={{color:'red'}}>{formatCurrency(subCategoryPieMonthlyChart.absolute,company.currency)}
                  <p> as of - {`${subCategoryPieMonthlyChart.label}`}</p>
                </span>)}
              </span>
              </span>
            </div>}
            {!categoryPieLTMError &&
            <div style={{fontSize: '1em'}} className='pieChartHeading'>
                <span className='pieChartHeadingLabel'> {paramSubCategory? paramSubCategory: paramCategory} <span
                  className={`pieChartHeadingLabelAmount`}>
                  {!subCategoryPieLTMLoading && !subCategoryPieLTMError && (subCategoryPieLTMChart.absolute >= 0 ?
                    <span>{formatCurrency(subCategoryPieLTMChart.absolute,company.currency)}
                    <p> as of Last Financial Year End - {`${subCategoryPieLTMChart.label}`}</p>
                    </span> :
                  <span style={{color: 'red'}}>
                    {formatCurrency(subCategoryPieLTMChart.absolute,company.currency)}
                    <p> as of Last Financial Year End - {`${subCategoryPieLTMChart.label}`}</p>
                  </span>)}
                </span>
                </span>
            </div>
            }
            {!categoryPieYTDError &&
            <div style={{fontSize: '1em'}} className='pieChartHeading'>
              <span className='pieChartHeadingLabel'> {paramSubCategory? paramSubCategory: paramCategory}
              <span className={`pieChartHeadingLabelAmount`}>
                {!subCategoryPieYTDLoading && !subCategoryPieYTDError && (subCategoryPieYTDChart.absolute >= 0 ?
                  <span>{formatCurrency(subCategoryPieYTDChart.absolute,company.currency)}
                    <p> as of Same Month Previous Year - {`${subCategoryPieYTDChart.label}`}</p>
                  </span>:
                <span style={{color:'red'}}>{formatCurrency(subCategoryPieYTDChart.absolute,company.currency)}
                  <p> as of Same Month Previous Year - {`${subCategoryPieYTDChart.label}`}</p>
                </span>)}
              </span>
              </span>
            </div>}
          </div>
          <div className={`pieChart`}>
            {<SimplePieChart
              dataTestId={`sub-category-pie-month-chart`}
              isLoading={subCategoryPieMonthlyLoading}
              items={subCategoryPieMonthlyChart.contribution}
              error={subCategoryPieMonthlyError}
              formatter={(value)=>formatCurrency(value,company.currency)}
              containerHeight={330}
              titleKey={``}/>}
            {<SimplePieChart
              dataTestId={`sub-category-pie-ltm-chart`}
              isLoading={subCategoryPieLTMLoading}
              items={subCategoryPieLTMChart.contribution}
              error={subCategoryPieLTMError}
              formatter={(value)=>formatCurrency(value,company.currency)}
              containerHeight={330}
              titleKey={``}/>}
            {<SimplePieChart
              dataTestId={`sub-category-pie-ytd-chart`}
              isLoading={subCategoryPieYTDLoading}
              items={subCategoryPieYTDChart.contribution}
              error={subCategoryPieYTDError}
              formatter={(value)=>formatCurrency(value,company.currency)}
              containerHeight={330}
              titleKey={``}/>}
          </div>
        </div>
        }
        {paramCategory !== 'BS Summary' && <hr className='separator-line' style={{margin:'2em 0'}}/>}
        {
          paramSubCategory &&
          <div>
            <div className={`writeups-container`}>
              <div className={`writeup-wrapper heading`}>Growth trends vs previous year</div>
              <div className={`writeup-wrapper heading`}>Recent growth trends</div>
            </div>
            {bsSubCatWriteupLoading && <div className={`align-loader`}><Loader dataTestId={`bs-subcat-writeup-loading`}  type={'pulse'} size={10}/></div>}
            {(!bsSubCatWriteupLoading && !bsSubCatWriteupError) && showWriteupBS(bsSubCatWriteup)}
            {bsSubCatWriteupError && <ErrorResponse dataTestId={`bs-subcat-writeup-error`} error={bsSubCatWriteupError}/>}
          </div>
        }
        {
          (paramSubCategory === null && paramCategory !=='BS Summary') &&
          <div>
            <div className={`writeups-container`}>
              <div className={`writeup-wrapper heading`}>Growth trends vs previous year</div>
              <div className={`writeup-wrapper heading`}>Recent growth trends</div>
            </div>
            {bsCatWriteupLoading && <div className={`align-loader`}><Loader dataTestId={`bs-cat-writeup-loading`} type={'pulse'} size={10}/></div>}
            {!bsCatWriteupLoading && !bsCatWriteupError && showWriteupBS(bsCatWriteup)}
            { bsCatWriteupError && <ErrorResponse dataTestId={`bs-cat-writeup-error`} error={bsCatWriteupError}/>}
          </div>
        }
        { paramSubCategory == null && paramCategory !=="BS Summary" && <hr className='separator-line' style={{marginBottom:'1.5em'}}/>}
        { !paramSubCategory && paramCategory !=="BS Summary" &&
        <div className={`line-charts-wrapper`}>
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>
              {paramCategory === 'BS Summary'?'Equity ': paramCategory} - Main Increases And Decreases Vs Previous Month
            </span>
          </div>}
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>
              {paramCategory === 'BS Summary'?'Equity ': paramCategory} - Main Increases And Decreases Vs Same Month Of Previous Year
            </span>
          </div>}
        </div>}
        {!paramSubCategory && paramCategory !=="BS Summary" &&
        <div className={`line-charts-wrapper`}>
          {<div className={`line-chart-container`}>
            <SimpleBarChart
              dataTestId={`category-difference-prev-month-chart`}
              dataValue={paramCategory}
              formatter={(value)=>formatCurrency(value,company.currency)}
              loading={ categoryDifferencePrevMonthLoading}
              error={categoryDifferencePrevMonthError}
              data={categoryDifferencePrevMonthChart}
              chartInfoCopy={infoCopies.bsSummaryOtherCategories.mainInceresesAndDecresesVsMonth}
            />
          </div>}
          {<div className={`line-chart-container`}>
            <SimpleBarChart
              dataTestId={`category-difference-prev-year-month-chart`}
              dataValue={paramCategory}
              formatter={(value)=>formatCurrency(value,company.currency)}
              loading={ categoryDifferencePrevYearMonthLoading}
              error={categoryDifferencePrevYearMonthError}
              data={categoryDifferencePrevYearMonthChart}
              chartInfoCopy={infoCopies.bsSummaryOtherCategories.mainInceresesAndDecresesVsPreviousYear}
            />
          </div>}
        </div>}

        <hr className='separator-line' style={{marginBottom:'1.5em'}}/>
        <div className={`line-charts-wrapper`}>
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>
              {paramSubCategory? paramSubCategory : paramCategory==='BS Summary'? 'Equity':paramCategory} -
              &nbsp;Last Twelve Months Trend Vs Previous Year</span>
          </div>}
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>
              {paramSubCategory? paramSubCategory : paramCategory==='BS Summary'? 'Equity':paramCategory}
              &nbsp;Evolution – Monthly</span>
          </div>}
        </div>
        <div className={`line-charts-wrapper`}>
          {<div className={`line-chart-container`}>
            <SimpleLineChart
              dataTestId={`ltm-prev-chart`}
              formatter={(value)=>formatCurrency(value,company.currency)}
              loading={lTMPrevChartLoading}
              error={lTMPrevChartError}
              data={lTMPrevChart}
              chartInfoCopy={infoCopies.equityLastTweleveMonthTrendVsPreviousYear}
            />
          </div>}
          {<div className={`line-chart-container`}>
            <HistoricTrendChart
              dataTestId={`historic-trend-chart`}
              categoryText={paramSubCategory? paramSubCategory : paramCategory==='BS Summary'? 'Equity':paramCategory}
              formatter={(value)=>formatCurrency(value,company.currency)}
              loading={historicTrendChartLoading}
              error={historicTrendChartError}
              data={historicTrendChart}
              chartInfoCopy={paramSubCategory?(infoCopies.bsSummaryOtherCategories.EvolutionMonthlyOne(paramSubCategory)): paramCategory&&(paramCategory==='BS Summary'? infoCopies.equityEvolutionMonthlyOne:infoCopies.bsSummaryOtherCategories.EvolutionMonthlyOne(paramCategory))}
            />
          </div>}
        </div>

        <hr className='separator-line' style={{marginBottom:'1.5em'}}/>
        <div className={`line-charts-wrapper`}>
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>
              {paramSubCategory? paramSubCategory:paramCategory ==='BS Summary'? 'Equity':paramCategory} Evolution - Monthly
            </span>
          </div>}
          {!paramSubCategory && <div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>
              Largest {paramSubCategory ? paramSubCategory : paramCategory ==='BS Summary'? 'Equity':paramCategory} Components - Historic Trend</span>
            </div>
          }
        </div>
        <div className={`line-charts-wrapper`}>
          {<div className={`line-chart-container`}>
            <LineChartDoubleYAxis
              dataTestId={`evolution-chart`}
              percentageOf={'% of Total Assets'}
              dataValue={paramSubCategory?paramSubCategory:paramCategory}
              showGrid
              formatter={(value)=>formatCurrency(value,company.currency)}
              loading={evolutionChartLoading}
              error={evolutionChartError}
              data={evolutionChart}
              chartInfoCopy={paramSubCategory?(infoCopies.bsSummaryOtherCategories.EvolutionMonthlyTwo(paramSubCategory)): paramCategory&&(paramCategory==='BS Summary'? infoCopies.equityEvolutionMonthlyTwo:infoCopies.bsSummaryOtherCategories.EvolutionMonthlyTwo(paramCategory))}
            />
          </div>}
          {!paramSubCategory && <div className={`line-chart-container`}>
            <LineChartMultipleSeries
              dataTestId={`historic-trend-components-chart`}
              formatter={(value)=>formatCurrency(value,company.currency)}
              loading={historicTrendComponentsChartLoading}
              error={historicTrendComponentsChartError}
              series={historicTrendComponentsChart}
              showGrid
              chartInfoCopy={paramCategory && paramCategory ==='BS Summary'?infoCopies.largestEquityComponent:infoCopies.bsSummaryOtherCategories.largestComponent(paramCategory)}
            />
          </div>}
        </div>
      </div>
    </div>
  )
}
export default withErrorBoundary(AnalysisBs);
