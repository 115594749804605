import React from 'react';
import CustomSelect from "../custom-select";

function CustomMonthYearSelect({monthOptions, selectedYear, selectedMonth, callbackMonthChange, callBackYearChange}) {
  const years = function(currentYear) {
      const monthsInAnYear = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

      let years = [], startYear = currentYear - 10;
      while (startYear <= currentYear) {
          years.push(`${startYear++}`);
      }
      return years.map((year) => {
        const indexOfSelectedMonth = monthsInAnYear.indexOf(`${selectedMonth}`);
        const selectedDate = new Date(year, indexOfSelectedMonth);
         if(new Date() < selectedDate) {
              return   ({
                  label: year,
                  selectable: false
              });
          } else {
              return  ({
                  label: year,
                  selectable: true
              });
          }
      });
  }

  return (
    <div style={{display:'flex'}}>
          <CustomSelect dataTestId={'monthSelect'} selectedValue={selectedMonth} options={monthOptions} callback={callbackMonthChange}/>
          <CustomSelect dataTestId={'yearSelect'} selectedValue={selectedYear} options={years(new Date().getFullYear())} callback={callBackYearChange}/>
    </div>
  );
}

export default CustomMonthYearSelect;
