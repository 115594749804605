import React from 'react';
import {formatCurrency} from "../analysis-helper";
import ErrorResponse from "../error-response/error-response";
import PnlSummaryBarChart from "../pnl-summary-bar-chart";
import WaterfallChart from "../waterfall-chart";
import Loader from "../reusables/Loader";
import parse from 'html-react-parser';
import LineChartDoubleYAxis from "../line-chart-double-y-axis";
import infoCopies from "../chart-info-copies";

const PnlSummary=({props})=>{
const {
    paramCategory,
    overallValueMonthLoading,
    overallValueMonth,
    company,
    overallValueMonthError,
    overallValueYTDLoading,
    overallValueYTDError,
    overallValueYTD,
    overallValueLTMLoading,
    overallValueLTMError,
    summaryMonth,
    summaryMonthLoading,
    summaryMonthError,
    summaryYTD,
    summaryYTDLoading,
    summaryYTDError,
    summaryLTM,
    summaryLTMLoading,
    summaryLTMError,
    changeEbitdaMonthLoading,
    changeEbitdaMonthError,
    changeEbitdaMonth,
    changeEbitdaYTDLoading,
    changeEbitdaYTDError,
    changeEbitdaYTD,
    overallValueLTM,
    overallEbitdaContriWriteupLoading,
    overallEbitdaContriWriteupError,
    overallEbitdaContriWriteup,
    overallEbitdaEvolutionMonthLoading,
    overallEbitdaEvolutionMonthError,
    overallEbitdaEvolutionMonth,
    changeEbitdaMarginMonthLoading,
    changeEbitdaMarginMonthError,
    changeEbitdaMarginMonth,
    changeEbitdaMarginYTDLoading,
    changeEbitdaMarginYTDError,
    changeEbitdaMarginYTD,
    overallEbitdaEvolutionLTMLoading,
    overallEbitdaEvolutionLTMError,
    overallEbitdaEvolutionLTM,
    salesEvolutionMonthLoading,
    salesEvolutionMonthError,
    salesEvolutionMonth,
    salesEvolutionLTMLoading,
    salesEvolutionLTMError,
    salesEvolutionLTM,
    costOfSalesEvolutionMonth,
    costOfSalesEvolutionMonthError,
    costOfSalesEvolutionMonthLoading,
    costOfSalesEvolutionLTMLoading,
    costOfSalesEvolutionLTMError,
    costOfSalesEvolutionLTM,
    grossProfitEvolutionMonthLoading,
    grossProfitEvolutionMonthError,
    grossProfitEvolutionMonth,
    grossProfitEvolutionLTMLoading,
    grossProfitEvolutionLTMError,
    grossProfitEvolutionLTM,
    sgnaEvolutionMonthLoading,
    sgnaEvolutionMonthError,
    sgnaEvolutionMonth,
    sgnaEvolutionLTMLoading,
    sgnaEvolutionLTMError,
    sgnaEvolutionLTM,
    netIncomeEvolutionMonthLoading,
    netIncomeEvolutionMonthError,
    netIncomeEvolutionMonth,
    netIncomeEvolutionLTMLoading,
    netIncomeEvolutionLTMError,
    netIncomeEvolutionLTM
    }= props;
  const currency= company.currency;

    return (
      <div>
        <div className={`line-charts-wrapper`}>
          {!overallValueMonthLoading && <div className='lineChartHeading' style={{textAlign: 'center'}}>
              <span className='lineChartHeadingLabel'>Month EBITDA {parseInt(overallValueMonth) >= 0 ?
                <span
                  className='pieChartHeadingLabelAmount'>{formatCurrency(parseInt(overallValueMonth), currency)}</span> :
                <span className='pieChartHeadingLabelAmount'
                      style={{color: 'red'}}>{formatCurrency(parseInt(overallValueMonth), currency)}</span>}
              </span>
            <span>{overallValueMonthError && <ErrorResponse dataTestId={'overall-value-month-error'} error={overallValueMonthError}/>}</span>
          </div>}
          {!overallValueYTDLoading && <div className='lineChartHeading' style={{textAlign: 'center'}}>
               <span className='lineChartHeadingLabel'>YTD EBITDA {parseInt(overallValueYTD) >= 0 ?
                 <span
                   className='pieChartHeadingLabelAmount'>{formatCurrency(parseInt(overallValueYTD), currency)}</span> :
                 <span className='pieChartHeadingLabelAmount'
                       style={{color: 'red'}}>{formatCurrency(parseInt(overallValueYTD), currency)}</span>}
              </span>
            <span>{overallValueYTDError && <ErrorResponse dataTestId={'overall-value-ytd-error'} error={overallValueYTDError}/>}</span>

          </div>}
          {!overallValueLTMLoading && <div className='lineChartHeading' style={{textAlign: 'center'}}>
               <span className='lineChartHeadingLabel'>LTM EBITDA {parseInt(overallValueLTM) >= 0 ?
                 <span
                   className='pieChartHeadingLabelAmount'>{formatCurrency(parseInt(overallValueLTM), currency)}</span> :
                 <span className='pieChartHeadingLabelAmount'
                       style={{color: 'red'}}>{formatCurrency(parseInt(overallValueLTM), currency)}</span>}
              </span>
            <span>{overallValueLTMError && <ErrorResponse dataTestId={'overall-value-ltm-error'} error={overallValueLTMError}/>}</span>
          </div>}
        </div>
        <hr className='separator-line' style={{margin: '2em 0 0em 0'}}/>
        <div className={`line-charts-wrapper`}>
          {<div className='lineChartHeading' style={{flex: '0 0 32%'}}>
            <span className='lineChartHeadingLabel'>Month P&L Summary</span>
          </div>}
          {<div className='lineChartHeading' style={{flex: '0 0 32%'}}>
            <span className='lineChartHeadingLabel'>YTD P&L Summary</span>
          </div>}
          {<div className='lineChartHeading' style={{flex: '0 0 32%'}}>
            <span className='lineChartHeadingLabel'>LTM P&L Summary</span>
          </div>}
        </div>

        <div className={`line-charts-wrapper`}>
          {<div className={`line-chart-container`} style={{flex: '0 0 32%'}}>
            <PnlSummaryBarChart
              dataTestId={'pnl-summary-month'}
              data={summaryMonth}
              loading={summaryMonthLoading}
              error={summaryMonthError}
              formatter={(value) => formatCurrency(value, currency)}
              chartInfoCopy={infoCopies.pnlSummary.monthYTDLTMSmall}
            />
          </div>}
          {<div className={`line-chart-container`} style={{flex: '0 0 32%'}}>
            <PnlSummaryBarChart
              dataTestId={'pnl-summary-ytd'}
              data={summaryYTD}
              loading={summaryYTDLoading}
              error={summaryYTDError}
              formatter={(value) => formatCurrency(value, currency)}
              chartInfoCopy={infoCopies.pnlSummary.monthYTDLTMSmall}
            />
          </div>}
          {<div className={`line-chart-container`} style={{flex: '0 0 32%'}}>
            <PnlSummaryBarChart
              dataTestId={'pnl-summary-ltm'}
              data={summaryLTM}
              loading={summaryLTMLoading}
              error={summaryLTMError}
              formatter={(value) => formatCurrency(value, currency)}
              chartInfoCopy={infoCopies.pnlSummary.monthYTDLTMSmall}
            />
          </div>}
        </div>
        <hr className='separator-line' style={{margin: '2em 0 0em 0'}}/>
        {/*Pnl generation charts*/}

        <div className={`line-charts-wrapper`}>
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>Change in Month EBITDA From Last Year</span>
          </div>}
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>Change in YTD EBITDA From Last Year</span>
          </div>}
        </div>
        <div className={`line-charts-wrapper`}>
          <div className={`line-chart-container`}>
            <WaterfallChart
              dataTestId={'change-ebitda-month'}
              dataValue={paramCategory}
              formatter={(value) => formatCurrency(value, currency)}
              loading={changeEbitdaMonthLoading}
              error={changeEbitdaMonthError}
              data={changeEbitdaMonth}
              chartInfoCopy={infoCopies.pnlSummary.changeInMonthEBITDAFromLastYear}
            />
          </div>
          <div className={`line-chart-container`}>
            <WaterfallChart
              dataTestId={'change-ebitda-ytd'}
              dataValue={paramCategory}
              formatter={(value) => formatCurrency(value, currency)}
              loading={changeEbitdaYTDLoading}
              error={changeEbitdaYTDError}
              data={changeEbitdaYTD}
              chartInfoCopy={infoCopies.pnlSummary.changeInYtdEBITDAFromLastYear}
            />
          </div>
        </div>

        <div className={`line-charts-wrapper`}>
          {overallEbitdaContriWriteupLoading &&
           <div className={`align-loader`}>
             <Loader dataTestId='overall-ebitda-contri-writeup-monthly-loading' type={'pulse'} size={10}/>
           </div>}
          <div className={`writeups-container`} style={{width: '50%'}}>
            {(!overallEbitdaContriWriteupLoading && !overallEbitdaContriWriteupError) &&
             <div className='writeup-wrapper'>
                {parse(`${overallEbitdaContriWriteup?.ebitda_monthly}`)}
             </div>
            }
          </div>
          <div className={`writeups-container`} style={{width: '50%'}}>
            {(!overallEbitdaContriWriteupLoading && !overallEbitdaContriWriteupError) &&
             <div className='writeup-wrapper'>
               {parse(`${overallEbitdaContriWriteup.ebitda_yearly}`)}
             </div>
            }
          </div>
          {overallEbitdaContriWriteupError && <ErrorResponse dataTestId={'overall-ebitda-contri-writeup-error'} error={overallEbitdaContriWriteupError}/>}
        </div>

        <hr className='separator-line' style={{margin: '2em 0 0em 0'}}/>

        <div className={`line-charts-wrapper`}>
          <div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>Change in Month EBITDA Margin From Last Year</span>
          </div>
          <div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>Change in YTD EBITDA Margin From Last Year</span>
          </div>
        </div>

        <div className={`line-charts-wrapper`}>
          <div className={`line-chart-container`}>
            <WaterfallChart
              dataTestId={`change-ebitda-margin-month`}
              dataValue={paramCategory}
              formatter={(value) => `${parseFloat(value).toFixed(1)} %`}
              loading={changeEbitdaMarginMonthLoading}
              error={changeEbitdaMarginMonthError}
              data={changeEbitdaMarginMonth}
              chartInfoCopy={infoCopies.pnlSummary.changeInMonthEBITDAMarginFromLastYear}
            />
          </div>
          <div className={`line-chart-container`}>
            <WaterfallChart
              dataTestId={`change-ebitda-margin-ytd`}
              dataValue={paramCategory}
              formatter={(value) => `${parseFloat(value).toFixed(1)} %`}
              loading={changeEbitdaMarginYTDLoading}
              error={changeEbitdaMarginYTDError}
              data={changeEbitdaMarginYTD}
              chartInfoCopy={infoCopies.pnlSummary.changeInYTDEBITDAMarginFromLastYear}
            />
          </div>
        </div>

        <div className={`line-charts-wrapper`}>
          {overallEbitdaContriWriteupLoading &&
           <div className={`align-loader`}>
             <Loader dataTestId='overall-ebitda-contri-writeup-margin-monthly-loading' type={'pulse'} size={10}/></div>}
          {<div className={`writeups-container`} style={{width: '50%'}}>
            {(!overallEbitdaContriWriteupLoading && !overallEbitdaContriWriteupError) &&
             <div className='writeup-wrapper'>
               {parse(`${overallEbitdaContriWriteup.ebitda_margin_monthly}`)}
             </div>
            }
          </div>}
          {<div className={`writeups-container`} style={{width: '50%'}}>
            {(!overallEbitdaContriWriteupLoading && !overallEbitdaContriWriteupError) &&
             <div className='writeup-wrapper'>
               {parse(`${overallEbitdaContriWriteup.ebitda_margin_yearly}`)}
             </div>
            }
          </div>}
          {overallEbitdaContriWriteupError && <ErrorResponse dataTestId='overall-ebitda-contri-writeup-error' error={overallEbitdaContriWriteupError}/>}
        </div>
        <hr className='separator-line' style={{margin: '2em 0 0em 0'}}/>

        <div className={`line-charts-wrapper`}>
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>EBITDA Evolution - Monthly</span>
          </div>}
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>EBITDA Evolution - LTM</span>
          </div>}
        </div>


        <div className={`line-charts-wrapper`}>
          {<div className={`line-chart-container`}>
            <LineChartDoubleYAxis
              dataTestId={`overall-ebitda-evolution-month`}
              percentageOf={'EBITDA as % of sales'}
              dataValue={'EBITDA'}
              showGrid
              formatter={(value) => formatCurrency(value, currency)}
              loading={overallEbitdaEvolutionMonthLoading}
              error={overallEbitdaEvolutionMonthError}
              data={overallEbitdaEvolutionMonth}
              chartInfoCopy={infoCopies.allMonthlyCharts('EBITDA')}
            />
          </div>}
          {<div className={`line-chart-container`}>
            <LineChartDoubleYAxis
              dataTestId={`overall-ebitda-evolution-ltm`}
              percentageOf={'EBITDA as % of sales'}
              dataValue={'EBITDA'}
              showGrid
              formatter={(value) => formatCurrency(value, currency)}
              loading={overallEbitdaEvolutionLTMLoading}
              error={overallEbitdaEvolutionLTMError}
              data={overallEbitdaEvolutionLTM}
              chartInfoCopy={infoCopies.allLtmCharts('EBITDA')}
            />
          </div>}
        </div>
        <hr className='separator-line' style={{margin: '2em 0 0em 0'}}/>
        <div className={`line-charts-wrapper`}>
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>Sales Evolution - Monthly</span>
          </div>}
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>Sales Evolution - LTM</span>
          </div>}
        </div>


        <div className={`line-charts-wrapper`}>
            <div className={`line-chart-container`}>
            <LineChartDoubleYAxis
              dataTestId={`sales-evolution-month`}
              percentageOf={'Sales as % of sales'}
              dataValue={'Sales'}
              showGrid
              showLegend
              formatter={(value) => formatCurrency(value, currency)}
              loading={salesEvolutionMonthLoading}
              error={salesEvolutionMonthError}
              data={salesEvolutionMonth}
              chartInfoCopy={infoCopies.salesMonthly}
            />
          </div>
          <div className={`line-chart-container`}>
            <LineChartDoubleYAxis
              dataTestId={`sales-evolution-ltm`}
              percentageOf={'Sales as % of sales'}
              dataValue={'Sales'}
              showGrid
              showLegend
              formatter={(value) => formatCurrency(value, currency)}
              loading={salesEvolutionLTMLoading}
              error={salesEvolutionLTMError}
              data={salesEvolutionLTM}
              chartInfoCopy={infoCopies.salesLTM}
            />
          </div>
        </div>
        {(costOfSalesEvolutionMonth.length > 0 || costOfSalesEvolutionMonthError) &&
         <hr className='separator-line' style={{margin: '2em 0 0em 0'}}/>}
        {
          (costOfSalesEvolutionMonth.length > 0 || costOfSalesEvolutionMonthError) &&
          <div className={`line-charts-wrapper`}>
            {<div className='lineChartHeading'>
              <span className='lineChartHeadingLabel'>Cost of Sales Evolution - Monthly </span>
            </div>}
            {<div className='lineChartHeading'>
              <span className='lineChartHeadingLabel'>Cost of Sales Evolution - LTM</span>
            </div>}
          </div>
        }
        {(costOfSalesEvolutionMonth.length > 0 || costOfSalesEvolutionMonthError) &&
         <div className={`line-charts-wrapper`}>
           <div className={`line-chart-container`}>
             <LineChartDoubleYAxis
               dataTestId={`cost-of-sales-evolution-month`}
               percentageOf={'Cost of Sales as % of sales'}
               dataValue={'Cost of Sales'}
               showGrid
               showLegend
               formatter={(value) => formatCurrency(value, currency)}
               loading={costOfSalesEvolutionMonthLoading}
               error={costOfSalesEvolutionMonthError}
               data={costOfSalesEvolutionMonth}
               chartInfoCopy={infoCopies.allMonthlyCharts('Cost of Sales')}
             />
           </div>
           <div className={`line-chart-container`}>
             <LineChartDoubleYAxis
               dataTestId={`cost-of-sales-evolution-ltm`}
               percentageOf={'Cost of Sales as % of sales'}
               dataValue={'Cost of Sales'}
               showGrid
               showLegend
               formatter={(value) => formatCurrency(value, currency)}
               loading={costOfSalesEvolutionLTMLoading}
               error={costOfSalesEvolutionLTMError}
               data={costOfSalesEvolutionLTM}
               chartInfoCopy={infoCopies.allLtmCharts('Cost of Sales')}
             />
           </div>
         </div>}

        <hr className='separator-line' style={{margin: '2em 0 0em 0'}}/>
        <div className={`line-charts-wrapper`}>
            <div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>Gross Profit Evolution - Monthly</span>
          </div>
          <div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>Gross Profit Evolution - LTM</span>
          </div>
        </div>


        <div className={`line-charts-wrapper`}>
          <div className={`line-chart-container`}>
            <LineChartDoubleYAxis
              dataTestId={`gross-profit-evolution-month`}
              percentageOf={'Gross Profit as % of sales'}
              dataValue={'Gross Profit'}
              showGrid
              formatter={(value) => formatCurrency(value, currency)}
              loading={grossProfitEvolutionMonthLoading}
              error={grossProfitEvolutionMonthError}
              data={grossProfitEvolutionMonth}
              chartInfoCopy={infoCopies.allMonthlyCharts('Gross Profit')}
            />
          </div>
          <div className={`line-chart-container`}>
            <LineChartDoubleYAxis
              dataTestId={`gross-profit-evolution-ltm`}
              percentageOf={'Gross Profit as % of sales'}
              dataValue={'Gross Profit'}
              showGrid
              formatter={(value) => formatCurrency(value, currency)}
              loading={grossProfitEvolutionLTMLoading}
              error={grossProfitEvolutionLTMError}
              data={grossProfitEvolutionLTM}
              chartInfoCopy={infoCopies.allLtmCharts('Gross Profit')}
            />
          </div>
        </div>
        <hr className='separator-line' style={{margin: '2em 0 0em 0'}}/>

        <div className={`line-charts-wrapper`}>
          <div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>SG&A Evolution - Monthly</span>
          </div>
          <div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>SG&A Evolution - LTM</span>
          </div>
        </div>


        <div className={`line-charts-wrapper`}>
          <div className={`line-chart-container`}>
            <LineChartDoubleYAxis
              dataTestId={`sgna-evolution-month`}
              percentageOf={'SG&A as % of sales'}
              dataValue={'SG&A'}
              showGrid
              showLegend
              formatter={(value) => formatCurrency(value, currency)}
              loading={sgnaEvolutionMonthLoading}
              error={sgnaEvolutionMonthError}
              data={sgnaEvolutionMonth}
              chartInfoCopy={infoCopies.allMonthlyCharts('SG&A')}
            />
          </div>
          <div className={`line-chart-container`}>
            <LineChartDoubleYAxis
              dataTestId={`sgna-evolution-ltm`}
              percentageOf={'SG&A as % of sales'}
              dataValue={'SG&A'}
              showGrid
              showLegend
              formatter={(value) => formatCurrency(value, currency)}
              loading={sgnaEvolutionLTMLoading}
              error={sgnaEvolutionLTMError}
              data={sgnaEvolutionLTM}
              chartInfoCopy={infoCopies.allLtmCharts('SG&A')}
            />
          </div>
        </div>
        <hr className='separator-line' style={{margin: '2em 0 0em 0'}}/>

        <div className={`line-charts-wrapper`}>
          <div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>Net Income Evolution - Monthly</span>
          </div>
          <div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>Net Income Evolution - LTM</span>
          </div>
        </div>


        <div className={`line-charts-wrapper`}>
          <div className={`line-chart-container`}>
            <LineChartDoubleYAxis
              dataTestId={`net-income-evolution-month`}
              percentageOf={'Net Income as % of sales'}
              dataValue={'Net Income'}
              showGrid
              formatter={(value) => formatCurrency(value, currency)}
              loading={netIncomeEvolutionMonthLoading}
              error={netIncomeEvolutionMonthError}
              data={netIncomeEvolutionMonth}
              chartInfoCopy={infoCopies.allMonthlyCharts('Net Income')}
            />
          </div>
          <div className={`line-chart-container`}>
            <LineChartDoubleYAxis
              dataTestId={`net-income-evolution-ltm`}
              percentageOf={'Net Income as % of sales'}
              dataValue={'Net Income'}
              showGrid
              formatter={(value) => formatCurrency(value, currency)}
              loading={netIncomeEvolutionLTMLoading}
              error={netIncomeEvolutionLTMError}
              data={netIncomeEvolutionLTM}
              chartInfoCopy={infoCopies.allLtmCharts('Net Income')}
            />
          </div>
        </div>
      </div>
    )
}
export default PnlSummary;
