import React from 'react';
import notify from '../notify/notify';
import {Mixpanel} from "../mixpanel";


const buttonStyle={
    border: '0 none',
    background: 'transparent',
    fontSize: '1em',
    color: '#305A80',
    borderBottom: '1px solid #305A80',
    padding: '0',
    margin: '0 0.5em',
    cursor: 'pointer',
}
export default function withErrorBoundary(ProtectedComponent) {
    function reloadPage() {
        window.location.reload(false);
    }

    return class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                error: null,
                errorInfo: null
            };
        }

        componentDidCatch(error, errorInfo) {
            // Catch errors in any components below and re-render with error message
            this.setState({
                error: error,
                errorInfo: errorInfo
            });

            Mixpanel.track('Error',{'error':error.message,'errorStack':error.stack});
        }

        render() {
            if (this.state.errorInfo) {
                notify({type: 'error', message: 'Sorry, we did not expect this, but something went wrong. We\'re on it already'});
                return (<div style={{margin: '0 auto',
                    textAlign: 'center',
                    paddingTop: '3em'}}>
                    <p> Something went wrong. Don't worry, we're already on it. </p><p>You can <button style={buttonStyle} onClick={reloadPage}>Reload</button> the page to continue.</p>
                </div>)
            }
            return <ProtectedComponent {...this.props} />
        }
    }
}