import React from "react";
import {formatCurrency} from "../analysis-helper";

const dummyData= [
  {name:'Total Sales',values:{actual:12390555,prev:19486544,diff:7090945,percent:-36.4}},
  {name:'Cost of Sales',values:{actual:12390555,prev:19486544,diff:7090945,percent:-36.4}},
  {name:'Cost of Sales % of Sales',values:{actual:12390555,prev:19486544,diff:7090945,percent:-36.4}},
  {name:'Gross Profit',values:{actual:12390555,prev:19486544,diff:7090945,percent:26.4}},
  {name:'Gross Margin',values:{actual:12390555,prev:19486544,diff:7090945,percent:26}},
  {name:'Total SG&A',values:{actual:12390555,prev:19486544,diff:7090945,percent:-36.4}},
  {name:'Total SG&A % of Sales',values:{actual:12390555,prev:19486544,diff:7090945,percent:-36.4}},
  {name:'EBITDA',values:{actual:12390555,prev:19486544,diff:-7090945,percent:26}},
  {name:'EBITDA Margin',values:{actual:-12390555,prev:19486544,diff:7090945,percent:-36.4}},
  {name:'Net Income',values:{actual:12390555,prev:-19486544,diff:7090945,percent:-36.4}},
  {name:'Net Income Margin',values:{actual:12390555,prev:19486544,diff:7090945,percent:-36.4}},
];

const formatCurrencyAndCheckSign=(currency,company)=>{
  const formattedCurrency = formatCurrency(currency,company.currency);
  if(formattedCurrency.includes('-')){
    return(
      <span style={{color:'red'}}>({formattedCurrency.split('-')[1].substr(1,formattedCurrency.length-1)})</span>
    )
  }
  return formattedCurrency.substr(1,formattedCurrency.length-1);
}
const formatPercentAndCheckSign=(value)=>{
  if(value === 'N/A'){
    return (
      <span>{value}</span>
    )
  }
  const formattedValue = parseFloat(value).toFixed(1)+'%';
  if(formattedValue.includes('-')){
    return(
      <span style={{color:'red'}}>({formattedValue.split('-')[1]})</span>
    )
  }
  return formattedValue;
}

const FinancialPerformanceSummary = ({company})=>{

  return(
    <div className='report-financial-table-wrapper'>
      <h3 className="table-heading lineChartHeading"><span className='lineChartHeadingLabel'>Financial Performance Summary</span></h3>
      <table className='report-financial-table financials-table'>
        <thead>
        <tr>
          <th>
            Consolidated Financials({formatCurrency(0, company.currency).substring(0, 1)})
          </th>
          <th>
            Actual
          </th>
          <th>
            Previous
          </th>
          <th>
            Diff
          </th>
          <th>
            Diff
          </th>
        </tr>
        </thead>
        <tbody>
          {
            dummyData.map(row=>{
              return (
                <tr>
                  <td>{row.name}</td>
                  <td className="number">{formatCurrencyAndCheckSign(row.values.actual,company)}</td>
                  <td className="number">{formatCurrencyAndCheckSign(row.values.prev,company)}</td>
                  <td className="number">{formatCurrencyAndCheckSign(row.values.diff,company)}</td>
                  <td className="number">{formatPercentAndCheckSign(row.values.percent)}</td>
                </tr>
              )
            })}
        </tbody>
      </table>
    </div>
  )
}
export default FinancialPerformanceSummary;