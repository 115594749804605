const infoCopies = {
  keyInsights: {
    cashflowGenerationMonth: [`This chart is a representation of the indirect cashflow statement.`,`It shows the major cash inflows and outflows in the month.`,`It also explains why Net Income is different from the cash actually generated.`],
    cashflowGenerationYtd: [`This chart is a representation of the indirect cashflow statement.`,` It shows the major cash inflows and outflows in the year-to-date.`,`It also explains why Net Income is different from the cash actually generated.`],
    cfFromOperationsPreviousTwelveMonths: [`This chart shows how much cash the OPERATIONS of the company have generated or consumed every month for the last twelve months.`,`It is the equivalent of Cash Burn from operations.`,` It excludes cashflow from INVESTING (e.g. buying machinery) and cashflow from FINANCING (e.g. cash from or to the bank and shareholders)`],
    cashflowGenerationPreviousTwelveMonths: [`This chart shows how much cash the company has generated or consumed every month for the last twelve months.`,` It includes cashflow from OPERATIONS, INVESTING and FINANCING. It is basically the change in cash from one month to the next.`],
    cashEvolutionMonthly:[`This chart shows the cash balance at the end of each month.`]
  },
  pnlSummary:{
    changeInMonthEBITDAFromLastYear:[`This charts shows why EBITDA changed from the same month of the previous year.`,`It shows changes in Sales, Cost of Sales and SG&A.`],
    changeInYtdEBITDAFromLastYear:[`This charts shows why EBITDA changed from the same year-to-date of the previous year.`,`It shows changes in Sales, Cost of Sales and SG&A.`],
    changeInMonthEBITDAMarginFromLastYear:[`This charts shows why EBITDA MARGIN changed from the same month of the previous year.`,`
It shows and quantifies the effect Cost of Sales and SG&A had on EBITDA MARGIN.`],
      changeInYTDEBITDAMarginFromLastYear:[`This charts shows why EBITDA MARGIN changed from the same year-to-date of the previous year.`,`It shows and quantifies the effect Cost of Sales and SG&A had on EBITDA MARGIN.`],
      monthYTDLTMSmall:[`This chart is a quick summary representation of the company’s Profit and Loss account.`,`It shows Sales, Gross Profit, EBITDA, and Net Income.`]
  },
  allMonthlyCharts: (chartName) => [`This chart shows ${chartName} and ${chartName} as a percentage of sales on a monthly basis.`],
  allLtmCharts: (chartName)=> [`This chart shows ${chartName} and ${chartName} as a percentage of sales on a Last-Twelve-Months basis (i.e. every date in the chart captures the past 12 months).`,
      `LTM charts give visibility of how the business is doing on a “full year” basis and it may be a good indicator of how the company will perform at the end of the financial year.`,`LTM charts account for seasonality as they always incorporate a summer, winter, etc... `],
    salesMonthly:  [`This chart shows SALES on a monthly basis.`],
    salesMonthlySubcategory: (chartName) =>  [`This chart shows ${chartName} on a monthly basis.`],
    salesLTM:  [`This chart shows SALES on a Last-Twelve-Months basis (i.e. every date in the chart captures the past 12 months).`,
        `LTM charts give visibility of how the business is doing on a “full year” basis and it may be a good indicator of how the company will perform at the end of the financial year. `,
    `LTM charts account for seasonality as they always incorporate a summer, winter, etc…`
    ],
    majorInceresesAndDecresesFromLastYearMonth: [`This chart shows the major increase and decreases when compared to the same month of the previous year.`,`Smaller changes may be grouped in the “All Other Increasing” or “All Other Decreasing” bars. `],
    majorInceresesAndDecresesFromLastYearYTD: [`This chart shows the major increase and decreases when compared to the same year-to-date of the previous year.`,`Smaller changes may be grouped in the “All Other Increasing” or “All Other Decreasing” bars.`],
    lastTwelveMonthTrendVsYear:[`This chart shows the evolution of the past twelve months and compares it with the same period of the previous year.`,`It is a good visual to assess whether a category is growing or declining versus the previous year.`],
    bsSummary: [`This charts is simple representation of the Balance Sheet of the company.`,`It shows Assets, Liabilities and Equity (all as a percentage of Assets).`],
    balanceSheetRepresentation: [`This charts shows the main components of the company’s Assets, Liabilities and Equity.`,`Smaller items are grouped into “All Other A” (for assets), “All Other L” (for liabilities) or “All Other E” (for equity).`],
    equityInceresesAndDecresesVsMonth: [`This chart shows the reasons why equity changed from the previous month.`,`The change in Equity is explained by changes in Current Assets, Non-Current Assets, Current Liabilities and Long Term Liabilities. `],
    equityInceresesAndDecresesVsPreviousYear: [`This chart shows the reasons why equity changed from the same month of the previous year.`,`The change in Equity is explained by changes in Current Assets, Non-Current Assets, Current Liabilities and Long Term Liabilities.`],
    equityLastTweleveMonthTrendVsPreviousYear: [`This chart shows the evolution of the past twelve months and compares it with the same period of the previous year.`,`It is a good visual to assess whether a category is growing or declining versus the previous year. `],
    equityEvolutionMonthlyOne: [`This chart shows the Equity balance on a monthly basis.`],
    equityEvolutionMonthlyTwo: [`This chart shows the Equity balance on a monthly basis.`,`It also shows Equity as a percentage of Total Assets (a low Equity to Total Assets ratio may be a sign of a weak Balance Sheet). `],
    largestEquityComponent: [`This charts shows the top Equity components and their historic evolution.`],
    bsSummaryOtherCategories:{
        mainInceresesAndDecresesVsMonth:[`This chart shows the major increase and decreases when compared to the previous month.`,`Smaller changes may be grouped in the “All Other Increasing” or “All Other Decreasing” bars.`],
        mainInceresesAndDecresesVsPreviousYear:[`This chart shows the major increase and decreases when compared to the same month of the previous year (i.e. one year ago).`,`Smaller changes may be grouped in the “All Other Increasing” or “All Other Decreasing” bars.`],
        LastTweleveMonthTrendVsPreviousYear: [`This chart shows the evolution of the past twelve months and compares it with the same period of the previous year.`,`It is a good visual to assess whether a category is growing or declining versus the previous year.`],
        EvolutionMonthlyOne: (chartName) => [`This chart shows the  ${chartName} balance on a monthly basis.`],
        EvolutionMonthlyTwo:  (chartName) => [`This chart shows the ${chartName} balance on a monthly basis.`,`It also shows ${chartName} as a percentage of Total Assets (a low Equity to Total Assets ratio may be a sign of a weak Balance Sheet).`],
        largestComponent:  (chartName) => [`This charts shows the top ${chartName} components and their historic evolution.`],
    },
    cfSummary:{
       cfGenration: [`This chart shows a quick representation of the Cashflow Statement.`,`It shows, CF from Operations, CF from Investing, Free Cashflow and CF from Financing.`,`You can quickly assess how cash has been generated and used. `],
       cashflowGenerationMonth: [`This chart is a representation of the indirect cashflow statement.`,`It shows the major cash inflows and outflows in the month.`,`It also explains why Net Income is different from the cash actually generated. `],
       cashflowGenerationYtd: [`This chart is a representation of the indirect cashflow statement.`,`It shows the major cash inflows and outflows in the year-to-date.`,`It also explains why Net Income is different from the cash actually generated. `],
       cashflowGenerationPreviousTwelveMonths: [`This chart shows how much cash the company has generated or consumed every month for the last twelve months..`,`It includes cashflow from OPERATIONS, INVESTING and FINANCING.`,`It is basically the change in cash from one month to the next.`],
       cashflowGenerationMonthTwo: [`This chart shows how much cash the company has generated or consumed every month historically.`,`It includes cashflow from OPERATIONS, INVESTING and FINANCING.`,`It is basically the change in cash from one month to the next.`],
       cashflowGenerationMonthThree: [`This chart shows how much cash the company has generated or consumed every month historically.`,`It includes cashflow from OPERATIONS, INVESTING and FINANCING.`,`It is basically the change in cash from one month to the next.`],
       cashflowGenerationLTM: [`This chart shows how much CASH the company has generated or consumed historically on a last-twelve month basis (i.e. every date in the chart captures the past 12 months).`,`LTM charts give visibility of how the business is doing on a “full year” basis and it may be a good indicator of how the company will perform at the end of the financial year.`,`LTM charts account for seasonality as they always incorporate a summer, winter, etc… `],
       freeCashFlowGenrationMonth: [`This chart shows how much FREE CASHFLOW the company has generated or consumed every month historically.`,`FREE CASHLOW includes cashflow from OPERATIONS and cashflow from INVESTING.`,`It excludes cashflow from FINANCING and that is why Free Cashflow is also called Cashflow before Financing (i.e. before cash from/to banks and shareholders).`],
       freeCashFlowGenrationLTM: [`This chart shows how much FREE CASHFLOW the company has generated or consumed historically on a last-twelve month basis (i.e. every date in the chart captures the past 12 months).`,`FREE CASHLOW includes cashflow from OPERATIONS and cashflow from INVESTING and excludes cashflow from FINANCING.`,`LTM charts give visibility of how the business is doing on a “full year” basis and it may be a good indicator of how the company will perform at the end of the financial year.`,
        `LTM charts account for seasonality as they always incorporate a summer, winter, etc…`]
    },
    cashFlowSubcategories:{
        mainCashOutflowsInflowsMonth: [`This chart shows the major inflows and outflows in the month.`,`Smaller changes may be grouped in the “All Other Increasing” or “All Other Decreasing” bars.`],
        mainCashOutflowsInflowsYTD: [`This chart shows the major inflows and outflows in the year-to-date.`,`Smaller changes may be grouped in the “All Other Increasing” or “All Other Decreasing” bars.`],
        previousTwelveMonths: (category) => [`This chart shows how much CF from ${category} the company has generated or consumed every month for the last twelve months.`],
        monthlyOne: (category) => [`This chart shows how much CF from ${category} the company has generated or consumed every month historically.`],
        monthlyTwo: (category) =>[`This chart shows how much CF from ${category} the company has generated or consumed every month historically.`],
        ltm: (category) => [`This chart shows how much CF from ${category} the company has generated or consumed historically on a last-twelve month basis (i.e. every date in the chart captures the past 12 months).`,
        `LTM charts give visibility of how the business is doing on a “full year” basis and it may be a good indicator of how the company will perform at the end of the financial year.`,
        `LTM charts account for seasonality as they always incorporate a summer, winter, etc…`]
    }
}


export default infoCopies;
