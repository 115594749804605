import React, {useEffect, useState} from 'react';
import useGet from "../hooks/use-get";
import {useSelector} from "react-redux";
import SubHeader from "../subHeader";
import SimpleBarChart from "../simple-bar-chart";
import {
  formatCurrency,
  mapCategoryForDifferencesBarChart,
  mapEvolution,
  mapHistoricTrend,
  waterfallChartMap,
} from '../analysis-helper';
import LineChartDoubleYAxis from "../line-chart-double-y-axis";
import WaterfallChart from "../waterfall-chart";
import BarChartOneCategory from "../bar-chart-one-category";
// import SimpleLineChart from "../simple-line-chart";
import SimpleAreaChart from "../simple-area-chart";
import './analysis-cf.css';
import Loader from "../reusables/Loader";
import CfGenerationWaterfallChart from "../cf-generation-waterfall-chart";
import ErrorResponse from "../error-response/error-response";
import infoCopies from "../chart-info-copies";
import withErrorBoundary from "../error/ErrorBoundary";


const AnalysisCf = ()=>{
  //const location =useLocation();
 // const query = new URLSearchParams(location.search);
  const company = useSelector(state => state.selectedCompany);
  const selectedDate = useSelector(state => state.endDate);
  const paramSection = useSelector(state => state.selectedNavigation.sheet);
  const paramCategory = useSelector(state => state.selectedNavigation.category);
  const paramSubCategory = useSelector(state => state.selectedNavigation.subCategory);

  const [lTMPrevChart,setLTMPrevChart]=useState([]);
  const [historicTrendChart,setHistoricTrendChart]=useState([]);

  const [evolutionMonthChart,setEvolutionMonthChart]=useState([]);
  const [evolutionLTMChart,setEvolutionLTMChart]=useState([]);

  const [categoryCfGenerationMonthlyChart,setCategoryCfGenerationMonthlyChart]=useState({});
  const [categoryCfGenerationYTDChart,setCategoryCfGenerationYTDChart]=useState({});

  const [contributorsYearlyChart,setContributorsYearlyChart]=useState({});
  const [contributorsMonthlyChart,setContributorsMonthlyChart]=useState({});

  const [absContributionMonth,setAbsContributionMonth]=useState('');
  const [absContributionLTM,setAbsContributionLTM]=useState('');
  const [absContributionYTD,setAbsContributionYTD]=useState('');

  const [cfGenerationHistoricTrendChart,setCfGenerationHistoricTrendChart]=useState({});
  const [cfGenerationChartTwelveMonths,setCfGenerationChartTwelveMonths]=useState({});

  const [cfGenerationLTMEvolutionChart,setCfGenerationLTMEvolutionChart]=useState({});

  const [freeCashFlowEvolutionMonthChart,setFreeCashFlowEvolutionMonthChart] =useState({});
  const [freeCashFlowEvolutionLTMChart,setFreeCashFlowEvolutionLTMChart] =useState({});

  const [cashflowGenerationMonthWriteup,setCashflowGenerationMonthWriteup]=useState({});
  const [cashflowGenerationYTDWriteup,setCashflowGenerationYTDWriteup]=useState({});

  const [cashflowGenerationMonthOverall,setCashflowGenerationMonthOverall]=useState({});
  const [cashflowGenerationYTDOverall,setCashflowGenerationYTDOverall]=useState({});
  const [cashflowGenerationLTMOverall,setCashflowGenerationLTMOverall]=useState({});

  const [overallValueMonth,setOverallValueMonth]=useState('');
  const [overallValueLTM,setOverallValueLTM]=useState('');
  const [overallValueYTD,setOverallValueYTD]=useState('');

  const getDate=(date)=> `${date.month} ${date.year.slice(-2)}`;

  const [lTMPrevChartResponse, lTMPrevChartLoading, lTMPrevChartError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/ltm-vs-previous${paramSubCategory?'':'/category'}?`+
         `companyId=${company.companyId}`+
         `${paramSubCategory?`&subCategory=${paramSubCategory?.replace(/&/ig,'%26')}`:''}`+
         `&category=${paramCategory?.replace(/&/ig,'%26')}`+
         `&date=${getDate(selectedDate)}&sheet=CF`,
    skip: paramCategory === 'CF Summary'
  });

  const [historicTrendChartResponse, historicTrendChartLoading, historicTrendChartError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/historic-data${paramSubCategory?'':'/category/summed-over-month'}?`+
         `companyId=${company.companyId}`+
         `${paramSubCategory?`&subCategory=${paramSubCategory?.replace(/&/ig,'%26')}`:''}`+
         `&category=${paramCategory?.replace(/&/ig,'%26')}`+
         `&date=${getDate(selectedDate)}&sheet=CF`,
    skip: paramCategory === 'CF Summary'
  });


  const [categoryCfGenerationMonthlyResponse, categoryCfGenerationMonthlyLoading, categoryCfGenerationMonthlyError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/cfGeneration?companyId=${company.companyId}`+
         `&category=${paramCategory?.replace(/&/ig,'%26')}`+
         `&date=${getDate(selectedDate)}`+
         `&breakdown=MONTH`,
    skip: paramCategory !== 'CF Summary'});

  const [cfGenerationChartTwelveMonthsResponse, cfGenerationChartTwelveMonthsLoading, cfGenerationChartTwelveMonthsError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/cfgeneration/ltm?companyId=${company.companyId}`+
         `&date=${getDate(selectedDate)}`,
    skip: paramCategory !== 'CF Summary'});

  const [categoryCfGenerationYTDResponse, categoryCfGenerationYTDLoading, categoryCfGenerationYTDError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/cfGeneration?companyId=${company.companyId}`+
         `&category=${paramCategory?.replace(/&/ig,'%26')}`+
         `&date=${getDate(selectedDate)}`+
         `&breakdown=YTD`,
    skip: paramCategory !== 'CF Summary'});

  const [contributorsMonthlyResponse, contributorsMonthlyLoading, contributorsMonthlyError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/contributors/cf?companyId=${company.companyId}`+
         `&category=${paramCategory?.replace(/&/ig,'%26')}`+
         `&date=${getDate(selectedDate)}`+
         `&breakdown=MONTH`,
    skip: paramSubCategory !== null || paramCategory === 'CF Summary'});

  const [contributorsYearlyResponse, contributorsYearlyLoading, contributorsYearlyError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/contributors/cf?companyId=${company.companyId}`+
         `&category=${paramCategory?.replace(/&/ig,'%26')}`+
         `&date=${getDate(selectedDate)}`+
         `&breakdown=YTD`,
    skip: paramSubCategory !== null || paramCategory === 'CF Summary'});

  const [evolutionChartMonthResponse, evolutionChartMonthLoading, evolutionChartMonthError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/monthly-evolution${paramSubCategory?'':'/category'}/cf?`+
         `companyId=${company.companyId}`+
         `&subCategory=${paramSubCategory? paramSubCategory.replace(/&/ig,'%26'):''}`+
         `&category=${paramCategory?.replace(/&/ig,'%26')}`+
         `&date=${getDate(selectedDate)}`,
    skip:paramCategory === 'CF Summary'
  });

  const [evolutionChartLTMResponse, evolutionChartLTMLoading, evolutionChartLTMError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/ltm-evolution${paramSubCategory?'':'/category'}/cf?`+
         `companyId=${company.companyId}`+
         `&subCategory=${paramSubCategory? paramSubCategory.replace(/&/ig,'%26'):''}`+
         `&category=${paramCategory?.replace(/&/ig,'%26')}`+
         `&date=${getDate(selectedDate)}`,
    skip:paramCategory === 'CF Summary'
  });

  const [absContributionMonthResponse, absContributionMonthLoading, absContributionMonthError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/absolute/${paramSubCategory?'subcategory':'category'}/cf?`+
         `companyId=${company.companyId}`+
         `&subCategory=${paramSubCategory? paramSubCategory.replace(/&/ig,'%26'):''}`+
         `&category=${paramCategory?.replace(/&/ig,'%26')}`+
         `&date=${getDate(selectedDate)}&breakdown=MONTH`,
    skip:paramCategory === 'CF Summary'
  });

  const [absContributionLTMResponse, absContributionLTMLoading, absContributionLTMError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/absolute/${paramSubCategory?'subcategory':'category'}/cf?`+
         `companyId=${company.companyId}`+
         `&subCategory=${paramSubCategory? paramSubCategory.replace(/&/ig,'%26'):''}`+
         `&category=${paramCategory?.replace(/&/ig,'%26')}`+
         `&date=${getDate(selectedDate)}`+
         `&breakdown=LTM`,
    skip:paramCategory === 'CF Summary'
  });

  const [absContributionYTDResponse, absContributionYTDLoading, absContributionYTDError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/absolute/${paramSubCategory?'subcategory':'category'}/cf?`+
         `companyId=${company.companyId}`+
         `&subCategory=${paramSubCategory? paramSubCategory.replace(/&/ig,'%26'):''}`+
         `&category=${paramCategory?.replace(/&/ig,'%26')}`+
         `&date=${getDate(selectedDate)}&breakdown=YTD`,
    skip:paramCategory === 'CF Summary'
  });

  const [cfGenerationHistoricTrendChartResponse, cfGenerationHistoricTrendChartLoading, cfGenerationHistoricTrendChartError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/cfgeneration/historic-data?`+
         `companyId=${company.companyId}`+
         `&date=${getDate(selectedDate)}`,
    skip:paramCategory !== 'CF Summary'});

  const [cfGenerationLTMEvolutionChartResponse, cfGenerationLTMEvolutionChartLoading, cfGenerationLTMEvolutionChartError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/cfgeneration/ltm-evolution?`+
         `companyId=${company.companyId}`+
         `&date=${getDate(selectedDate)}`,
    skip:paramCategory !== 'CF Summary'});

    const [freeCashFlowEvolutionMonthChartResponse, freeCashFlowEvolutionMonthChartLoading, freeCashFlowEvolutionMonthChartError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/monthly-evolution/derived-category/cf?`+
         `category=Free%20Cashflow`+
         `&companyId=${company.companyId}`+
         `&date=${getDate(selectedDate)}`,
      skip:paramCategory !== 'CF Summary'});

  const [freeCashFlowEvolutionLTMChartResponse, freeCashFlowEvolutionLTMChartLoading, freeCashFlowEvolutionLTMChartError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/ltm-evolution/derived-category/cf?`+
         `category=Free%20Cashflow`+
         `&companyId=${company.companyId}`+
         `&date=${getDate(selectedDate)}`,
    skip:paramCategory !== 'CF Summary'});

  const [cashflowGenerationMonthWriteupResponse, cashflowGenerationMonthWriteupLoading, cashflowGenerationMonthWriteupError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/cfGeneration/writeup?`+
         `companyId=${company.companyId}`+
         `&date=${getDate(selectedDate)}`+
         `&breakdown=MONTH`,
    skip:paramCategory !== 'CF Summary'});

  const [cashflowGenerationYTDWriteupResponse, cashflowGenerationYTDWriteupLoading, cashflowGenerationYTDWriteupError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/cfGeneration/writeup?`+
         `companyId=${company.companyId}`+
         `&date=${getDate(selectedDate)}`+
         `&breakdown=YTD`,
    skip:paramCategory !== 'CF Summary'});

  const [cashflowGenerationMonthOverallResponse, cashflowGenerationMonthOverallLoading, cashflowGenerationMonthOverallError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/cfGeneration/overall?`+
         `companyId=${company.companyId}`+
         `&date=${getDate(selectedDate)}`+
         `&breakdown=MONTH`,
    skip:paramCategory !== 'CF Summary'});

  const [cashflowGenerationYTDOverallResponse, cashflowGenerationYTDOverallLoading, cashflowGenerationYTDOverallError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/cfGeneration/overall?`+
         `companyId=${company.companyId}`+
         `&date=${getDate(selectedDate)}`+
         `&breakdown=YTD`,
    skip:paramCategory !== 'CF Summary'});

  const [cashflowGenerationLTMOverallResponse, cashflowGenerationLTMOverallLoading, cashflowGenerationLTMOverallError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/cfGeneration/overall?`+
         `companyId=${company.companyId}`+
         `&date=${getDate(selectedDate)}`+
         `&breakdown=LTM`,
    skip:paramCategory !== 'CF Summary'});

  const [overallValueMonthResponse, overallValueMonthLoading, overallValueMonthError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/absolute/cfGeneration?`+
         `companyId=${company.companyId}`+
         `&category=EBITDA`+
         `&date=${getDate(selectedDate)}`+
         `&breakdown=MONTH`,
    skip:paramCategory !=='CF Summary'});

  const [overallValueYTDResponse, overallValueYTDLoading, overallValueYTDError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/absolute/cfGeneration?`+
         `companyId=${company.companyId}`+
         `&category=EBITDA`+
         `&date=${getDate(selectedDate)}`+
         `&breakdown=YTD`,
    skip:paramCategory !=='CF Summary'});

  const [overallValueLTMResponse, overallValueLTMLoading, overallValueLTMError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/absolute/cfGeneration?`+
         `companyId=${company.companyId}`+
         `&category=EBITDA`+
         `&date=${getDate(selectedDate)}`+
         `&breakdown=LTM`,
    skip:paramCategory !=='CF Summary'});

  const mapCfGenerationHistoricTrend =(obj)=>{
    return Object.keys(obj).length> 0 ? obj.map((entry)=>({date: entry.date.substr(entry.date.length - 6), value: parseInt(entry.value)})): obj
  }
  const sentenceWithColor =(value)=>{
    if(Math.floor(value)>0)
    return(<span style={{color:'green',fontWeight:'500'}}>&nbsp;{formatCurrency(value,company.currency)}</span>)
    else if(Math.ceil(value)<0)
      return(
      <span style={{color:'red',fontWeight:'500'}}>&nbsp;{formatCurrency(value,company.currency)}</span>)
    else
      return (<span style={{fontWeight:'500'}}>&nbsp;{formatCurrency(value,company.currency)}</span>)
}
  const showWriteup=(obj)=> {

    if (Object.keys(obj).length) {
      return obj.map((entry, index) => {
        const titles = [];
        const content = [];
        const subPoints=[];
        //const {title:{text,postText}}= entry;
        titles.push(<h2 className="writeup-heading" style={{margin:'2em 0 0.5em 0'}}>{entry.title.text}&nbsp;
          {sentenceWithColor(entry.title.value)}
          &nbsp; {entry.title.postText}
        </h2>);
        content.push(
          <ul style={{padding:'0'}} key={index}>
            {entry.points.map((point, index) => (
              <li className="writeup-bullets" style={{fontWeight:'500'}} key={index}>{point.text}&nbsp;
                {sentenceWithColor(point.value)}
                &nbsp; {point.postText}
              </li>))
            }
        </ul>)
        subPoints.push(
          <ul style={{padding:'0 0 0 1em',margin:'0',marginTop:'-0.5em'}} key={index}>
            {entry.subPoints.map((subPoint, index) => (<li className="writeup-bullets" key={index}>{subPoint.text}&nbsp;
              {sentenceWithColor(subPoint.value)}&nbsp; {subPoint.postText}
            </li>))}
          </ul>
        )
        return (<div key={index}>{titles}{content}{subPoints}</div>)
      })
    }
  }

  const cfGenerationWaterfallMap=(array)=>{
      if(Array.isArray(array)) {
        return array.reduce((acc, currValue, currIndex, arr) => {
          if (currValue.cat === 'Cashflow from Operations') {
            acc.push({cat: currValue.cat, value: currValue.value, sum: 0});
          } else if (currValue.cat === 'Cashflow from Investing') {
            acc.push({cat: currValue.cat, value: currValue.value, sum: arr[0].value});
          } else if (currValue.cat === 'Free Cashflow') {
            acc.push({cat: currValue.cat, value: currValue.value, sum: 0});
          } else if (currValue.cat === 'Cashflow from Financing') {
            acc.push({cat: currValue.cat, value: currValue.value, sum: arr[2].value});
          } else if (currValue.cat === 'Cashflow Generation') {
            acc.push({cat: currValue.cat, value: arr[2].value + arr[3].value, sum: 0});
          }
          return acc;
        }, []);
      }
      else{
        return array;
      }
  }
  useEffect(()=>{
    if(paramCategory === 'CF Summary') {
      if (!cashflowGenerationMonthWriteupLoading && (cashflowGenerationMonthWriteupResponse)) {
        setCashflowGenerationMonthWriteup(cashflowGenerationMonthWriteupResponse);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    cashflowGenerationMonthWriteupResponse,
    cashflowGenerationMonthWriteupLoading,
    cashflowGenerationMonthWriteupError,
    paramSection,
    paramCategory,
    paramSubCategory]);

  useEffect(()=>{
    if(paramCategory === 'CF Summary') {
      if (!cashflowGenerationYTDWriteupLoading && (cashflowGenerationYTDWriteupResponse)) {
        setCashflowGenerationYTDWriteup(cashflowGenerationYTDWriteupResponse);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    cashflowGenerationYTDWriteupResponse,
    cashflowGenerationYTDWriteupLoading,
    cashflowGenerationYTDWriteupError,
    paramSection,
    paramCategory,
    paramSubCategory]);


  useEffect(()=>{
    if(paramCategory === 'CF Summary') {
      if (!cashflowGenerationMonthOverallLoading && (cashflowGenerationMonthOverallResponse)) {
        setCashflowGenerationMonthOverall(cfGenerationWaterfallMap(cashflowGenerationMonthOverallResponse));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    cashflowGenerationMonthOverallResponse,
    cashflowGenerationMonthOverallLoading,
    cashflowGenerationMonthOverallError,
    paramSection,
    paramCategory,
    paramSubCategory]);

  useEffect(()=>{
    if(paramCategory === 'CF Summary') {
      if (!cashflowGenerationYTDOverallLoading && (cashflowGenerationYTDOverallResponse)) {
        setCashflowGenerationYTDOverall(cfGenerationWaterfallMap(cashflowGenerationYTDOverallResponse));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    cashflowGenerationYTDOverallResponse,
    cashflowGenerationYTDOverallLoading,
    cashflowGenerationYTDOverallError,
    paramSection,
    paramCategory,
    paramSubCategory]);

  useEffect(()=>{
    if(paramCategory === 'CF Summary') {
      if (!cashflowGenerationLTMOverallLoading && (cashflowGenerationLTMOverallResponse)) {
        setCashflowGenerationLTMOverall(cfGenerationWaterfallMap(cashflowGenerationLTMOverallResponse));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    cashflowGenerationLTMOverallResponse,
    cashflowGenerationLTMOverallLoading,
    cashflowGenerationLTMOverallError,
    paramSection,
    paramCategory,
    paramSubCategory]);

   useEffect(()=>{
    if(paramCategory === 'CF Summary') {
      if (!freeCashFlowEvolutionMonthChartLoading && (freeCashFlowEvolutionMonthChartResponse)) {
        setFreeCashFlowEvolutionMonthChart(mapCfGenerationHistoricTrend(freeCashFlowEvolutionMonthChartResponse));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[freeCashFlowEvolutionMonthChartResponse,
     freeCashFlowEvolutionMonthChartLoading,
     freeCashFlowEvolutionMonthChartError,
     paramSection,
     paramCategory,
     paramSubCategory]);

   useEffect(()=>{
    if(paramCategory === 'CF Summary') {
      if (!cfGenerationChartTwelveMonthsLoading && (cfGenerationChartTwelveMonthsResponse)) {
        setCfGenerationChartTwelveMonths(mapCfGenerationHistoricTrend(cfGenerationChartTwelveMonthsResponse));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[cfGenerationChartTwelveMonthsResponse,
     cfGenerationChartTwelveMonthsLoading,
     cfGenerationChartTwelveMonthsError,
     paramSection,
     paramCategory,
     paramSubCategory]);

  useEffect(()=>{
    if(paramCategory === 'CF Summary') {
      if (!freeCashFlowEvolutionLTMChartLoading && (freeCashFlowEvolutionLTMChartResponse)) {
        setFreeCashFlowEvolutionLTMChart(mapCfGenerationHistoricTrend(freeCashFlowEvolutionLTMChartResponse));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[freeCashFlowEvolutionLTMChartResponse,
    freeCashFlowEvolutionLTMChartLoading,
    freeCashFlowEvolutionLTMChartError,
    paramSection,
    paramCategory,
    paramSubCategory]);

  useEffect(()=>{
    if(paramCategory === 'CF Summary') {
      if (!cfGenerationLTMEvolutionChartLoading && (cfGenerationLTMEvolutionChartResponse)) {
        setCfGenerationLTMEvolutionChart(mapCfGenerationHistoricTrend(cfGenerationLTMEvolutionChartResponse));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[cfGenerationLTMEvolutionChartResponse,
    cfGenerationLTMEvolutionChartLoading,
    cfGenerationLTMEvolutionChartError,
    paramSection,
    paramCategory,
    paramSubCategory]);

  useEffect(()=>{
    if(paramCategory === 'CF Summary') {
      if (!cfGenerationHistoricTrendChartLoading && (cfGenerationHistoricTrendChartResponse)) {
        setCfGenerationHistoricTrendChart(mapCfGenerationHistoricTrend(cfGenerationHistoricTrendChartResponse));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[cfGenerationHistoricTrendChartResponse,
    cfGenerationHistoricTrendChartLoading,
    cfGenerationHistoricTrendChartError,
    paramSection,
    paramCategory,
    paramSubCategory]);

  useEffect(()=>{
    if(paramCategory !== 'CF Summary') {
      if (!absContributionMonthLoading && (absContributionMonthResponse !== undefined)) {
        setAbsContributionMonth(absContributionMonthResponse);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    absContributionMonthResponse,
    absContributionMonthLoading,
    absContributionMonthError,
]);

  useEffect(()=>{
    if(paramCategory !== 'CF Summary') {
      if (!absContributionLTMLoading && (absContributionLTMResponse !== undefined)) {
        setAbsContributionLTM(absContributionLTMResponse);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[absContributionLTMResponse, absContributionLTMLoading, absContributionLTMError,paramSection,paramCategory,paramSubCategory])

  useEffect(()=>{
    if(paramCategory !== 'CF Summary') {
      if (!absContributionYTDLoading && (absContributionYTDResponse !== undefined)) {
        setAbsContributionYTD(absContributionYTDResponse);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[absContributionYTDResponse, absContributionYTDLoading, absContributionYTDError,paramSection,paramCategory,paramSubCategory])

  useEffect(()=>{
    if(paramCategory !== 'CF Summary') {
      if (!lTMPrevChartLoading && (Object.keys(lTMPrevChartResponse).length > 0)) {
       const roundedData = lTMPrevChartResponse.map(obj => {return ({date: obj.date, value:parseFloat(parseFloat(obj.value).toFixed(2))})});
       setLTMPrevChart(roundedData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[lTMPrevChartResponse, lTMPrevChartLoading, lTMPrevChartError,paramSection,paramCategory,paramSubCategory])

  useEffect(()=>{
    if(paramCategory !== 'CF Summary') {
      if (!historicTrendChartLoading && (Object.keys(historicTrendChartResponse).length > 0)) {
        setHistoricTrendChart(mapHistoricTrend(historicTrendChartResponse));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[historicTrendChartLoading, historicTrendChartResponse, historicTrendChartError,paramSection,paramCategory,paramSubCategory]);

  useEffect(()=>{
    if(paramCategory === 'CF Summary') {
      if (!categoryCfGenerationMonthlyLoading && (Object.keys(categoryCfGenerationMonthlyResponse).length > 0)) {
        setCategoryCfGenerationMonthlyChart(waterfallChartMap(categoryCfGenerationMonthlyResponse));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[categoryCfGenerationMonthlyLoading, categoryCfGenerationMonthlyResponse, categoryCfGenerationMonthlyError,paramSection,paramCategory]);

  useEffect(()=>{
    if(paramCategory === 'CF Summary') {
      if (!categoryCfGenerationYTDLoading && (Object.keys(categoryCfGenerationYTDResponse).length > 0)) {
        setCategoryCfGenerationYTDChart(waterfallChartMap(categoryCfGenerationYTDResponse));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[categoryCfGenerationYTDLoading, categoryCfGenerationYTDResponse, categoryCfGenerationYTDError,paramSection,paramCategory]);

  useEffect(()=>{
    if(paramSubCategory === null) {
      if (!contributorsMonthlyLoading && (Object.keys(contributorsMonthlyResponse).length > 0)) {
        setContributorsMonthlyChart(mapCategoryForDifferencesBarChart(contributorsMonthlyResponse.contribution));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[contributorsMonthlyLoading, contributorsMonthlyResponse, contributorsMonthlyError,paramSection,paramCategory]);

  useEffect(()=>{
    if(paramSubCategory === null) {
      if (!contributorsYearlyLoading && (Object.keys(contributorsYearlyResponse).length > 0)) {
        setContributorsYearlyChart(mapCategoryForDifferencesBarChart(contributorsYearlyResponse.contribution));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[contributorsYearlyLoading, contributorsYearlyResponse, contributorsYearlyError,paramSection,paramCategory]);

  useEffect(()=>{
    if(paramCategory !== 'CF Summary') {
      if (!evolutionChartMonthLoading && (Object.keys(evolutionChartMonthResponse).length > 0)) {
        setEvolutionMonthChart(mapEvolution(evolutionChartMonthResponse, paramSubCategory, paramCategory, 'bs'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[evolutionChartMonthLoading, evolutionChartMonthResponse, evolutionChartMonthError,paramSection,paramCategory,paramSubCategory])

  useEffect(()=>{
    if(paramCategory !== 'CF Summary') {
      if (!evolutionChartLTMLoading && (Object.keys(evolutionChartLTMResponse).length > 0)) {
        setEvolutionLTMChart(mapEvolution(evolutionChartLTMResponse, paramSubCategory, paramCategory, 'bs'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[evolutionChartLTMLoading, evolutionChartLTMResponse, evolutionChartLTMError,paramSection,paramCategory,paramSubCategory])

  useEffect(()=>{
    if(paramCategory === 'CF Summary') {
      if (!overallValueMonthLoading && overallValueMonthResponse) {
        setOverallValueMonth(overallValueMonthResponse);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[overallValueMonthResponse, overallValueMonthLoading, overallValueMonthError,paramSection,paramCategory,paramSubCategory])

  useEffect(()=>{
    if(paramCategory === 'CF Summary') {
      if (!overallValueYTDLoading && overallValueYTDResponse) {
        setOverallValueYTD(overallValueYTDResponse);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[overallValueYTDResponse, overallValueYTDLoading, overallValueYTDError,paramSection,paramCategory,paramSubCategory])

  useEffect(()=>{
    if(paramCategory === 'CF Summary') {
      if (!overallValueLTMLoading && overallValueLTMResponse) {
        setOverallValueLTM(overallValueLTMResponse);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[overallValueLTMResponse, overallValueLTMLoading, overallValueLTMError,paramSection,paramCategory,paramSubCategory])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="content-wrapper">
      <SubHeader text={'Analysis as of'}/>
      <h5 className='page-title'>{`${paramSection !== undefined && paramSubCategory ? paramSubCategory : paramCategory}`}</h5>
      <div className="analysisContainer">
        {/*overall values*/}
        {
          paramCategory ==='CF Summary' &&
          <div className={`line-charts-wrapper`}>
            {!overallValueMonthLoading && <div className='lineChartHeading' style={{textAlign: 'center'}}>
              <span className='lineChartHeadingLabel'>Month Cashflow Generation {parseInt(overallValueMonth) >=0 ?
                <span className='pieChartHeadingLabelAmount'>
                  {formatCurrency(parseInt(overallValueMonth),company.currency)}</span>:
                <span className='pieChartHeadingLabelAmount' style={{color:'red'}}>
                  {formatCurrency(parseInt(overallValueMonth),company.currency)}
                </span>}
              </span>
              <span>{overallValueMonthError && <ErrorResponse dataTestId={`overall-value-month-error`} error={overallValueMonthError}/>}</span>
            </div>}
            {!overallValueYTDLoading && <div className='lineChartHeading' style={{textAlign: 'center'}}>
               <span className='lineChartHeadingLabel'>YTD Cashflow Generation {parseInt(overallValueYTD) >=0 ?
                 <span className='pieChartHeadingLabelAmount'>{formatCurrency(parseInt(overallValueYTD),company.currency)}</span>:
                 <span className='pieChartHeadingLabelAmount' style={{color:'red'}}>{formatCurrency(parseInt(overallValueYTD),company.currency)}</span>}
              </span>
              <span>{overallValueYTDError && <ErrorResponse dataTestId={`overall-value-ytd-error`} error={overallValueYTDError} />}</span>

            </div>}
            {!overallValueLTMLoading && <div className='lineChartHeading' style={{textAlign: 'center'}}>
               <span className='lineChartHeadingLabel'>LTM Cashflow Generation {parseInt(overallValueLTM) >=0 ?
                 <span className='pieChartHeadingLabelAmount'>{formatCurrency(parseInt(overallValueLTM),company.currency)}</span>:
                 <span className='pieChartHeadingLabelAmount' style={{color:'red'}}>{formatCurrency(parseInt(overallValueLTM),company.currency)}</span>}
              </span>
              <span>{overallValueLTMError && <ErrorResponse dataTestId={`overall-value-ltm-error`} error={overallValueLTMError}/>}</span>
            </div>}
          </div>
        }

        {paramCategory === 'CF Summary' &&
         <div className={`line-charts-wrapper`}>
           {<div className={`line-chart-container`} style={{flex: '0 0 32%'}}>
             <CfGenerationWaterfallChart
               dataTestId={'cashflow-generation-month-overall'}
               dataValue={paramCategory}
               formatter={(value)=>formatCurrency(value,company.currency)}
               loading={ cashflowGenerationMonthOverallLoading}
               error={cashflowGenerationMonthOverallError}
               data={cashflowGenerationMonthOverall}
               chartInfoCopy={infoCopies.cfSummary.cfGenration}
             />
           </div>}
           {<div className={`line-chart-container`} style={{flex: '0 0 32%'}}>
             <CfGenerationWaterfallChart
               dataTestId={'cashflow-generation-ytd-overall'}
               dataValue={paramCategory}
               formatter={(value)=>formatCurrency(value,company.currency)}
               loading={ cashflowGenerationYTDOverallLoading}
               error={cashflowGenerationYTDOverallError}
               data={cashflowGenerationYTDOverall}
               chartInfoCopy={infoCopies.cfSummary.cfGenration}
             />
           </div>}
           {<div className={`line-chart-container`} style={{flex: '0 0 32%'}}>
             <CfGenerationWaterfallChart
               dataTestId={'cashflow-generation-ltm-overall'}
               dataValue={paramCategory}
               formatter={(value)=>formatCurrency(value,company.currency)}
               loading={ cashflowGenerationLTMOverallLoading}
               error={cashflowGenerationLTMOverallError}
               data={cashflowGenerationLTMOverall}
               chartInfoCopy={infoCopies.cfSummary.cfGenration}
             />
           </div>}
         </div>}
        {paramCategory === 'CF Summary' && <hr className='separator-line' style={{margin:'2em 0 0 0'}}/>}
        {/*CF generation charts*/}
        { paramCategory === 'CF Summary' &&
          <div className={`line-charts-wrapper`}>
            {<div className='lineChartHeading'>
              <span className='lineChartHeadingLabel'>Cashflow Generation - Month</span>
            </div>}
            {<div className='lineChartHeading'>
              <span className='lineChartHeadingLabel'>Cashflow Generation - YTD</span>
            </div>}
          </div>}
        {paramCategory === 'CF Summary' &&
         <div className={`line-charts-wrapper`}>
           {<div className={`line-chart-container`}>
             <WaterfallChart
               dataTestId={'category-cf-generation-monthly'}
               dataValue={paramCategory}
               formatter={(value)=>formatCurrency(value,company.currency)}
               loading={ categoryCfGenerationMonthlyLoading}
               error={categoryCfGenerationMonthlyError}
               data={categoryCfGenerationMonthlyChart}
               chartInfoCopy={infoCopies.cfSummary.cashflowGenerationMonth}
             />
           </div>}
           {<div className={`line-chart-container`}>
             <WaterfallChart
               dataTestId={'category-cf-generation-ytd'}
               dataValue={paramCategory}
               formatter={(value)=>formatCurrency(value,company.currency)}
               loading={ categoryCfGenerationYTDLoading}
               error={categoryCfGenerationYTDError}
               data={categoryCfGenerationYTDChart}
               chartInfoCopy={infoCopies.cfSummary.cashflowGenerationYtd}
             />

           </div>}
         </div>}
        { paramCategory === 'CF Summary' &&
          <div className={`line-charts-wrapper`}>
            {<div className='lineChartHeading'>
              <div className={`writeup-wrapper`} style={{margin:'0 0'}}>
                {cashflowGenerationMonthWriteupLoading && <div style={{margin:'1em 0 2em 0'}}>
                  <Loader dataTestId={`cashflow-generation-month-writeup-loading`} type={'pulse'} size={10}/></div>}
                {cashflowGenerationMonthWriteupError && <ErrorResponse dataTestId={`cashflow-generation-month-writeup-error`} error={cashflowGenerationMonthWriteupError}/>}
                {!cashflowGenerationMonthWriteupLoading && showWriteup(cashflowGenerationMonthWriteup,'CF')}
              </div>
            </div>}
            {<div className='lineChartHeading'>
              <div className={`writeup-wrapper`} style={{margin:'0 0'}}>
                {cashflowGenerationYTDWriteupLoading && <div style={{margin:'1em 0 2em 0'}}>
                  <Loader dataTestId={`cashflow-generation-ytd-writeup-loading`}  type={'pulse'} size={10}/></div>}
                {cashflowGenerationYTDWriteupError && <ErrorResponse dataTestId={`cashflow-generation-ytd-writeup-error`} error={cashflowGenerationYTDWriteupError}/>}
                {!cashflowGenerationYTDWriteupLoading && showWriteup(cashflowGenerationYTDWriteup,'CF')}
              </div>
            </div>}
          </div>}
        {paramCategory === 'CF Summary' && <hr className='separator-line' style={{marginBottom:'1.5em'}}/>}
        { paramCategory !==  'CF Summary' &&
          <div style={{display:'flex', marginTop:'2em',width:'100%',fontSize:'1em'}}>
            <div style={{flex:'1 1 33%',textAlign:'center'}}>
              <label style={{fontWeight:'600',color:'#305A80'}}>{paramSubCategory ? 'CF from ' + paramSubCategory : paramCategory} Month : </label>
              {absContributionMonthError && <ErrorResponse dataTestId={`abs-contribution-month-error`} error={absContributionMonthError}/>}
              {!absContributionMonthError && !absContributionMonthLoading &&
              <span style={{fontSize:'1.1em',fontWeight:'500'}}>{parseInt(absContributionMonth) < 0? <span
                style={{color:'red'}}>{formatCurrency(absContributionMonth,company.currency)}</span>:formatCurrency(absContributionMonth,company.currency)}</span>
              }</div>
            <div style={{flex:'1 1 33%',textAlign:'center'}}>
              <label style={{fontWeight:'600',color:'#305A80'}}>{paramSubCategory ? 'CF from ' + paramSubCategory : paramCategory} YTD : </label>
              {absContributionYTDError && <ErrorResponse dataTestId={`abs-contribution-ytd-error`}  error={absContributionYTDError}/>}
              {!absContributionYTDError && !absContributionYTDLoading &&
               <span style={{fontSize: '1.1em', fontWeight: '500'}}>{parseInt(absContributionYTD) < 0 ? <span
                 style={{color: 'red'}}>{formatCurrency(absContributionYTD,company.currency)}</span> : formatCurrency(absContributionYTD,company.currency)}</span>
              }</div>
            <div style={{flex:'1 1 33%',textAlign:'center'}}>
              <label style={{fontWeight:'600',color:'#305A80'}}>{paramSubCategory ? 'CF from ' + paramSubCategory : paramCategory} LTM: </label>
              {absContributionLTMError && <ErrorResponse dataTestId={`abs-contribution-ltm-error`} error={absContributionLTMError}/>}
              {!absContributionLTMError && !absContributionLTMLoading &&
               <span style={{fontSize: '1.1em', fontWeight: '500'}}>{parseInt(absContributionLTM) < 0 ? <span
                 style={{color: 'red'}}>{formatCurrency(absContributionLTM,company.currency)}</span> : formatCurrency(absContributionLTM,company.currency)}</span>
              }</div>
          </div>
        }
        {paramCategory !== 'CF Summary'  &&
         <hr className='separator-line' style={{margin:'1.5em 0'}}/>
        }
        {/*CF historic trend for summary charts*/}
        { paramCategory === 'CF Summary' &&
          <div className={`line-charts-wrapper`} style={{display:'flex',flexWrap:'nowrap',justifyContent:'space-around',paddingTop:'1em'}}>
            {<div className='lineChartHeading'>
              <span className='lineChartHeadingLabel'>Cashflow Generation - Previous Twelve Months</span>
            </div>}
            {<div className='lineChartHeading'>
              <span className='lineChartHeadingLabel'>Cashflow Generation - Monthly</span>
            </div>}
          </div>}
        {paramCategory === 'CF Summary' &&
         <div className={`line-charts-wrapper`} style={{display:'flex',flexWrap:'nowrap',justifyContent:'space-around'}}>
           {<div className={`line-chart-container`}>
             <SimpleBarChart
               dataTestId={'cf-generation-chart-twelve-months'}
               dataKey={'date'}
               dataValue={paramCategory}
               formatter={(value)=>formatCurrency(value,company.currency)}
               loading={ cfGenerationChartTwelveMonthsLoading}
               error={cfGenerationChartTwelveMonthsError}
               data={cfGenerationChartTwelveMonths}
               chartInfoCopy={infoCopies.cfSummary.cashflowGenerationPreviousTwelveMonths}
             />
           </div>}
           {<div className={`line-chart-container`}>
             <SimpleBarChart
               dataTestId={'cf-generation-historic-trend-chart'}
               dataKey={'date'}
               showBrush={true}
               dataValue={paramCategory}
               formatter={(value)=>formatCurrency(value,company.currency)}
               loading={ cfGenerationHistoricTrendChartLoading}
               error={cfGenerationHistoricTrendChartError}
               data={cfGenerationHistoricTrendChart}
               chartInfoCopy={infoCopies.cfSummary.cashflowGenerationMonthTwo}
             />
           </div>}
         </div>}
        {paramCategory === 'CF Summary' && <hr className='separator-line' style={{marginBottom:'1.5em'}}/>}
        {/* CF Overall - Evolution - Month, LTM*/}
        { paramCategory === 'CF Summary' &&
          <div className={`line-charts-wrapper`} style={{display:'flex',flexWrap:'nowrap',justifyContent:'space-around',paddingTop:'1em'}}>
            {<div className='lineChartHeading'>
              <span className='lineChartHeadingLabel'>Cashflow Generation – Monthly</span>
            </div>}
            {<div className='lineChartHeading'>
              <span className='lineChartHeadingLabel'>Cashflow Generation - LTM</span>
            </div>}
          </div>}
        {paramCategory === 'CF Summary' &&
         <div className={`line-charts-wrapper`} style={{display:'flex',flexWrap:'nowrap',justifyContent:'space-around'}}>
           {<div className={`line-chart-container`}>
             <SimpleAreaChart
               dataTestId={'cf-generation-historic-trend-chart'}
               dataKey={'date'}
               showBrush
               showLegend={false}
               data={cfGenerationHistoricTrendChart}
               loading={cfGenerationHistoricTrendChartLoading}
               formatter={(value)=>formatCurrency(value,company.currency)}
               error={cfGenerationHistoricTrendChartError}
               chartInfoCopy={infoCopies.cfSummary.cashflowGenerationMonthThree}
             />
           </div>}
           {<div className={`line-chart-container`}>
             <SimpleAreaChart
               dataTestId={'cf-generation-ltm-evolution-chart'}
               dataKey={'date'}
               showBrush
               showLegend={false}
               data={cfGenerationLTMEvolutionChart}
               loading={cfGenerationLTMEvolutionChartLoading}
               formatter={(value)=>formatCurrency(value,company.currency)}
               error={cfGenerationLTMEvolutionChartError}
               chartInfoCopy={infoCopies.cfSummary.cashflowGenerationLTM}
             />
           </div>}
         </div>}
        {paramCategory === 'CF Summary' && <hr className='separator-line' style={{marginBottom:'1.5em'}}/>}
        {/* CF Overall - Evolution - Month, LTM*/}
        { paramCategory === 'CF Summary' &&
          <div className={`line-charts-wrapper`} style={{display:'flex',flexWrap:'nowrap',justifyContent:'space-around',paddingTop:'1em'}}>
            {<div className='lineChartHeading'>
              <span className='lineChartHeadingLabel'>Free Cashflow Generation – Monthly</span>
            </div>}
            {<div className='lineChartHeading'>
              <span className='lineChartHeadingLabel'>Free Cashflow Generation – Ltm</span>
            </div>}
          </div>}
        {paramCategory === 'CF Summary' &&
         <div className={`line-charts-wrapper`} style={{display:'flex',flexWrap:'nowrap',justifyContent:'space-around'}}>
           {<div className={`line-chart-container`}>
             <SimpleAreaChart
               dataTestId={'freeCash-flow-evolution-month-chart'}
               dataKey={'date'}
               showBrush
               showLegend={false}
               data={freeCashFlowEvolutionMonthChart}
               loading={freeCashFlowEvolutionMonthChartLoading}
               formatter={(value)=>formatCurrency(value,company.currency)}
               error={freeCashFlowEvolutionMonthChartError}
               chartInfoCopy={infoCopies.cfSummary.freeCashFlowGenrationMonth}
             />
           </div>}
           {<div className={`line-chart-container`}>
             <SimpleAreaChart
               dataTestId={'freeCash-flow-evolution-ltm-chart'}
               dataKey={'date'}
               showBrush
               showLegend={false}
               data={freeCashFlowEvolutionLTMChart}
               loading={freeCashFlowEvolutionLTMChartLoading}
               formatter={(value)=>formatCurrency(value,company.currency)}
               error={freeCashFlowEvolutionLTMChartError}
               chartInfoCopy={infoCopies.cfSummary.freeCashFlowGenrationLTM}
             />
           </div>}
         </div>}
        {/*/!* CF Overall - Evolution - Month, LTM*!/*/}
        {/*{ paramCategory === 'CF Summary' &&*/}
        {/*  <div className={`line-charts-wrapper`} style={{display:'flex',flexWrap:'nowrap',justifyContent:'space-around',paddingTop:'1em'}}>*/}
        {/*    {<div className='lineChartHeading'>*/}
        {/*      <span className='lineChartHeadingLabel'>Monthly Cashflow Generation</span>*/}
        {/*    </div>}*/}
        {/*    {<div className='lineChartHeading'>*/}
        {/*      <span className='lineChartHeadingLabel'>YTD Cashflow Generation</span>*/}
        {/*    </div>}*/}
        {/*  </div>}*/}
        {/*{paramCategory === 'CF Summary' &&*/}
        {/* <div className={`line-charts-wrapper`} style={{display:'flex',flexWrap:'nowrap',justifyContent:'space-around'}}>*/}
        {/*   {<div className={`line-chart-container`}>*/}
        {/*     <WaterfallChart*/}
        {/*       dataValue={paramCategory}*/}
        {/*       formatter={(value)=>formatCurrency(value,company.currency)}*/}
        {/*       loading={ cashflowGenerationMonthLoading}*/}
        {/*       error={cashflowGenerationMonthError}*/}
        {/*       data={cashflowGenerationMonth} />*/}
        {/*   </div>}*/}
        {/*   {<div className={`line-chart-container`}>*/}
        {/*     <WaterfallChart*/}
        {/*       dataValue={paramCategory}*/}
        {/*       formatter={(value)=>formatCurrency(value,company.currency)}*/}
        {/*       loading={ cashflowGenerationYTDLoading}*/}
        {/*       error={cashflowGenerationYTDError}*/}
        {/*       data={cashflowGenerationYTD} />*/}
        {/*   </div>}*/}
        {/* </div>}*/}


        {/*  paramCategory ==='CF Summary' &&*/}
        {/*  <div className={`line-charts-wrapper`} style={{display:'flex',flexWrap:'nowrap',justifyContent:'space-around',paddingTop:'1em'}}>*/}
        {/*    {<div className='lineChartHeading'>*/}
        {/*      <span className='lineChartHeadingLabel'>Monthly Free Cashflow Evolution</span>*/}
        {/*    </div>}*/}
        {/*    {<div className='lineChartHeading'>*/}
        {/*      <span className='lineChartHeadingLabel'>LTM Free Cashflow Evolution</span>*/}
        {/*    </div>}*/}
        {/*  </div>*/}
        {/*}*/}
        {/*{paramCategory ==='CF Summary' &&*/}
        {/* <div className={`line-charts-wrapper`} style={{display:'flex',flexWrap:'nowrap',justifyContent:'space-around'}}>*/}
        {/*   {<div className={`line-chart-container`}>*/}
        {/*     <LineChartDoubleYAxis*/}
        {/*       percentageOf={'Free Cashflow as % of sales'}*/}
        {/*       dataValue={'Free Cashflow'}*/}
        {/*       showGrid*/}
        {/*       formatter={(value)=>formatCurrency(value,company.currency)}*/}
        {/*       loading={freeCashFlowEvolutionLTMLoading}*/}
        {/*       error={freeCashFlowEvolutionMonthError}*/}
        {/*       data={freeCashFlowEvolutionMonth}/>*/}
        {/*   </div>}*/}
        {/*   {<div className={`line-chart-container`}>*/}
        {/*     <LineChartDoubleYAxis*/}
        {/*       percentageOf={'Gross Profit as % of sales'}*/}
        {/*       dataValue={'Gross Profit'}*/}
        {/*       showGrid*/}
        {/*       formatter={(value)=>formatCurrency(value,company.currency)}*/}
        {/*       loading={freeCashFlowEvolutionLTMLoading}*/}
        {/*       error={freeCashFlowEvolutionLTMError}*/}
        {/*       data={freeCashFlowEvolutionLTM}/>*/}
        {/*   </div>}*/}
        {/* </div>}*/}

        { !paramSubCategory && paramCategory !== 'CF Summary' &&
        <div className={`line-charts-wrapper`} style={{display:'flex',flexWrap:'nowrap',justifyContent:'space-around',paddingTop:'1em'}}>
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>{paramCategory} - Main Cash Outflows And Inflows In The Month</span>
          </div>}
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>{paramCategory} - Main Cash Outflows And Inflows In The Ytd</span>
          </div>}
        </div>}
        {!paramSubCategory && paramCategory !== 'CF Summary' &&
        <div className={`line-charts-wrapper`} style={{display:'flex',flexWrap:'nowrap',justifyContent:'space-around'}}>
          {<div className={`line-chart-container`}>
            <SimpleBarChart
              dataTestId={'contributors-monthly'}
              dataValue={paramCategory}
              formatter={(value)=>formatCurrency(value,company.currency)}
              loading={ contributorsMonthlyLoading}
              error={contributorsMonthlyError}
              data={contributorsMonthlyChart}
              chartInfoCopy={infoCopies.cashFlowSubcategories.mainCashOutflowsInflowsMonth}
            />
          </div>}
          {<div className={`line-chart-container`}>
            <SimpleBarChart
              dataTestId={'contributors-yearly'}
              dataValue={paramCategory}
              formatter={(value)=>formatCurrency(value,company.currency)}
              loading={ contributorsYearlyLoading}
              error={contributorsYearlyError}
              data={contributorsYearlyChart}
              chartInfoCopy={infoCopies.cashFlowSubcategories.mainCashOutflowsInflowsYTD}
            />
          </div>}
        </div>}
{/*LTM vs Prev and historic trend chart*/}
        {!paramSubCategory && paramCategory !== 'CF Summary' &&  <hr className='separator-line' style={{marginBottom:'1.5em'}}/>}
        {paramCategory !== 'CF Summary' &&
        <div className={`line-charts-wrapper`} style={{display:'flex',flexWrap:'nowrap',justifyContent:'space-around'}}>
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>{paramSubCategory? `CF from ${paramSubCategory}` : `${paramCategory}`} - <span className='lineChartHeadingLabelBold'>Previous Twelve Months
</span></span>
          </div>}
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>{paramSubCategory? `CF from ${paramSubCategory}` : `${paramCategory}`} - <span className='lineChartHeadingLabelBold'>Monthly</span></span>
          </div>}
        </div>}
        {paramCategory !== 'CF Summary' &&
        <div className={`line-charts-wrapper`} style={{display:'flex',flexWrap:'nowrap',justifyContent:'space-around'}}>
          {<div className={`line-chart-container`}>
            <BarChartOneCategory
              dataTestId={'ltm-prev-Chart'}
              showLegend={false}
              showBrush={false}
              dataKey={"date"}
              formatter={(value)=>formatCurrency(value,company.currency)}
              loading={lTMPrevChartLoading}
              error={lTMPrevChartError}
              data={lTMPrevChart}
              chartInfoCopy={paramSubCategory ?infoCopies.cashFlowSubcategories.previousTwelveMonths(paramSubCategory):infoCopies.cashFlowSubcategories.previousTwelveMonths(paramCategory)}
            />
            {/*<SimpleLineChart formatter={(value)=>formatCurrency(value,company.currency)} loading={lTMPrevChartLoading} error={lTMPrevChartError} data={lTMPrevChart}/>*/}
          </div>}
          {<div className={`line-chart-container`}>
            <BarChartOneCategory
              dataTestId={'historic-trend-Chart'}
              showLegend={false}
              showBrush
              dataKey={"name"}
              formatter={(value)=>formatCurrency(value,company.currency)}
              loading={historicTrendChartLoading}
              error={historicTrendChartError}
              data={historicTrendChart}
              chartInfoCopy={paramSubCategory ?infoCopies.cashFlowSubcategories.monthlyOne(paramSubCategory):infoCopies.cashFlowSubcategories.monthlyOne(paramCategory)}
            />
            {/*<HistoricTrendChart formatter={(value)=>formatCurrency(value,company.currency)} loading={historicTrendChartLoading} error={historicTrendChartError} data={historicTrendChart}/>*/}
          </div>}
        </div>}
        {paramCategory !== 'CF Summary' &&
        <hr className='separator-line' style={{marginBottom:'1.5em'}}/>}
{/*Evolution charts*/}
        {paramCategory !== 'CF Summary' &&
        <div className={`line-charts-wrapper`} style={{display:'flex',flexWrap:'nowrap',justifyContent:'space-around'}}>
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>{paramSubCategory? `CF from ${paramSubCategory}`:paramCategory} - Monthly</span>
          </div>}
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>{paramSubCategory? `CF from ${paramSubCategory}`:paramCategory} - LTM</span>
          </div>}
        </div>}
        {paramCategory !== 'CF Summary' &&
        <div className={`line-charts-wrapper`} style={{display:'flex',flexWrap:'nowrap',justifyContent:'space-around'}}>
          {<div className={`line-chart-container`}>
            <LineChartDoubleYAxis
              dataTestId={'evolution-chart-month'}
              percentageOf={'% of Total Assets'}
              dataValue={paramSubCategory?paramSubCategory:paramCategory}
              showGrid
              formatter={(value)=>formatCurrency(value,company.currency)}
              loading={evolutionChartMonthLoading}
              error={evolutionChartMonthError}
              data={evolutionMonthChart}
              chartInfoCopy={paramSubCategory ?infoCopies.cashFlowSubcategories.monthlyTwo(paramSubCategory):infoCopies.cashFlowSubcategories.monthlyTwo(paramCategory)}
            />
          </div>}
          {<div className={`line-chart-container`}>
            <LineChartDoubleYAxis
              dataTestId={'evolution-chart-ltm'}
              percentageOf={'% of Total Assets'}
              dataValue={paramSubCategory?paramSubCategory:paramCategory}
              showGrid
              formatter={(value)=>formatCurrency(value,company.currency)}
              loading={evolutionChartLTMLoading}
              error={evolutionChartLTMError}
              data={evolutionLTMChart}
              chartInfoCopy={paramSubCategory ?infoCopies.cashFlowSubcategories.ltm(paramSubCategory):infoCopies.cashFlowSubcategories.ltm(paramCategory)}
            />
          </div>}
        </div>}
      </div>
    </div>
  )
}
export default withErrorBoundary(AnalysisCf);
